// LICENSE_CODE MIT
import axios from 'axios';
import * as http from 'node:http';
import https from 'https';
import Form_data from 'form-data';
import {HttpsProxyAgent as Https_proxy_agent} from 'https-proxy-agent';
import process from 'process';
import eserf from './eserf.js';
import xurl from './xurl.js';
import str from './str.js';

let E = {};
export default E;
let is_node = process&&process.title!='browser';
E.is_bun_single_exe;
if (!Form_data.prototype.getHeaders)
{
  // in browser no need to add boundary will be added by axios
  Form_data.prototype.getHeaders = ()=>{
    return {'Content-Type': 'multipart/form-data'};
  };
}
E.is_verbose = is_node&&0;
E.fd_count = 0;

E.auth_hdr = token=>{ return {Authorization: `Bearer ${token}`}; };
E.auth_hdr_vnd = token=>{
  return {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/vnd.api+json',
  };
};
// btoa only supported in node17 and up
E.auth_hdr_user = (user, passwd)=>{
  return {Authorization: `Basic ${global.btoa ? btoa(user+':'+passwd)
    : Buffer.from(user+':'+passwd).toString('base64')}`};
};

E.post_upload = (url, config)=>eserf(function* ereq_post_upload(){
  let form = new Form_data();
  for (let field in config.data)
    form.append(field, config.data[field]);
  let headers = {...form.getHeaders(), ...config.headers};
  let ret = yield E.post(url, {...config, headers, data: form});
  return ret;
});

// XXX colin: move to abortcontroller
const cancel_token = axios.CancelToken;
let ess = [];
E.default = (method, url, config)=>{
  const source = cancel_token.source();
  let proxy_opt = '';
  let proxy = config?.proxy;
  let https_proxy_agent;
  if (config?.proxy)
  {
    config.proxy = false;
    let __url = `${proxy.protocol}://${proxy.auth.username}:`
      +`${proxy.auth.password}@${proxy.host||proxy.hostname}${proxy.port
        ? ':'+proxy.port : ''}`;
    https_proxy_agent = new Https_proxy_agent(__url);
    proxy_opt = `--proxy '${__url}'`;
  }
  let is_running, curl = `curl ${proxy_opt} -X ${method.toUpperCase()} `
    +`'${url}'`;
  return eserf(function* _req(){
    // XXX colin: use eserf.pool instead
    E.fd_count++;
    this.finally(()=>{
      E.fd_count--;
      if (is_running)
        source.cancel('ereq cancelled');
      if (!ess.length)
        return;
      let es = ess.pop();
      es.continue();
    });
    if (E.fd_limit && E.fd_count>=E.fd_limit)
    {
      let es = this.wait();
      ess.push(es);
      yield es;
    }
    is_running = true;
    config = config||{};
    config.transformResponse = [function(data, headers){
      let _headers = {};
      for (let h in headers)
      {
        let arr = typeof headers[h]=='string' ? headers[h].split(';')
          : headers[h];
        _headers[h.toLowerCase()] = arr;
      }
      if (config.is_resp_json || _headers['content-type']
        && _headers['content-type'][0]=='application/json')
      { // eslint-disable-line
        data = xurl.json_parse(data);
      }
      return data;
    }];
    let qs = config.qs;
    let hs = config.hs;
    if (qs || hs)
      url = xurl.url(url, qs, hs);
    config = Object.assign(config, {url, method, cancelToken: source.token});
    let _headers = {};
    for (let h in config.headers)
      _headers[h.toLowerCase()] = config.headers[h];
    let content_type = _headers['content-type'];
    if (config.data && typeof config.data=='object')
    {
      if (!content_type)
      {
        config.headers = config.headers||{};
        content_type = config.headers['Content-Type'] = 'application/json';
      }
      if ((/application\/.*json/u).test(content_type))
        config.data = JSON.stringify(config.data);
      else if (content_type=='application/x-www-form-urlencoded' ||
        (/application\/x-www-form-urlencoded.*/u).test(content_type))
      {
        config.data = xurl.qs(config.data);
      }
    }
    let accept = _headers.accept;
    if (!accept && config.is_event_stream)
      accept = config.headers.Accept = 'text/event-stream';
    if (https_proxy_agent)
      config.httpsAgent = https_proxy_agent;
    else if (config.unsafe)
      config.httpsAgent = new https.Agent({rejectUnauthorized: false});
    if (config.is_resp_buf)
      config.responseType = 'arraybuffer';
    if (config.is_resp_blob)
      config.responseType = 'blob';
    if (config.progress_rx_cb)
    {
      config.onDownloadProgress = event=>{
        let percent = Math.round(event.loaded * 100 / event.total);
        config.progress_rx_cb({percent, is_done: percent==100,
          rate_byte_sec: event.rate, event});
      };
    }
    if (config.progress_tx_cb)
    {
      config.onUploadProgress = event=>{
        let percent = Math.round(event.loaded * 100 / event.total);
        config.progress_tx_cb({percent, is_done: percent==100, event});
      };
    }
    let res, _err;
    let retry = config.retry || 1;
    for (let h in config.headers)
      curl += ` -H '${h}: ${config.headers[h]}'`;
    // XXX: unsupported Form_data
    if (config.data)
      curl += ` --data-raw '${config.data}'`;
    while (retry)
    {
      _err = undefined;
      if (E.is_bun_single_exe)
      {
        // eslint-disable-next-line no-loop-func
        let __req = http.get(url, resp=>{
          // XXX: support binary
          resp.setEncoding('utf8');
          resp.data_orig = '';
          // XXX: add cancel support
          resp.on('data', chunk=>{
            resp.data_orig += chunk;
          });
          resp.on('end', ()=>{
            let data;
            let headers = resp.headers;
            let _headers = {};
            for (let h in headers)
            {
              let arr = typeof headers[h]=='string' ? headers[h].split(';')
                : headers[h];
              _headers[h.toLowerCase()] = arr;
            }
            if (config.is_resp_json || _headers['content-type']
              && _headers['content-type'][0]=='application/json')
            { // eslint-disable-line
              data = xurl.json_parse(resp.data_orig);
            }
            resp.data = data;
            this.continue(resp);
          });
        });
        // eslint-disable-next-line no-loop-func
        __req.on('error', err=>{
          _err = err;
          this.continue();
        });
      }
      else
      {
        axios(config).then(resp=>{
          this.continue(resp);
        // eslint-disable-next-line no-loop-func
        }).catch(err=>{
          if (axios.isCancel(err))
            return;
          _err = err;
          this.continue();
        });
      }
      res = yield this.wait();
      if (!_err)
        break;
      retry--;
    }
    is_running = false;
    if (_err)
    {
      if (_err.errno=='EMFILE')
        throw new Error(`EMFILE to many open fds ${E.fd_count}`);
      if (_err.errno=='ERR_NETWORK')
        return {err: 'err_network'};
      if (_err.errno=='ETIMEDOUT')
        return {err: 'err_timeout'};
      let msg = 'ereq failed '+url+' '+_err.message;
      if (!config.no_print)
        console.error(msg, config, _err.stack, this.ps());
      let status = _err.response&&_err.response.status;
      let status_txt = _err.response&&_err.response.statusText;
      let err_data = _err&&_err.response&&_err.response.data;
      return {err: msg, status, status_txt, err_data, errno: _err.errno,
        curl};
    }
    if (E.is_verbose)
    {
      console.log('ereq', config.method, config.url,
        'data : ' + (config.data ? str.j2s(config.data) : ''),
        config.headers||'', res.data);
    }
    if (config.is_event_stream)
      res.data = xurl.parse_stream(res.data);
    return res;
  });
};

E.get = (url, config)=>E.default('GET', url, config);
E.head = (url, config)=>E.default('HEAD', url, config);
E.get_head = (url, config)=>E.get(url, {...config,
  headers: {Range: 'bytes=0-0'}});
E.patch = (url, config)=>E.default('PATCH', url, config);
E.post = (url, config)=>E.default('POST', url, config);
E.put = (url, config)=>E.default('PUT', url, config);
E.delete = (url, config)=>E.default('DELETE', url, config);

// XXX colin: add proc support in browser using babel/polyfill
//if (proc.is_main(import.meta.url))
//{
//  eserf(function* ereq_main(){
//    //let res = yield E.get('http://localhost:4000/pub/ping.json',
//    //  {qs: {is_header: true}, headers: {
//    //    'User-Agent': 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) '
//    //    +'AppleWebKit/537.36 (KHTML, like Gecko) Chrome/115.0.0.0 '
//    //    +'Safari/537.36',
//    //  }});
//    //console.log(str.j2s(res.data));
//    let res = yield E.get('https://mp4-download.com/4k-MP4',
//      {qs: {}, progress_rx_cb: ({percent, is_done, rate_byte_sec,
//        event})=>console.log(percent,
//        is_done, rate_byte_sec/1000/1000, event)});
//    console.log(res);
//  });
//}
