// LICENSE_CODE TLM
import React, {useCallback, useState, useEffect, useMemo} from 'react';
import {Route, Link, useLocation, useNavigate,
  Navigate} from 'react-router-dom';
import {Layout, Menu, Avatar, Row, Col, Affix, Button, Tooltip, Typography,
  Space, Drawer, Select} from 'antd';
import Icon, {HomeOutlined, BlockOutlined, ScissorOutlined, ReadOutlined,
  ContactsOutlined, MenuOutlined, CreditCardOutlined, EditOutlined,
  HighlightOutlined, MessageOutlined, FolderOutlined,
  LeftOutlined} from '@ant-design/icons';
import Slide_routes from 'react-slide-routes';
import {useTranslation} from 'react-i18next';
import country2flag from 'country-flag-icons/react/3x2';
import ISO6391 from 'iso-639-1';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Home from './home.js';
import Profile from './profile.js';
import Perm from './perm.js';
import Pay from './pay.js';
import Highlighter from './highlighter.js';
import Workspace from './workspace.js';
import Review from './review.js';
import Grouper from './grouper.js';
import Cutter from './cutter.js';
import Editor from './editor.js';
import Privacy from './privacy.js';
import Logger from './logger.js';
import Plan from './plan.js';
import Contact from './contact.js';
import Onboard from './onboard.js';
import Blog from './blog.js';
import Email_verif from './email_verif.js';
import Tos from './tos.js';
import Disclaimer from './disclaimer.js';
import auth from './auth.js';
import './app.css';
import xurl from '../../../util/xurl.js';
import logo from './assets/logo.svg';
import {Clickable, Loading, Scroll_to_top, use_qs, Stars_bg,
  use_is_mobile} from './comp.js';
import config_ext from './config_ext.js';
import je from '../../../util/je.js';
import metric from './metric.js';

AOS.init();
let {Text} = Typography;
export let supp_lngs = {en: {country: 'gb'}, fr: {country: 'fr'},
  de: {country: 'de'}, it: {country: 'it'}, hi: {country: 'in'},
  bn: {country: 'bd'}, zh: {country: 'cn'}, es: {country: 'es'},
  pl: {country: 'pl'}, pt: {country: 'pt'}, nl: {country: 'nl'},
  sv: {country: 'se'}};
let lng2flag = (lng, is_txt)=>{
  let _lng = supp_lngs[lng];
  let Flag = country2flag.GB;
  if (_lng)
    Flag = country2flag[_lng.country.toUpperCase()];
  else if (lng)
    metric.error('missing language', lng);
  return <><Space><Flag style={{height: '24px'}}/>{is_txt
    && <Text>{ISO6391.getNativeName(lng)}</Text>}</Space></>;
};

let i18n2lng = language=>{
  if (!language)
    return 'en';
  let ls = language.toLowerCase().split('-');
  let lng = ls[0];
  let country;
  if (ls.length>=2)
    country = ls[1];
  if (lng=='he')
    return 'en';
  return lng;
};

let Nav_drawer = React.memo(({is_open, on_close})=>{
  let {t, i18n} = useTranslation();
  let location = useLocation();
  let navigate = useNavigate();
  let {is_auth, login, signup, logout} = auth.use_auth_ext();
  let {qs_o} = use_qs();
  let [lng, lng_set] = useState(i18n2lng(i18n.language));
  let items = useMemo(()=>{
    return [
      {label: t('Home'), key: '/', icon: <HomeOutlined />},
      {label: t('Grouper'), key: '/grouper', icon: <BlockOutlined />},
      {label: t('Cutter'), key: '/cutter', icon: <ScissorOutlined />},
      {label: t('Editor'), key: '/editor', icon: <EditOutlined />},
      {label: t('Highlighter'), key: '/highlighter',
        icon: <HighlightOutlined />},
      {label: t('Workspace'), key: '/workspace', icon: <FolderOutlined />},
      {label: t('Logger'), key: '/logger', icon: <MessageOutlined />},
      {label: t('Blog'), key: '/blog', icon: <ReadOutlined />},
      {label: t('Plan'), key: '/plan', icon: <CreditCardOutlined />},
      {label: t('Contact Us'), key: '/contact', icon: <ContactsOutlined />},
    ];
  }, [t]);
  let opts = useMemo(()=>{
    return Object.keys(supp_lngs).map(_lng=>{
      return {value: _lng, label: <div style={{display: 'flex',
        alignItems: 'center', gap: '8px'}}>
        <Icon component={()=>lng2flag(_lng, false)} />
        <span>{ISO6391.getNativeName(_lng)}</span>
      </div>};
    });
  }, []);
  let change_handle = useCallback(_lng=>{
    lng_set(_lng);
    i18n.changeLanguage(_lng);
  }, [i18n]);
  let signup_handle = useCallback(()=>{
    signup();
  }, [signup]);
  let logout_with_redirect = useCallback(()=>{
    logout({returnTo: window.location.origin + location.search});
  }, [logout, location.search]);
  let profile_click_handle = useCallback(()=>{
    navigate(xurl.url('/profile', qs_o));
    on_close();
  }, [navigate, on_close, qs_o]);
  let menu_click_handle = useCallback(e=>{
    navigate(xurl.url(e.key, qs_o));
    on_close();
  }, [navigate, on_close, qs_o]);
  return (
    <Drawer placement="right" closable={false}
      open={is_open} onClose={on_close} styles={{body: {display: 'flex',
        flexDirection: 'column', justifyContent: 'space-between'}}}>
      <Menu items={items} mode="inline" style={{borderInlineEnd: 'none',
        background: 'transparent'}} selectedKeys={[location.pathname]}
      onClick={menu_click_handle} />
      <div style={{display: 'flex', flexDirection: 'column', gap: 8}}>
        <Select options={opts} onChange={change_handle} value={lng} />
        <div style={{display: 'flex', gap: 8}}>
          {!is_auth && <Button block onClick={login}>
            {t('Login')}
          </Button>}
          {!is_auth && <Button type="primary" block onClick={signup_handle}>
            {t('Sign Up')}
          </Button>}
          {is_auth && <Button block onClick={profile_click_handle}>
            {t('Profile')}
          </Button>}
          {is_auth && <Button block onClick={logout_with_redirect}>
            {t('Log Out')}
          </Button>}
        </div>
      </div>
    </Drawer>
  );
});
let with_onboard = {'/grouper': true, '/cutter': true, '/editor': true,
  '/highlighter': true, '/logger': true, '/workspace': true};
let Header = React.memo(({on_drawer_open})=>{
  let {t, i18n} = useTranslation();
  let location = useLocation();
  let navigate = useNavigate();
  let {qs_o} = use_qs();
  let {user, is_auth, login, signup, logout} = auth.use_auth_ext();
  let {user_full, org} = auth.use_auth();
  let [lng, lng_set] = useState(i18n2lng(i18n.language));
  let [nav, nav_set] = useState();
  let {is_mobile} = use_is_mobile();
  let search = location.search;
  let pathname = location.pathname;
  je.set('app.pathname', pathname);
  useEffect(()=>{
    if (!nav)
      return;
    navigate(nav);
    nav_set(null);
  }, [nav, navigate]);
  useEffect(()=>{
    lng_set(i18n2lng(i18n.language));
  }, [i18n.language]);
  useEffect(()=>{
    i18n.changeLanguage(lng);
  }, [lng, i18n]);
  useEffect(()=>{
    let listeners = [];
    listeners.push(je.on(['auth.is_onboard', 'app.pathname'], ()=>{
      let _pathname = je.get('app.pathname');
      let is_onboard = je.get('auth.is_onboard');
      if (is_onboard && _pathname=='/onboard')
      {
        nav_set(xurl.url('/grouper', qs_o));
        return;
      }
      if (is_onboard || is_onboard===undefined || _pathname=='/onboard')
        return;
      if (with_onboard[_pathname])
        nav_set(xurl.url('/onboard', qs_o));
    }));
    listeners.push(je.on(['auth.is_email_verif', 'app.pathname'], ()=>{
      let _pathname = je.get('app.pathname');
      let is_email_verif = je.get('auth.is_email_verif');
      if (is_email_verif && _pathname=='/email_verif')
      {
        nav_set(xurl.url('/grouper', qs_o));
        return;
      }
      if (is_email_verif || is_email_verif===undefined
        || _pathname=='/email_verif')
      {
        return;
      }
      nav_set(xurl.url('/email_verif', qs_o));
    }));
    return ()=>je.off(listeners);
  }, [qs_o]);
  let icon_flag_props = useMemo(()=>({style: {fontSize: '150%'}}), []);
  let menu_lngs = useMemo(()=>{
    let _menu_lngs = [];
    for (let _lng in supp_lngs)
    {
      _menu_lngs.push({
        label: <Clickable><Icon {...icon_flag_props} onClick={()=>lng_set(_lng)}
          component={()=>lng2flag(_lng, true)}/></Clickable>,
        key: 'lng_'+_lng,
      });
    }
    return _menu_lngs;
  }, [icon_flag_props]);
  let icon_props = useMemo(()=>({style: {fontSize: '150%'}}), []);
  let logout_with_redirect = useCallback(()=>logout({
    returnTo: window.location.origin + location.search,
  }), [logout, location.search]);
  let on_click = ({key})=>{
    if (key=='/logout')
      return void logout_with_redirect({});
    if (key=='/login')
      return void login();
    if (key=='/signup')
      return void signup();
    if (!key.startsWith('/'))
      return;
    nav_set(xurl.url(key, qs_o));
  };
  let _logo = user_full?.id != org?.id && org?.logo_url && 0 || logo;
  let logo_style = {cursor: 'pointer'};
  if (_logo!=logo)
    logo_style.filter = 'invert(1)';
  let items = useMemo(()=>{
    let _items = [
      {
        label: <Clickable>
          <Tooltip placement="bottom" title={t('Home')}>
            <Link to={{pathname: '/', search}}>
              <HomeOutlined {...icon_props}/>
            </Link>
          </Tooltip>
        </Clickable>, key: '/'
      }, {
        label: <Clickable><Tooltip placement="bottom" title={t('Grouper')}>
          <Link to={{pathname: '/grouper', search}}>
            <BlockOutlined {...icon_props}/></Link></Tooltip></Clickable>,
        key: '/grouper',
      }, {
        label: <Clickable><Tooltip placement="bottom" title={t('Cutter')}>
          <Link to={{pathname: '/cutter', search}}>
            <ScissorOutlined {...icon_props}/>
          </Link></Tooltip></Clickable>, key: '/cutter',
      }, {
        label: <Clickable><Tooltip placement="bottom" title={t('Editor')}>
          <Link to={{pathname: '/editor', search}}>
            <EditOutlined {...icon_props}/>
          </Link></Tooltip></Clickable>, key: '/editor',
      }, {
        label: <Clickable><Tooltip placement="bottom" title={t('Highlighter')}>
          <Link to={{pathname: '/highlighter', search}}>
            <HighlightOutlined {...icon_props}/>
          </Link></Tooltip></Clickable>, key: '/highlighter',
      }, {
        label: <Clickable><Tooltip placement="bottom" title={t('Workspace')}>
          <Link to={{pathname: '/workspace', search}}>
            <FolderOutlined {...icon_props}/>
          </Link></Tooltip></Clickable>, key: '/workspace',
      }, {
        label: <Clickable><Tooltip placement="bottom" title={t('Logger')}>
          <Link to={{pathname: '/logger', search}}>
            <MessageOutlined {...icon_props}/>
          </Link></Tooltip></Clickable>, key: '/logger',
      }, {
        label: <Clickable><Link to={{pathname: '/onboard', search}}>
          {t('Onboard')}</Link></Clickable>, key: '/onboard', disabled: true,
      }, {
        label: <Clickable><Tooltip placement="bottom" title={t('Blog')}><Link
          to={{pathname: '/blog', search}}>
          <ReadOutlined {...icon_props}/>
        </Link></Tooltip></Clickable>, key: '/blog',
      }, {
        label: <Clickable><Tooltip placement="bottom" title={t('Plans')}><Link
          to={{pathname: '/plan', search}}>
          <CreditCardOutlined {...icon_props}/>
        </Link></Tooltip></Clickable>, key: '/plan',
      }, {
        label: <Clickable>
          <Tooltip placement="bottom" title={t('Contact Us')}>
            <Link to={{pathname: '/contact', search}}>
              <ContactsOutlined {...icon_props}/>
            </Link>
          </Tooltip>
        </Clickable>, key: '/contact',
      }, {
        label: <Clickable>
          <Button type="dashed">
            {t('Login')}
          </Button>
        </Clickable>, key: '/login', disabled: is_auth
      }, {
        label: <Clickable><Button type="primary">
          {t('Signup Free')}</Button></Clickable>, key: '/signup',
        disabled: is_auth
      }, {
        label: <Avatar src={user?.picture}
          alt="Profile" style={{marginTop: '-5px'}}/>,
        disabled: !is_auth,
        children: [{
          label: <div style={{display: 'flex', flexDirection: 'column'}}>
            <span>{user?.name}</span>
            {org && <span style={{fontSize: 11}}>{org.lbl}</span>}
          </div>, type: 'group'
        }, {
          label: <Clickable><Link to={{pathname: '/profile', search}}>
            {t('Profile')}</Link></Clickable>,
          key: '/profile', disabled: !is_auth
        }, {
          label: <Clickable>{t('Logout')}</Clickable>, key: '/logout',
        }]}, {
        label: <Icon {...icon_flag_props}
          component={()=>lng2flag(lng, false)}/>,
        key: 'lng',
        children: menu_lngs,
      }
    ];
    for (let i in _items)
    {
      let item = _items[i];
      if (item.disabled)
        delete _items[i];
    }
    return _items;
  }, [icon_flag_props, icon_props, is_auth, lng, menu_lngs, org, search, t,
    user?.name, user?.picture]);
  let logo_click_handle = useCallback(()=>{
    navigate('/');
  }, [navigate]);
  let back_handle = useCallback(()=>{
    navigate(-1);
  }, [navigate]);
  return (
    <Affix offsetTop="0">
      <Layout.Header style={{display: 'flex', alignItems: 'center', gap: '24px',
        justifyContent: is_mobile ? 'space-between' : 'flex-start',
        padding: '0 10px'}}>
        {is_mobile && <Button type="text" icon={<LeftOutlined />}
          onClick={back_handle} />}
        <Clickable>
          <img src={_logo} alt="Toolium" width="48" style={logo_style}
            onClick={logo_click_handle} />
        </Clickable>
        {!is_mobile && <Menu onClick={on_click} theme="dark" mode="horizontal"
          overflowedIndicator={<MenuOutlined {...icon_props}/>}
          selectedKeys={[location.pathname]} items={items}
          style={{width: '100%'}} />}
        {is_mobile && <Button type="text" icon={<MenuOutlined />}
          onClick={on_drawer_open} />}
      </Layout.Header>
    </Affix>
  );
});

let year = new Date().getUTCFullYear();
let Content_footer = ({children})=>{
  let {t} = useTranslation();
  return <>
    <Stars_bg/>
    <Layout style={{paddingTop: '4vw', paddingBottom: '4vw',
      background: 'linear-gradient(180deg, rgba(100,42,181,1) 0%, '
      +'rgba(0,0,0,1) 100px)'}}>
      <Layout.Content style={{minHeight: '70vh'}}>
        <Row justify="center">
          <Col span={24}>{children}</Col>
        </Row>
      </Layout.Content>
      <Layout.Footer style={{paddingTop: '4vw'}}>
        <Row gutter={[16, 24]} justify="space-around">
          <Col xs={24} md={6} style={{textAlign: 'center'}}>
            <Text>
              Copyright © {year} {config_ext.front.company_lbl}.
              All Rights Reserved.
            </Text>
          </Col>
          <Col xs={24} md={4} style={{textAlign: 'center'}}>
            <Clickable>
              <Link to={{pathname: '/privacy', search: location.search}}>
                {t('Privacy policy')}
              </Link>
            </Clickable>
          </Col>
          <Col xs={24} md={4} style={{textAlign: 'center'}}>
            <Clickable>
              <Link to={{pathname: '/tos', search: location.search}}>
                {t('Terms of service')}
              </Link>
            </Clickable>
          </Col>
          <Col xs={24} md={4} style={{textAlign: 'center'}}>
            <Clickable>
              <Link to={{pathname: '/disclaimer', search: location.search}}>
                {t('Disclaimer')}
              </Link>
            </Clickable>
          </Col>
          <Col xs={24} md={6} style={{textAlign: 'center'}}>
            <Contact is_center={1}/>
          </Col>
        </Row>
      </Layout.Footer>
    </Layout>;
  </>;
};

let Not_found = ()=>{
  let location = useLocation();
  metric.error('not_found_404', location.pathname, location.search);
  return <Navigate to="/" replace />;
};
let Cf_home = ()=><Content_footer><Home/></Content_footer>;
let Cf_profile = ()=><Content_footer><Profile/></Content_footer>;
let Cf_perm = ()=><Content_footer><Perm/></Content_footer>;
let Cf_pay = ()=><Content_footer><Pay/></Content_footer>;
let Cf_highlighter = ()=><Content_footer><Highlighter/></Content_footer>;
let Cf_workspace = ()=><Content_footer><Workspace/></Content_footer>;
let Cf_review = ()=><Content_footer><Review/></Content_footer>;
let Cf_grouper = ()=><Content_footer><Grouper/></Content_footer>;
let Cf_cutter = ()=><Content_footer><Cutter/></Content_footer>;
let Cf_editor = ()=><Content_footer><Editor/></Content_footer>;
let Cf_privacy = ()=><Content_footer><Privacy/></Content_footer>;
let Cf_logger = ()=><Content_footer><Logger/></Content_footer>;
let Cf_tos = ()=><Content_footer><Tos/></Content_footer>;
let Cf_disclaimer = ()=><Content_footer><Disclaimer/></Content_footer>;
let Cf_contact = ()=><Content_footer>
  <Contact is_addr={true}/>
</Content_footer>;
let Cf_onboard = ()=><Content_footer><Onboard/></Content_footer>;
let Cf_blog = ()=><Content_footer><Blog/></Content_footer>;
let Cf_plan = ()=><Content_footer><Plan/></Content_footer>;
let Cf_email_verif = ()=><Content_footer><Email_verif/></Content_footer>;
let Cf_not_found = ()=><Content_footer><Not_found/></Content_footer>;
let App = ()=>{
  let {is_loading, error} = auth.use_auth_ext();
  // XXX colin: handle error in auth0 and sbase in the same way
  let {is_ready} = use_is_mobile();
  let [is_drawer_open, is_drawer_open_set] = useState(false);
  let drawer_open_handle = useCallback(()=>{
    is_drawer_open_set(true);
  }, []);
  let drawer_close_handle = useCallback(()=>{
    is_drawer_open_set(false);
  }, []);
  if (error)
  {
    return <div style={{backgroundColor: 'red'}}>
      Oops... {config_ext.is_sbase ? error : error.message}</div>;
  }
  if (is_loading || !is_ready)
    return <Loading />;
  return (
    <Layout>
      <Nav_drawer is_open={is_drawer_open} on_close={drawer_close_handle} />
      <Header on_drawer_open={drawer_open_handle} />
      <Scroll_to_top/>
      <Slide_routes>
        <Route path="/" exact element={<Cf_home/>} />
        <Route path="/pay" element={<Cf_pay/>} />
        <Route path="/checkout" element={<Cf_pay/>} />
        <Route path="/highlighter" element={<Cf_highlighter/>} />
        <Route path="/workspace" element={<Cf_workspace/>} />
        <Route path="/review" element={<Cf_review/>} />
        <Route path="/grouper" element={<Cf_grouper/>} />
        <Route path="/cutter" element={<Cf_cutter/>} />
        <Route path="/editor" element={<Cf_editor/>} />
        <Route path="/blog" element={<Cf_blog/>} />
        <Route path="/plan" element={<Cf_plan/>} />
        <Route path="/contact" element={<Cf_contact/>} />
        <Route path="/contactus" element={<Cf_contact/>} />
        <Route path="/profile" element={<Cf_profile/>} />
        <Route path="/perm" element={<Cf_perm/>} />
        <Route path="/privacy" element={<Cf_privacy/>} />
        <Route path="/logger" element={<Cf_logger/>} />
        <Route path="/tos" element={<Cf_tos/>} />
        <Route path="/disclaimer" element={<Cf_disclaimer/>} />
        <Route path="/onboard" element={<Cf_onboard/>} />
        <Route path="/email_verif" element={<Cf_email_verif/>} />
        <Route path="/logout" element={<Navigate to="/" replace />} />
        <Route path="*" element={<Cf_not_found/>} />
      </Slide_routes>
    </Layout>
  );
};

export default App;
