// LICENSE_CODE TLM
import React, {useCallback, useMemo, useState, useEffect} from 'react';
import {UploadOutlined, DownloadOutlined} from '@ant-design/icons';
import {Button, message, Upload, Switch, Row, Space, Rate, Modal,
  Tooltip, Typography, Col, Form} from 'antd';
import {useTranslation} from 'react-i18next';
import xurl from '../../../util/xurl.js';
import xdate from '../../../util/date.js';
import ereq from '../../../util/ereq.js';
import str from '../../../util/str.js';
import eserf from '../../../util/eserf.js';
import je from '../../../util/je.js';
import auth from './auth.js';
import Contact from './contact.js';
import config_ext from './config_ext.js';
import {Clickable, Loading, download, Org_no_credit, use_qs,
  use_es_root} from './comp.js';
import back_app from './back_app.js';
import metric from './metric.js';
import {Timeline_panel} from './editor.js';

let {Title} = Typography;
let prefix = config_ext.back.app.url;

let E = ()=>{
  let {t} = useTranslation();
  let {qs_o: _qs_o} = use_qs();
  let [modal, modal_ctx_holder] = Modal.useModal();
  let [form] = Form.useForm();
  let [lbin, lbin_set] = useState(null);
  // XXX colin: add support for them both
  //let [rec_monitor_in, rec_monitor_in_set] = useState(null);
  //let [rec_monitor_out, rec_monitor_out_set] = useState(null);
  let [message_api, message_ctx_holder] = message.useMessage();
  let {user, token, org, user_full} = auth.use_auth();
  let [proc_type] = useState('grouper');
  let [is_rate, set_is_rate] = useState(false);
  let es_root = use_es_root();
  let [qs_o, qs_o_set] = useState({proc_type, dbg_py: !!_qs_o.dbg_py});
  useEffect(()=>{
    set_is_rate(org && user_full && user_full.rate==undefined && org.credit
      && org.credit.v<-50);
  }, [org, user_full]);
  let settings = useMemo(()=>{
    return [
      [
        {name: 'is_multi', checked_lbl: t('MULTI'),
          uncheded_lbl: t('CLASSIC'),
          tooltip: <Space direction="vertical">
            <Row>{t('CLASSIC: Classic AVID grouping')}</Row>
            <Row>{t(str.trim(`MULTI: Groups are created as the new
              avid groups with black spaces between clips on the same
              track. A new group is created after a space on the
              timeline.`))}
            </Row>
          </Space>},
        {name: 'is_rename', checked_lbl: t('RENAME ON'),
          uncheded_lbl: t('RENAME OFF'),
          tooltip: <Space direction="vertical">
            <Row>
              {t(str.trim(`RENAME ON: Renames audio tracks inside the
                groups according to the sequence track names. You can open
                as many tracks as you need, the groups will be created
                according to the source audio track.`))}
            </Row>
          </Space>},
        {name: 'is_keep_audio', checked_lbl: t('KEEP AUDIO ON'),
          uncheded_lbl: t('KEEP AUDIO OFF'),
          tooltip: <Space direction="vertical">
            <Row>
              {t(str.trim(`KEEP AUDIO ON: Splices groups including the
                trailing audio onto the final sequence.`))}
            </Row>
          </Space>},
        {name: 'is_audio_as_is', checked_lbl: t('GROUP AUDIO AS IS ON'),
          uncheded_lbl: t('GROUP AUDIO AS IS OFF'),
          tooltip: <Space direction="vertical">
            <Row>
              {t(str.trim(`GROUP AUDIO AS IS ON: Creates groups
                with the audio tracks routed exactly as they are
                placed on your synchronized sequence.
                Use this if you want your groups audio to be
                routed exactly like your sequence`))}
            </Row>
          </Space>},
      ],
      [
        {name: 'is_cam_keep_track', checked_lbl: t('KEEP CAM ON TRACK ON'),
          uncheded_lbl: t('KEEP CAM ON TRACK OFF'),
          tooltip: <Space direction="vertical">
            <Row>
              {t(str.trim(`KEEP CAM ON TRACK ON: Creates groups
                with camera angles on the same track as they are
                placed on your synchronized sequence.
                Use this if you want your cameras to stay in the
                same group placement during the whole shoot`))}
            </Row>
          </Space>},
        {name: 'is_route_audio', checked_lbl: t('ROUTE AUDIO ON'),
          uncheded_lbl: t('ROUTE AUDIO OFF'),
          tooltip: <Space direction="vertical">
            <Row>
              {t(str.trim(`ROUTE AUDIO ON: Routes all the audio tracks
                in the group to the sequence in consecutive order.
                Use this if you have audio from more than one
                device.`))}
            </Row>
          </Space>},
      ],
    ];
  }, [t]);
  // XXX colin: wait till token exists before allowing usage
  // XXX colin: change to use ereq and send to metric on err
  let props = {
    name: 'file',
    listType: 'picture',
    action: xurl.url(prefix+'/private/grouper/aaf/upload.json',
      {email: user.email, ver: config_ext.ver, ...qs_o}),
    onChange(info){
      if (info.file.status != 'uploading')
        console.log(info.file, info.fileList);
      if (info.file.status == 'done')
      {
        let resp = info.file.response;
        if (resp.err)
          return metric.error('error on done', resp.err);
        let file = resp.file;
        let _lbin = resp?.lbin;
        if (_lbin)
        {
          _lbin.rec_monitor_in = _lbin.rec_monitor_out;
          let lbl_save = xdate.delta2lbl(_lbin.n_group_calc*124, t);
          message_api.success(`${info.file.name} `
            +`${t('processed successfully we saved you')} ${lbl_save}`);
          // XXX colin: add this as vanity
          // 124sec*n_group_calc
          // if rename is on 124sec*n_group_calc+60sec*n_subclip
        }
        else
        {
          message_api.success(`${info.file.name} `
            +`${t('processed successfully')}`);
        }
        lbin_set(_lbin);
        info.file.url = xurl.url(prefix+'/private/aaf/get.aaf',
          {email: user.email, file, token, ver: config_ext.ver});
        let file_prev = info.file.name;
        info.file.name =
          <>
            <span>
              {str.trun(file_prev, 50)} {'->'} {str.trun(file, 20)}
            </span>
            <Button icon={<DownloadOutlined/>}/>
          </>;
        download(info.file.url);
      }
      else if (info.file.status == 'error')
      {
        let resp = info.file.response;
        if (!resp)
        {
          metric.error('grouper_upload_timeout_504_err');
          resp = {err: 'file_to_big'};
        }
        let err_id2str = {
          is_not_aaf: 'Is not an AAF format, look at how you exported it',
          import_sequence_filler_found: 'Import sequence filler found',
          invalid_file_type: 'Invalid file type',
          org_no_credit:
            <>
              <Space direction="vertical">
                <Org_no_credit />
              </Space>
            </>,
          org_is_disable:
            <>
              <div>{t('Contact your Toolium representative to activate '
                +'your account')}
              </div>
              <Contact is_no_txt={true}/>
            </>,
          proc_type_unknown: t('Process type unknown'),
          no_clips_found_on_sequence: t('No clips found on sequence'),
          no_clips_for_writing_sequence: t('No clips for writing sequence'),
          no_sequence_found_in_aaf_file: t('No sequence found in AAF file'),
          file_to_big: t('File is to big reduce AAF file'),
          sequence_has_length_of_0: t('Sequence has length of 0'),
          group_clip_found_on_sequence: t('Group clip found on sequence'),
          group_clip_found_on_sequence_2: t('Group clip found on sequence 2'),
          unknown_selector_type_found_on_sequence:
            t('Unknown selector type found in sequence'),
          clip_framerate_does_not_match_sequence_framerate:
            t('Clip framerate does not match sequence framerate'),
          clip_editrate_does_not_match_sequence_framerate:
            t('Clip editrate does not match sequence framerate. '
            +'This cannot be seen in Avid.'),
          subclips_with_motion_effects_are_not_supported:
            t('Subclips with motion effects are not supported'),
          mob_get_no_mob_found:
            t('Could not find find Mob ID, invalid AAF try and export again'),
        };
        let err_s = err_id2str[resp.err]||resp.err;
        if (err_s==resp.err)
          metric.error('grouper_missing_err_id', err_s);
        else
          metric.error('grouper_upload_err_'+resp.err);
        info.file.error.message = err_s;
        modal.error({title: `${info.file.name} ${t('file upload failed')}`,
          content: err_s});
        return void message_api.error(`${info.file.name} `
          +`${t('file upload failed')} ${resp.err}`);
      }
    },
  };
  let settings_initial_values = useMemo(()=>{
    if (!user_full?.setting?.grouper)
      return {};
    return user_full.setting.grouper;
  }, [user_full?.setting?.grouper]);
  let settings_change_handle = useCallback(changed_values=>{
    qs_o_set({...qs_o, ...changed_values});
    for (let setting in changed_values)
    {
      let es = eserf(function* _settings_change_handle(){
        let res = yield back_app.user_set_grouper_setting(token, user.email,
          setting, changed_values[setting]);
        if (res.err)
          return metric.error_once('user_set_grouper_setting', res.err);
        je.set_inc('grouper.update_n');
      });
      es_root.spawn(es);
    }
  }, [es_root, qs_o, token, user.email]);
  useEffect(()=>{
    if (!user_full?.setting?.grouper)
      return;
    qs_o_set(prev_qs_o=>({...prev_qs_o, ...user_full.setting.grouper}));
  }, [user_full?.setting?.grouper]);
  let _on_change = rate=>{
    // XXX colin: ask for feedback and why?
    back_app.user_set_rate(token, user.email, rate);
    if (rate<4)
      return void set_is_rate(false);
    // XXX colin: change to be toolium.org
    window.location.href = 'https://www.trustpilot.com/review/www.toolium.org';
  };
  if (token)
    props.headers = ereq.auth_hdr(token);
  if (is_rate)
  {
    modal.confirm({
      title: t('Rate the app to help us improve'),
      content: <Clickable>
        <Rate onChange={_on_change} allowHalf defaultValue={3.5} />
      </Clickable>,
      okButtonProps: {
        disabled: true,
        style: {display: 'none'},
      },
      cancelText: t('I don\'t wanna'),
      onOk(){},
      onCancel(){},
    });
  }
  else
    Modal.destroyAll();
  if (!token)
    return <Loading/>;
  // XXX colin: change top col back to span 22 and add col for each radio
  return (
    <Row justify="center">
      {modal_ctx_holder}
      {message_ctx_holder}
      <Row>
        <Col xs={{offset: 1, span: 22}} md={{offset: 1, span: 22}}>
          <Space direction="vertical" size="large" align="center">
            <Row><Title>{t('GROUPER')}</Title></Row>
            <Row>
              <Form form={form} onValuesChange={settings_change_handle}
                initialValues={settings_initial_values}>
                <Space direction="vertical" size="large" align="center">
                  {settings.map((row, idx)=>{
                    return <Row key={idx} justify="center">
                      <Space size="large">
                        {row.map(setting=>{
                          return <Clickable key={setting.name}>
                            <Tooltip title={setting.tooltip}>
                              <Form.Item name={setting.name} noStyle>
                                <Switch checkedChildren={setting.checked_lbl}
                                  unCheckedChildren={setting.uncheded_lbl} />
                              </Form.Item>
                            </Tooltip>
                          </Clickable>;
                        })}
                      </Space>
                    </Row>;
                  })}
                </Space>
              </Form>
            </Row>
            <Row>
              <Upload {...props}>
                <Clickable>
                  <Button block type="primary"
                    style={{whiteSpace: 'normal', height: '25vh',
                      marginBottom: '10px'}}
                  >
                    <Title>
                      <UploadOutlined/>
                      <br/>
                      {t('Click to Upload or Drag and Drop an AAF')}
                    </Title>
                  </Button>
                </Clickable>
              </Upload>
            </Row>
          </Space>
        </Col>
      </Row>
      {lbin?.rec_monitor_in && <Row
        style={{minHeight: '45vh', width: '100%', marginTop: '48px'}}
        data-aos="fade-up" >
        <Col span={24}>
          <Timeline_panel lbin={lbin} />
        </Col>
      </Row>}
    </Row>);
};

export default auth.with_auth_req(E);
