// LICENSE_CODE MIT
import {v4} from 'uuid';
//import proc from './proc.js';

let E = {};
export default E;

E.uuid = ()=>v4();
E.dice = (max, min=1)=>Math.floor(Math.random()*(max-min+1))+min;
E.seed = ()=>E.dice(2147483647, 0);

//if (proc.is_main(import.meta.url))
//  console.log(E.uuid());
