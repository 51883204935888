// LICENSE_CODE TLM
import React, {useState, useCallback, useMemo, useEffect, useRef,
  createContext, useContext} from 'react';
import {Modal, Breadcrumb, Layout, Tree, Input, Typography, Button, Table,
  Form, Dropdown, Space, Image, Tabs, Avatar, Progress, theme, Tooltip,
  message, Drawer, Grid, Row, Col, Select, Switch, Skeleton} from 'antd';
import Icon, {RightOutlined, LoadingOutlined, FolderAddOutlined, HomeOutlined,
  DownOutlined, FolderOutlined, FolderViewOutlined, FolderFilled,
  UploadOutlined, FileFilled, DownloadOutlined, DeleteOutlined, MenuOutlined,
  SettingOutlined, ShareAltOutlined, EditOutlined, PlusOutlined,
  EnterOutlined, CopyOutlined, SnippetsOutlined, LeftOutlined, MoreOutlined,
  LockOutlined, EllipsisOutlined, ExportOutlined, FileOutlined,
  HighlightOutlined, PrinterOutlined, FileTextOutlined, PlaySquareOutlined,
  TableOutlined, ApartmentOutlined, PauseOutlined, BankOutlined,
  FullscreenOutlined, DiffOutlined} from '@ant-design/icons';
import {useDropzone} from 'react-dropzone';
import {purple, gray} from '@ant-design/colors';
import {tinykeys} from 'tinykeys';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {DndContext, PointerSensor, TouchSensor, useSensor, useSensors,
  pointerWithin as pointer_within, useDraggable, DragOverlay,
  useDroppable} from '@dnd-kit/core';
import {snapCenterToCursor as snap_center_to_cursor} from '@dnd-kit/modifiers';
import mime from 'mime';
import assert from 'assert';
import _ from 'lodash';
import {Clickable, download, use_qs, use_qs_clear, use_is_mobile,
  use_es_root, use_effect_eserf} from './comp.js';
import xurl from '../../../util/xurl.js';
import eserf from '../../../util/eserf.js';
import xdate from '../../../util/date.js';
import str from '../../../util/str.js';
import tc, {editrate2fps} from '../../../util/tc.js';
import xutil from '../../../util/util.js';
import rand from '../../../util/rand.js';
import auth from './auth.js';
import back_app from './back_app.js';
import metric from './metric.js';
import player from './player.js';
import config_ext from './config_ext.js';
import {cmt_sort_methods, sort_method2lbl} from './editor.js';
import audio_icon from './assets/audio_icon.svg';
import je from '../../../util/je.js';
import {ReactComponent as Play_icon} from './assets/play_icon.svg';
import {ReactComponent as Sequence_icon} from './assets/sequence_icon.svg';

let proj_lbl = 'main';
export let file_id2filename = file_id=>{
  return str.mongo2path(file_id).split('/').pop();
};
let native_file2file_id = (base_path, native_file)=>{
  let file_path;
  if (native_file.webkitRelativePath)
    file_path = base_path + '/' + native_file.webkitRelativePath;
  else if (native_file.path && native_file.path[0] == '/')
    file_path = base_path + native_file.path;
  else if (native_file.path)
    file_path = base_path + '/' + native_file.path;
  else
    file_path = base_path + '/' + native_file.name;
  return str.path2mongo(file_path);
};
let filename_idx_add = (filename, idx)=>{
  let filename_arr = filename.split('.');
  if (filename_arr.length == 1)
    return `${filename} (${idx})`;
  let ext = filename_arr.at(-1);
  let name = filename_arr.slice(0, -1).join('.');
  return `${name} (${idx}).${ext}`;
};
let proj_link2url = (proj_id, proj_link_id)=>{
  return xurl.url(`${config_ext.front.url}/review`, {proj_id, proj_link_id});
};
let url_copy = url=>eserf(function* _url_copy(){
  let res = yield this.wait_ext2(navigator.clipboard.writeText(url));
  if (!res)
    return {};
  return res;
});
let action2key_bind = {move_one_frame_left: 'ArrowLeft',
  move_one_frame_right: 'ArrowRight', move_ten_frames_left: 'Shift+ArrowLeft',
  move_ten_frames_right: 'Shift+ArrowRight', play_stop: 'Space',
  play_backwards: 'KeyJ', stop: 'KeyK', play: 'KeyL'};
// XXX vladimir: move to comp.js
let key_binding_map_get = action2func=>{
  return Object.entries(action2func)
    .map(([action, func])=>{
      let key_bind = action2key_bind[action];
      if (Array.isArray(key_bind))
      {
        return key_bind
          .map(key=>({
            [key]: e=>{
              if (['INPUT', 'TEXTAREA'].includes(e.target.tagName))
                return;
              e.preventDefault();
              if (typeof func == 'function')
                func(e);
            }
          }))
          .reduce((accum, curr)=>({...accum, ...curr}));
      }
      return {
        [action2key_bind[action]]: e=>{
          if (['INPUT', 'TEXTAREA'].includes(e.target.tagName))
            return;
          e.preventDefault();
          if (typeof func == 'function')
            func(e);
        },
      };
    })
    .reduce((accum, cur)=>({...accum, ...cur}));
};
let is_image = path=>{
  if (!path)
    assert(0, 'is_image() no path');
  let mime_type = mime.getType(path);
  if (!mime_type)
    return false;
  return mime_type.startsWith('image/');
};
let is_audio = path=>{
  if (!path)
    assert(0, 'is_audio() no path');
  let mime_type = mime.getType(path);
  if (!mime_type)
    return false;
  return mime_type.startsWith('audio/');
};
let is_video = path=>{
  if (!path)
    assert(0, 'is_video() no path');
  let mime_type = mime.getType(path);
  if (!mime_type)
    return false;
  return mime_type.startsWith('video/') || mime_type == 'model/vnd.mts';
};
let files2tree_data = files=>{
  if (!files)
    return [];
  return Object.values(files)
    .filter(file=>file.is_dir)
    .map(file=>{
      let filename = file_id2filename(file.id);
      return {key: file.id, title: filename,
        icon: <FolderFilled />,
        children: files2tree_data(file.children)};
    });
};
let Sidebar_drawer = React.memo(({is_open, on_close, path, on_path_change,
  files, org, token, proj_get, proj})=>{
  let {t} = useTranslation();
  let [message_api, message_ctx_holder] = message.useMessage();
  let [expanded_keys, expanded_keys_set] = useState([]);
  let tree_data = useMemo(()=>{
    if (!org)
      return [];
    if (!org.id)
      assert(0, 'no org id');
    return [{key: `/${org.id}/root/${proj_lbl}`, title: t('Workspace'),
      icon: <HomeOutlined />, children: files2tree_data(files)}];
  }, [files, org, t]);
  let selected_keys = useMemo(()=>{
    return [str.path2mongo(path)];
  }, [path]);
  useEffect(()=>{
    let path_arr = path.split('/');
    let expanded_keys_part = [];
    for (let i = 3; i < path_arr.length; i++)
    {
      expanded_keys_part.push(
        str.path2mongo(path_arr.slice(0, i + 1).join('/')));
    }
    expanded_keys_set(_expanded_keys=>{
      return [...new Set([..._expanded_keys, ...expanded_keys_part])];
    });
  }, [path]);
  let select_handle = useCallback(_selected_keys=>{
    on_close();
    if (!_selected_keys.length)
      return;
    let file_id = _selected_keys[0];
    on_path_change(str.mongo2path(file_id), true);
  }, [on_path_change, on_close]);
  let expand_handle = useCallback((_expanded_keys, info)=>{
    let new_expanded_keys = [...expanded_keys];
    if (info.expanded)
      new_expanded_keys.push(info.node.key);
    else
    {
      new_expanded_keys = new_expanded_keys
        .filter(file_id=>!file_id.startsWith(info.node.key));
    }
    expanded_keys_set(new_expanded_keys);
  }, [expanded_keys]);
  let drop_handle = useCallback(info=>eserf(function* _drop_handle(){
    let file_id = info.dragNode.key;
    let filename = file_id2filename(file_id);
    let target_file_id = info.node.key + '/' + str.path2mongo(filename);
    if (file_id == target_file_id || file_id == proj.id)
      return;
    let res = yield back_app.file.mv(token, file_id, target_file_id);
    if (res.err == 'already_exist')
      return message_api.error(t('Folder already exists'));
    if (res.err)
    {
      message_api.error(t('Something went wrong'));
      return metric.error('file_drag_mv_err', res.err);
    }
    proj_get();
    if (str.mongo2path(file_id).startsWith(path))
    {
      let parent_path = str.mongo2path(file_id).split('/').slice(0, -1)
        .join('/');
      on_path_change(parent_path, true);
    }
  }), [proj_get, message_api, t, token, path, on_path_change, proj]);
  useEffect(()=>{
    if (!is_open)
      return;
    let start_x;
    let touch_start_handle = e=>{
      start_x = e.touches[0].clientX;
    };
    let touch_end_handle = e=>{
      let end_x = e.changedTouches[0].clientX;
      if (start_x - end_x > 50)
        on_close();
    };
    document.addEventListener('touchstart', touch_start_handle);
    document.addEventListener('touchend', touch_end_handle);
    return ()=>{
      document.removeEventListener('touchstart', touch_start_handle);
      document.removeEventListener('touchend', touch_end_handle);
    };
  }, [is_open, on_close]);
  return (
    <Drawer open={is_open} onClose={on_close} placement="left"
      styles={{body: {padding: '10px 0px'}}} closeIcon={null}>
      {message_ctx_holder}
      <Tree showLine switcherIcon={<DownOutlined />} autoExpandParent
        selectedKeys={selected_keys} onSelect={select_handle}
        treeData={tree_data} expandedKeys={expanded_keys} showIcon
        onExpand={expand_handle} draggable={{icon: false}} blockNode
        rootStyle={{background: 'none', margin: '0px 28px'}}
        onDrop={drop_handle} />
    </Drawer>
  );
});
let Header = React.memo(({on_drawer_open, query, on_query_change, proj,
  on_path_change, proj_get})=>{
  let {t} = useTranslation();
  let {token: {colorBgContainer}} = theme.useToken();
  let {is_mobile} = use_is_mobile();
  let {user, token, org, user_full} = auth.use_auth();
  let [message_api, message_ctx_holder] = message.useMessage();
  let [is_org_select_loading, is_select_loading_set] = useState(false);
  let org_opts = useMemo(()=>{
    if (!user_full)
      return [];
    return Object.values(user_full.orgs).map(_org=>({value: _org.id,
      key: _org.id, label: _org.lbl}));
  }, [user_full]);
  let org_select_handle = useCallback(org_id=>eserf(function*
  _org_select_handle(){
    is_select_loading_set(true);
    let res = yield back_app.user_set_org_id_select(token, user.email, org_id);
    is_select_loading_set(false);
    if (res.err)
      return void message_api.error(t('org select failed'));
    je.set_inc('workspace.update_n');
    let proj_id = `/${org_id}/root/${proj_lbl}`;
    yield proj_get(proj_id);
    on_path_change(str.mongo2path(proj_id), true);
  }), [message_api, on_path_change, proj_get, t, token, user]);
  let query_change_handle = useCallback(e=>{
    on_query_change(e.target.value);
  }, [on_query_change]);
  let select_change_handle = useCallback(org_id=>{
    org_select_handle(org_id);
  }, [org_select_handle]);
  let dropdown_click_handle = useCallback(e=>{
    org_select_handle(e.key);
  }, [org_select_handle]);
  return (
    <Layout.Header style={{display: 'flex', alignItems: 'center',
      background: colorBgContainer, gap: '8px', padding: '0 10px',
      justifyContent: is_mobile ? 'space-between' : 'flex-start'}}>
      {message_ctx_holder}
      <Button onClick={on_drawer_open} type="text" icon={<MenuOutlined />} />
      {!is_mobile && <Select value={org?.id} options={org_opts}
        loading={is_org_select_loading || !org} onChange={select_change_handle}
        style={{width: '250px'}} />}
      <Input placeholder={t('Search in project...')}
        value={query} onChange={query_change_handle}
        style={{flexGrow: 1, maxWidth: '250px'}} disabled={!proj} />
      {is_mobile && <Dropdown menu={{items: org_opts, selectable: true,
        selectedKeys: [org?.id], onClick: dropdown_click_handle}}>
        <Button type="text" icon={<BankOutlined />} />
      </Dropdown>}
    </Layout.Header>
  );
});
let Add_dir_modal = React.memo(({is_open, token, path, proj_get, on_close})=>{
  let {t} = useTranslation();
  let [form] = Form.useForm();
  let [message_api, message_ctx_holder] = message.useMessage();
  let [is_loading, is_loading_set] = useState(false);
  let input_ref = useRef(null);
  let submit_handle = useCallback(()=>eserf(function* _submit_handle(){
    let values = yield this.wait_ext2(form.validateFields());
    if (values.err || !token)
      return;
    is_loading_set(true);
    let file_id = str.path2mongo(path + '/' + values.lbl);
    let res = yield back_app.file.touch(token, file_id, true);
    is_loading_set(false);
    if (res.err == 'already_exist')
      return message_api.error(t('Folder already exists'));
    if (res.err == 'forbidden')
      return message_api.error(t('Access denied'));
    if (res.err)
    {
      message_api.error(t('Something went wrong'));
      return metric.error('file_touch_err', res.err);
    }
    on_close();
    proj_get();
    form.resetFields();
  }), [token, form, path, message_api, t, proj_get, on_close]);
  let forbidden_chars_validator = useCallback((rule, value)=>{
    return str.dir_forbidden_chars.some(char=>value.includes(char))
      ? Promise.reject(new Error(t('Incorrect name'))) : Promise.resolve();
  }, [t]);
  useEffect(()=>{
    if (!is_open)
      return;
    // https://github.com/ant-design/ant-design/issues/8668
    setTimeout(()=>{
      input_ref.current?.focus();
      input_ref.current?.select();
    }, 0);
  }, [is_open]);
  return (
    <Modal title={t('New folder')} open={is_open} confirmLoading={is_loading}
      onOk={submit_handle} onCancel={on_close} destroyOnClose>
      {message_ctx_holder}
      <Form form={form} layout="vertical" preserve={false}
        initialValues={{lbl: 'Untitled Folder'}} onFinish={submit_handle}>
        <Form.Item name="lbl"
          rules={[{required: true, message: t('Please, input the name')},
            {validator: forbidden_chars_validator}]}>
          <Input ref={input_ref} />
        </Form.Item>
      </Form>
    </Modal>
  );
});
let Rename_file_modal = React.memo(({is_open, token, file_id, proj_get,
  is_dir, on_close})=>{
  let {t} = useTranslation();
  let [form] = Form.useForm();
  let [message_api, message_ctx_holder] = message.useMessage();
  let input_ref = useRef(null);
  let [is_loading, is_loading_set] = useState(false);
  let submit_handle = useCallback(()=>eserf(function* _submit_handle(){
    let values = yield this.wait_ext2(form.validateFields());
    if (values.err || !token)
      return;
    let target_file_id = file_id.split('/').slice(0, -1).join('/') + '/'
      + str.path2mongo(values.lbl);
    is_loading_set(true);
    let res = yield back_app.file.mv(token, file_id, target_file_id);
    is_loading_set(false);
    if (res.err)
    {
      message_api.error(t('Something went wrong'));
      return metric.error('file_mv_err', res.err);
    }
    on_close();
    proj_get();
    form.resetFields();
  }), [file_id, form, proj_get, message_api, on_close, t, token]);
  useEffect(()=>{
    if (!is_open)
      return;
    form.setFieldsValue({lbl: str.mongo2path(file_id.split('/').pop())});
    // https://github.com/ant-design/ant-design/issues/8668
    setTimeout(()=>input_ref.current?.focus(), 0);
  }, [form, file_id, is_open]);
  return (
    <Modal title={t(is_dir ? 'Rename folder' : 'Rename file')} open={is_open}
      confirmLoading={is_loading} onOk={submit_handle} onCancel={on_close}
      destroyOnClose>
      {message_ctx_holder}
      <Form form={form} layout="vertical" preserve={false}
        initialValues={{lbl: file_id ? file_id.split('/').pop() : ''}}
        onFinish={submit_handle}>
        <Form.Item name="lbl"
          rules={[{required: true, message: t('Please, input the name')}]}>
          <Input ref={input_ref} />
        </Form.Item>
      </Form>
    </Modal>
  );
});
let Edit_proj_link_modal = React.memo(({is_open, on_close, proj_link, token,
  proj_id, share_files, proj_get})=>{
  let {t} = useTranslation();
  let [form] = Form.useForm();
  let [message_api, message_ctx_holder] = message.useMessage();
  let lbl_input_ref = useRef(null);
  let [is_loading, is_loading_set] = useState(false);
  use_effect_eserf(()=>eserf(function* _use_effect_proj_link_insert(){
    if (!is_open)
      return;
    let file_inodes = share_files.map(file=>file.inode);
    let is_files_changed = !_.isEqual(file_inodes.sort(),
      Object.keys(proj_link.file_inodes).sort());
    if (!is_files_changed)
      return;
    let file_inodes_o = {};
    for (let inode of file_inodes)
      file_inodes_o[inode] = 1;
    let res = yield back_app.proj_link.set(token, proj_id,
      proj_link.id, 'file_inodes', file_inodes_o);
    if (res.err)
    {
      message_api.error(t('Something went wrong'));
      return metric.error('proj_link_set_err', res.err);
    }
    proj_get();
  }), [share_files, form, is_open, message_api, proj_id, t, token]);
  let access_opts = useMemo(()=>{
    return [
      {value: 'public', label: t('Public')},
      {value: 'private', label: t('Private')}
    ];
  }, [t]);
  let modal_title = useMemo(()=>{
    if (!is_open)
      return null;
    if (!proj_link)
      assert(0, 'no proj_link');
    return t('Edit link') + ' "' + proj_link.lbl + '"';
  }, [is_open, proj_link, t]);
  let initial_values = useMemo(()=>{
    return {lbl: '', access: 'public', passwd: ''};
  }, []);
  let submit_handle = useCallback(()=>eserf(function* _submit_handle(){
    let values = yield this.wait_ext2(form.validateFields());
    if (values.err || !token)
      return;
    let ess = [];
    if (proj_link.lbl != values.lbl)
    {
      ess.push(back_app.proj_link.set(token, proj_id, proj_link.id, 'lbl',
        values.lbl));
    }
    let is_private = values.access == 'private';
    if (proj_link.is_private != is_private)
    {
      ess.push(back_app.proj_link.set(token, proj_id, proj_link.id,
        'is_private', is_private));
    }
    if (is_private && proj_link.passwd != values.passwd)
    {
      ess.push(back_app.proj_link.set(token, proj_id, proj_link.id, 'passwd',
        values.passwd));
    }
    if (ess.length == 0)
      return;
    is_loading_set(true);
    let resps = yield this.wait_ret(ess);
    is_loading_set(false);
    let err_res = resps.find(res=>res.err);
    if (err_res?.err == 'forbidden')
      return message_api.error(t('Access denied'));
    if (err_res)
    {
      message_api.error(t('Something went wrong'));
      return metric.error('proj_link_set_err', err_res.err);
    }
    proj_get();
    on_close();
  }), [form, message_api, on_close, proj_get, proj_id, proj_link, t, token]);
  let copy_btn_click_handle = useCallback(()=>eserf(function*
  _copy_btn_click_handle(){
    if (!proj_id)
      assert(0, 'no proj_id');
    if (!proj_link)
      assert(0, 'no proj_link');
    let url = proj_link2url(proj_id, proj_link.id);
    let res = yield url_copy(url);
    if (res.err)
      message_api.error(t('Something went wrong'));
  }), [message_api, proj_id, proj_link, t]);
  useEffect(()=>{
    if (!is_open)
      return;
    if (!proj_id)
      assert(0, 'no proj_id');
    if (!proj_link)
      assert(0, 'no proj_link');
    let url = proj_link2url(proj_id, proj_link.id);
    form.setFieldsValue({lbl: proj_link.lbl, passwd: proj_link.passwd, url,
      access: proj_link.is_private ? 'private' : 'public'});
    setTimeout(()=>{
      lbl_input_ref.current?.focus();
      lbl_input_ref.current?.select();
    }, 0);
  }, [form, is_open, proj_link, proj_id]);
  let access = Form.useWatch('access', form);
  return (
    <Modal title={modal_title} open={is_open} onCancel={on_close} destroyOnClose
      okText={t('Save')} onOk={submit_handle} confirmLoading={is_loading}>
      {message_ctx_holder}
      <Form form={form} layout="vertical" preserve={false}
        initialValues={initial_values}>
        <Form.Item name="lbl" label={t('Name')}
          rules={[{required: true, message: t('Please, input the name')}]}>
          <Input disabled={is_loading} ref={lbl_input_ref} />
        </Form.Item>
        <Form.Item label={t('Link access')}>
          <Space.Compact block>
            <Form.Item name="url" noStyle>
              <Input disabled />
            </Form.Item>
            <Button icon={<CopyOutlined />} onClick={copy_btn_click_handle} />
          </Space.Compact>
        </Form.Item>
        <Form.Item name="access" label={t('Access')}
          rules={[{required: true, message: t('Please, select the access')}]}>
          <Select options={access_opts} disabled={is_loading} />
        </Form.Item>
        {access == 'private' && <Form.Item name="passwd"
          rules={[{required: true, message: t('Please, input the password')}]}
          label={t('Password')}>
          <Input.Password disabled={is_loading} />
        </Form.Item>}
      </Form>
    </Modal>
  );
});
let Share_modal = React.memo(({is_open, on_close, share_files, proj_id,
  token, proj_get, proj_links, on_editing_proj_link_change})=>{
  let {t} = useTranslation();
  let [form] = Form.useForm();
  let [message_api, message_ctx_holder] = message.useMessage();
  let [is_loading, is_loading_set] = useState(false);
  let action_opts = useMemo(()=>{
    return [
      {value: 'create_new', label: t('Create new link')},
      {value: 'add_to_existing', label: t('Add to existing link')},
    ];
  }, [t]);
  let proj_links_opts = useMemo(()=>{
    return proj_links.map(proj_link=>({value: proj_link.id,
      label: proj_link.lbl}));
  }, [proj_links]);
  let access_opts = useMemo(()=>{
    return [
      {value: 'public', label: t('Public')},
      {value: 'private', label: t('Private')}
    ];
  }, [t]);
  let modal_title = useMemo(()=>{
    if (!is_open)
      return null;
    return t('Share') + ' ' + share_files.length + ' ' + t('items');
  }, [is_open, share_files, t]);
  let submit_handle = useCallback(()=>eserf(function* _submit_handle(){
    let values = yield this.wait_ext2(form.validateFields());
    if (values.err || !token)
      return;
    let file_inodes = share_files.map(file=>file.inode);
    if (values.action == 'create_new')
    {
      let is_private = values.access == 'private';
      is_loading_set(true);
      let res = yield back_app.proj_link.insert(token, proj_id, values.lbl,
        is_private, values.passwd, file_inodes);
      is_loading_set(false);
      if (res.err == 'forbidden')
        return message_api.error(t('Access denied'));
      if (res.err)
      {
        message_api.error(t('Something went wrong'));
        return metric.error('proj_link_insert_err', res.err);
      }
      proj_get();
      on_editing_proj_link_change(res.proj_link);
      on_close();
      return;
    }
    if (values.action == 'add_to_existing')
    {
      let proj_link = proj_links.find(_proj_link=>{
        return _proj_link.id == values.proj_link_id;
      });
      if (!proj_link)
        assert(0, 'proj_link not found');
      let new_file_inodes = {...proj_link.file_inodes};
      for (let file_inode of file_inodes)
        new_file_inodes[file_inode] = 1;
      is_loading_set(true);
      let res = yield back_app.proj_link.set(token, proj_id, proj_link.id,
        'file_inodes', new_file_inodes);
      is_loading_set(false);
      if (res.err == 'forbidden')
        return message_api.error(t('Access denied'));
      if (res.err)
      {
        message_api.error(t('Something went wrong'));
        return metric.error('proj_link_add_err', res.err);
      }
      proj_get();
      on_editing_proj_link_change(res.proj_link);
      on_close();
      return;
    }
    assert(0, 'unexpected action: ' + values.action);
  }), [form, message_api, proj_get, proj_id, proj_links, share_files, t,
    token, on_close, on_editing_proj_link_change]);
  let initial_lbl = useMemo(()=>{
    if (!is_open)
      return '';
    if (!share_files.length)
      assert(0, 'no files to share');
    if (share_files.length == 1)
      return file_id2filename(share_files[0].id);
    return t('Link') + ' - ' + xdate.date_format(new Date(), 'MMM DD, YYYY');
  }, [is_open, share_files, t]);
  let initial_values = useMemo(()=>{
    return {action: 'create_new', lbl: initial_lbl, access: 'public',
      passwd: ''};
  }, [initial_lbl]);
  useEffect(()=>{
    if (!is_open)
      return;
    form.setFieldsValue({lbl: initial_lbl});
  }, [form, initial_lbl, is_open]);
  let action = Form.useWatch('action', form);
  let access = Form.useWatch('access', form);
  return (
    <Modal title={modal_title} open={is_open} onCancel={on_close} destroyOnClose
      okText={action == 'create_new' ? t('Share') : t('Add new items')}
      onOk={submit_handle} confirmLoading={is_loading}>
      {message_ctx_holder}
      <Form form={form} layout="vertical" preserve={false}
        initialValues={initial_values} onFinish={submit_handle}>
        <Form.Item name="action"
          rules={[{required: true, message: t('Please, select the action')}]}>
          <Select options={action_opts} />
        </Form.Item>
        {action == 'create_new' && <Form.Item name="lbl" label={t('Name')}
          rules={[{required: true, message: t('Please, input the name')}]}>
          <Input disabled={is_loading} />
        </Form.Item>}
        {action == 'create_new' && <Form.Item name="access" label={t('Access')}
          rules={[{required: true, message: t('Please, select the access')}]}>
          <Select options={access_opts} />
        </Form.Item>}
        {action == 'create_new' && access == 'private' && <Form.Item
          name="passwd" label={t('Password')}
          rules={[{required: true, message: t('Please, input the password')}]}>
          <Input.Password disabled={is_loading} />
        </Form.Item>}
        {action == 'add_to_existing' && <Form.Item name="proj_link_id"
          rules={[{required: true, message: t('Please, select a link')}]}
          label={t('Link')}>
          <Select options={proj_links_opts} />
        </Form.Item>}
      </Form>
    </Modal>
  );
});
let Thumbnail = React.memo(({filename, src, is_dir, is_loading, progress})=>{
  let {token: {borderRadiusLG}} = theme.useToken();
  if (is_loading && progress !== undefined)
    assert(0, 'loading_and_progress_at_the_same_time');
  if (is_loading)
  {
    return <div style={{minWidth: '56px', display: 'flex',
      justifyContent: 'center'}}>
      <LoadingOutlined style={{color: '#272A3C', fontSize: '36px'}} />
    </div>;
  }
  if (progress !== undefined)
  {
    return <div style={{minWidth: '56px', height: '32px', display: 'flex',
      justifyContent: 'center'}}>
      <Progress type="circle" percent={progress * 100} size={32}
        format={percent=>Math.floor(percent)} />
    </div>;
  }
  if (is_dir)
  {
    return <div style={{minWidth: '56px', display: 'flex',
      justifyContent: 'center'}}>
      <FolderFilled style={{color: '#272A3C', fontSize: '36px'}} />
    </div>;
  }
  let ext = filename.split('.').at(-1);
  if (ext == 'aaf')
  {
    return <div style={{minWidth: '56px', display: 'flex',
      justifyContent: 'center'}}>
      <Icon component={Sequence_icon} style={{color: '#272A3C',
        fontSize: '32px'}} />
    </div>;
  }
  if (is_video(filename))
  {
    return <video src={src} width="56" height="32" muted
      preload="metadata" style={{borderRadius: borderRadiusLG,
        background: '#000000', minWidth: '56px'}} />;
  }
  if (is_image(filename))
  {
    return <img src={src} width="56" height="32"
      style={{minWidth: '56px', borderRadius: borderRadiusLG,
        objectFit: 'cover'}} />;
  }
  return (
    <div style={{minWidth: '56px', display: 'flex', justifyContent: 'center'}}>
      <FileFilled style={{color: '#272A3C', fontSize: '28px'}} />
    </div>
  );
});
export let File_name = React.memo(({filename, is_dir, src, is_loading, progress,
  status})=>{
  return (
    <div style={{display: 'flex', alignItems: 'center', height: '42px',
      gap: '8px', userSelect: 'none'}}>
      <Thumbnail filename={filename} is_dir={is_dir} src={src}
        is_loading={is_loading} progress={progress} />
      <div style={{display: 'flex', flexDirection: 'column', height: '42px',
        justifyContent: 'center'}}>
        <Typography.Text>{filename}</Typography.Text>
        {status && <Typography.Text type="secondary"
          style={{fontSize: '12px'}}>
          {status}
        </Typography.Text>}
      </div>
    </div>
  );
});
export let bytes_format = bytes=>{
  if (!bytes)
    return '0 Bytes';
  let units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let power = Math.floor(Math.log2(bytes) / 10);
  power = Math.min(power, units.length - 1);
  bytes /= Math.pow(1024, power);
  return `${bytes.toFixed(1)} ${units[power]}`;
};
export let folder_size_get = file=>{
  if (!file.is_dir)
    return file.size;
  let children = Object.values(file.children);
  if (!children.length)
    return 0;
  return children.map(folder_size_get).reduce((accum, cur)=>accum + cur);
};
let Mv_cp_modal = React.memo(({is_open, on_close, proj, files,
  selected_files, token, proj_get, cmd})=>{
  let {t} = useTranslation();
  let [message_api, message_ctx_holder] = message.useMessage();
  let [path, path_set] = useState();
  let [is_loading, is_loading_set] = useState(false);
  let initial_dir_id = useMemo(()=>{
    if (!selected_files.length)
      return null;
    let file = selected_files[0];
    return file.id.split('/').slice(0, -1).join('/');
  }, [selected_files]);
  let cur_files = useMemo(()=>{
    if (!path || !files)
      return {};
    let root_dir = {children: files};
    let cur_dir = root_dir;
    let arr = path.split('/').slice(4);
    for (let lbl of arr)
    {
      cur_dir = cur_dir.children[lbl];
      if (!cur_dir)
        break;
    }
    if (!cur_dir)
      cur_dir = root_dir;
    return cur_dir.children;
  }, [files, path]);
  let submit_handle = useCallback(()=>eserf(function* _submit_handle(){
    if (!token || !selected_files.length)
      return;
    let back_app_method;
    if (cmd == 'mv')
      back_app_method = back_app.file.mv;
    else if (cmd == 'cp')
      back_app_method = back_app.file.cp;
    else
      assert(0, 'unknown cmd');
    is_loading_set(true);
    let res = yield this.wait_ret(selected_files.map(file=>{
      let filename = file_id2filename(file.id);
      let target_file_id = str.path2mongo(path + '/' + filename);
      let idx = 1;
      // eslint-disable-next-line no-loop-func
      while (Object.values(cur_files).some(f=>f.id == target_file_id))
      {
        target_file_id = str.path2mongo(path + '/'
          + filename_idx_add(filename, idx));
        idx += 1;
      }
      return back_app_method(token, file.id, target_file_id);
    }));
    is_loading_set(false);
    if (res.err)
    {
      message_api.error(t('Something went wrong'));
      return metric.error('file_mv_err', res.err);
    }
    on_close();
    proj_get();
  }), [selected_files, proj_get, message_api, on_close, t, token, cmd,
    cur_files, path]);
  let cols = useMemo(()=>{
    return [
      {title: t('Name'), key: 'name', dataIndex: 'name', ellipsis: true},
    ];
  }, [t]);
  let data_src = useMemo(()=>{
    if (!path || !files)
      return [];
    let _data_src = Object.values(cur_files)
      .filter(_file=>_file.is_dir)
      .map(_file=>{
        let record = {};
        record.file_id = _file.id;
        record.file = _file;
        let _filename = str.mongo2path(_file.id).split('/').pop();
        if (_file.is_dir)
        {
          record.name = <File_name filename={_filename} is_dir
            status={Object.keys(_file.children).length + ' ' + t('items')} />;
        }
        else
        {
          record.name = <File_name filename={_filename}
            src={_file.src.pub_url} />;
        }
        return record;
      });
    return _data_src;
  }, [files, path, t, cur_files]);
  let breadcrumb_items = useMemo(()=>{
    if (!proj || !path)
      return [];
    let _breadcrumb_items = [];
    let arr = path.split('/');
    for (let idx = 3; idx < arr.length; idx += 1)
    {
      let lbl = str.mongo2path(arr[idx]);
      let sub_path = arr.slice(0, idx + 1).join('/');
      _breadcrumb_items.push({title: <Clickable>
        <Button type="text">
          {lbl}
        </Button>
      </Clickable>,
      onClick: ()=>path_set(sub_path, true)});
    }
    return _breadcrumb_items;
  }, [path, proj]);
  let row_handle = useCallback(record=>{
    return {
      onDoubleClick: ()=>{
        path_set(record.file.id, record.file.is_dir);
      },
    };
  }, []);
  useEffect(()=>{
    if (!initial_dir_id)
      return;
    path_set(str.mongo2path(initial_dir_id));
  }, [initial_dir_id]);
  let modal_title = useMemo(()=>{
    if (!is_open)
      return null;
    if (cmd == 'mv')
      return t('Move to');
    if (cmd == 'cp')
      return t('Copy to');
    assert(0, 'unexpected cmd', cmd);
  }, [cmd, is_open, t]);
  let is_ok_btn_disabled = useMemo(()=>{
    if (cmd == 'cp')
      return false;
    return str.path2mongo(path) == initial_dir_id;
  }, [cmd, initial_dir_id, path]);
  return (
    <Modal title={modal_title} open={is_open} onOk={submit_handle}
      onCancel={on_close} confirmLoading={is_loading}
      okButtonProps={{disabled: is_ok_btn_disabled}}>
      {message_ctx_holder}
      <Table columns={cols} dataSource={data_src} size="small"
        pagination={{hideOnSinglePage: true}} onRow={row_handle}
        rowClassName="workspace-table-row" rowKey="file_id"
        showHeader={false} />
      <Breadcrumb style={{margin: '24px 0 0 0'}}
        items={breadcrumb_items} separator={<RightOutlined />} />
    </Modal>
  );
});
let Files_list_row_ctx = createContext(null);
let Breadcrumb_title_item = React.memo(({file_id, ...rest})=>{
  let {t} = useTranslation();
  let {setNodeRef: node_ref_set,
    isOver: is_over} = useDroppable({id: file_id});
  let style = {height: '45px', ...rest.style,
    ...is_over ? {background: purple.primary} : {}};
  return <Clickable>
    <Button {...rest} ref={node_ref_set} type="text" style={style}>
      <Typography.Title level={3} style={{margin: 0}}>
        {t('Workspace')}
      </Typography.Title>
    </Button>
  </Clickable>;
});
let Breadcrumb_item = React.memo(({lbl, file_id, ...rest})=>{
  let {setNodeRef: node_ref_set,
    isOver: is_over} = useDroppable({id: file_id});
  let style = {height: '45px', ...rest.style,
    ...is_over ? {background: purple.primary} : {}};
  return <Clickable>
    <Button {...rest} ref={node_ref_set} type="text" style={style}>
      {lbl}
    </Button>
  </Clickable>;
});
let Files_list_row = React.memo(props=>{
  let {data_src} = useContext(Files_list_row_ctx);
  let record = data_src.find(r=>r.file_id == props['data-row-key']);
  let is_draggable = !!record;
  let is_droppable = !!record?.file?.is_dir;
  let {attributes: draggable_attributes, listeners: draggable_listeners,
    setNodeRef: draggable_node_ref_set,
    transition} = useDraggable({id: props['data-row-key'],
    disabled: !is_draggable});
  let {setNodeRef: droppable_node_ref_set,
    isOver: is_over} = useDroppable({id: props['data-row-key'],
    disabled: !is_droppable});
  let node_ref_set = ref=>{
    draggable_node_ref_set(ref);
    droppable_node_ref_set(ref);
  };
  let style = {...props.style, transition,
    ...is_over && is_droppable ? {background: `${purple.primary}50`} : {}};
  return <tr {...props} ref={node_ref_set} style={style}
    {...draggable_attributes} {...draggable_listeners} />;
});
let Content = React.memo(({token, path, on_path_change, proj, user_full,
  is_dir, files, proj_get, query, on_query_change})=>{
  let {t} = useTranslation();
  let es_root = use_es_root();
  let navigate = useNavigate();
  let {qs_o} = use_qs();
  let {token: {colorBgContainer: color_bg_container,
    borderRadiusLG: border_radius_lg,
    boxShadow: box_shadow}} = theme.useToken();
  let screens = Grid.useBreakpoint();
  let {is_mobile} = use_is_mobile();
  let [message_api, message_ctx_holder] = message.useMessage();
  let [modal_api, modal_ctx_holder] = Modal.useModal();
  let sensors = useSensors(
    useSensor(PointerSensor, {activationConstraint: {distance: 1}}),
    useSensor(TouchSensor),
  );
  let [is_new_folder_modal_open, is_new_folder_modal_open_set] =
    useState(false);
  let [is_rename_file_modal_open, is_rename_file_modal_open_set] =
    useState(false);
  let [rename_file, rename_file_set] = useState(null);
  let [is_mv_cp_modal_open, is_mv_cp_modal_open_set] = useState(false);
  let [mv_cp_modal_cmd, mv_cp_modal_cmd_set] = useState(null);
  let [is_share_modal_open, is_share_modal_open_set] = useState(false);
  let [is_edit_proj_link_modal_open, is_edit_proj_link_modal_open_set] =
    useState(false);
  let [ctx_file, ctx_file_set] = useState(null);
  let [ctx_proj_link, ctx_proj_link_set] = useState(null);
  let [is_media_inner_ctx_open, is_media_inner_ctx_open_set] = useState(false);
  let [is_links_inner_ctx_open, is_links_inner_ctx_open_set] = useState(false);
  let [uploading_files, uploading_files_set] = useState({});
  let [uploading_progress, uploading_progress_set] = useState({});
  let [time, time_set] = useState(Date.now());
  let [selected_file_ids, selected_file_ids_set] = useState([]);
  let [last_selected_file, last_selected_file_set] = useState(null);
  let [selected_proj_link_ids, selected_proj_link_ids_set] = useState([]);
  let [last_selected_proj_link, last_selected_proj_link_set] = useState(null);
  let [is_show_share_checkboxes, is_show_share_checkboxes_set] =
    useState(false);
  let [editing_proj_link, editing_proj_link_set] = useState(null);
  let [selected_to_share_files, selected_to_share_files_set] =
    useState([]);
  let [active_tab, active_tab_set] = useState('media');
  let [is_dragging, is_dragging_set] = useState(false);
  let [media_sorter, media_sorter_set] = useState({});
  let [media_sorted_data_src, media_sorted_data_src_set] = useState([]);
  let [links_sorter, links_sorter_set] = useState({});
  let [links_sorted_data_src, links_sorted_data_src_set] = useState([]);
  let title_click_handle = useCallback(()=>{
    if (!proj)
      return;
    on_path_change(str.mongo2path(proj.id), true);
  }, [on_path_change, proj]);
  let mouse_down_propagation_stop = useCallback(e=>{
    e.stopPropagation();
  }, []);
  let breadcrumb_items = useMemo(()=>{
    if (!proj || !path)
      return [];
    let _breadcrumb_items = [
      {title: <Breadcrumb_title_item file_id={proj.id}
        onMouseDown={mouse_down_propagation_stop} />,
      onClick: title_click_handle},
    ];
    let arr = path.split('/');
    for (let idx = 4; idx < arr.length; idx += 1)
    {
      let lbl = str.mongo2path(arr[idx]);
      let subpath = arr.slice(0, idx + 1).join('/');
      _breadcrumb_items.push({title: <Breadcrumb_item lbl={lbl}
        file_id={str.path2mongo(subpath)}
        onMouseDown={mouse_down_propagation_stop} />,
      onClick: ()=>on_path_change(subpath, true)});
    }
    return _breadcrumb_items;
  }, [title_click_handle, mouse_down_propagation_stop, proj, path,
    on_path_change]);
  let media_cols = useMemo(()=>{
    let _cols = [
      {title: t('Name'), key: 'name', dataIndex: 'name', ellipsis: true,
        sorter: (a, b)=>str.cmp(a.file.id, b.file.id),
        width: screens.md ? '40%' : '80%'},
    ];
    if (screens.lg)
    {
      _cols.push({title: t('Type'), key: 'type', dataIndex: 'type',
        sorter: (a, b)=>str.cmp(a.type, b.type)});
      _cols.push({title: t('Status'), key: 'status', dataIndex: 'status',
        sorter: (a, b)=>str.cmp(a.status, b.status)});
      _cols.push({title: t('Comments'), key: 'cmts', dataIndex: 'cmts',
        sorter: (a, b)=>b.cmts - a.cmts});
    }
    if (screens.md)
    {
      _cols.push({title: t('Size'), key: 'size', dataIndex: 'size',
        sorter: (a, b)=>{
          a = a.file.is_dir ? folder_size_get(a.file) : a.file.size;
          b = b.file.is_dir ? folder_size_get(b.file) : b.file.size;
          return b - a;
        }});
      _cols.push({title: t('Date uploaded'), key: 'date_uploaded',
        dataIndex: 'date_uploaded',
        sorter: (a, b)=>xdate.cmp(a.file.ts.insert, b.file.ts.insert)});
      _cols.push({title: t('Uploader'), key: 'uploader',
        dataIndex: 'uploader',
        sorter: (a, b)=>str.cmp(a.uploader, b.uploader)});
    }
    _cols.push({title: t('Action'), key: 'action', dataIndex: 'action'});
    return _cols;
  }, [screens.lg, screens.md, t]);
  let links_cols = useMemo(()=>{
    let _cols = [
      {title: t('Name'), key: 'lbl', dataIndex: 'lbl', ellipsis: true,
        sorter: (a, b)=>str.cmp(a.proj_link.lbl, b.proj_link.lbl),
        width: '40%'},
    ];
    if (screens.md)
    {
      _cols.push({title: t('Date created'), key: 'date_created',
        dataIndex: 'date_created',
        sorter: (a, b)=>{
          return xdate.cmp(a.proj_link.ts.insert, b.proj_link.ts.insert);
        }});
      _cols.push({title: t('Created by'), key: 'creator',
        dataIndex: 'creator',
        sorter: (a, b)=>str.cmp(a.creator, b.creator)});
      _cols.push({title: t('Visits'), key: 'visit',
        dataIndex: 'visit', sorter: (a, b)=>b.visit - a.visit});
      _cols.push({title: t('Active'), key: 'active',
        dataIndex: 'active',
        sorter: (a, b)=>b.proj_link.is_active - a.proj_link.is_active});
    }
    _cols.push({title: t('Action'), key: 'action', dataIndex: 'action'});
    return _cols;
  }, [screens.md, t]);
  let new_btn_items = useMemo(()=>{
    return [
      {label: t('File upload'), key: 'file_upload', icon: <UploadOutlined />},
      {label: t('Folder upload'), key: 'folder_upload',
        icon: <FolderAddOutlined />},
      {type: 'divider'},
      {label: t('New folder'), key: 'new_folder', icon: <FolderOutlined />},
      {label: t('New private folder'), key: 'new_private_folder',
        icon: <FolderViewOutlined />, disabled: true},
    ];
  }, [t]);
  let cur_files = useMemo(()=>{
    let root_dir = {children: files};
    let cur_dir = root_dir;
    let arr = path.split('/').slice(4);
    for (let lbl of arr)
    {
      cur_dir = cur_dir.children[lbl];
      if (!cur_dir)
        break;
    }
    if (!cur_dir)
      cur_dir = root_dir;
    return cur_dir.children;
  }, [files, path]);
  let plain_files = useMemo(()=>{
    let queue = Object.values(files);
    let _files = [];
    while (queue.length)
    {
      let file = queue.shift();
      _files.push(file);
      if (file.children)
        queue = [...queue, ...Object.values(file.children)];
    }
    return _files;
  }, [files]);
  let query_files = useMemo(()=>{
    let _query = query.trim().toLowerCase();
    return plain_files
      .filter(file=>{
        let filename = file_id2filename(file.id);
        return filename.trim().toLowerCase().includes(_query)
        || file.user_id.trim().toLowerCase().includes(_query)
        || Object.values(file.cmts).map(cmt=>cmt.msg.trim().toLowerCase())
          .some(msg=>msg.includes(_query));
      });
  }, [plain_files, query]);
  let already_existing_file_handle = useCallback(filename=>eserf(function*
  _already_existing_file_handle(){
    let modal;
    let form;
    let cancel_handle = ()=>{
      modal.destroy();
      this.continue(null);
    };
    let make_copy_handle = ()=>{
      modal.destroy();
      let basename = filename.split('.').slice(0, -1).join('.');
      let ext = filename.split('.').slice(-1)[0];
      let new_filename = filename;
      for (let idx = 1; cur_files[str.path2mongo(new_filename)]; idx += 1)
        new_filename = `${basename} (${idx}).${ext}`;
      this.continue(new_filename);
    };
    let _this = this;
    let rename_handle = ()=>eserf(function* _rename_handle(){
      let values = yield this.wait_ext2(form.validateFields());
      if (values.err)
        return;
      modal.destroy();
      _this.continue(values.filename);
    });
    let filename_validator = (rule, value)=>{
      if (cur_files[str.path2mongo(value)])
        return Promise.reject(new Error(t('File already exists')));
      return Promise.resolve();
    };
    modal = modal_api.warning({
      title: `An item "${filename}" already exists`,
      content: <Form layout="vertical" initialValues={{filename}}
        ref={el=>form = el}>
        <Form.Item label={t('New filename')} name="filename"
          rules={[{required: true, message: t('Please, enter the filename')},
            {validator: filename_validator}]}>
          <Input />
        </Form.Item>
      </Form>,
      footer: ()=><Row justify="space-between">
        <Col>
          <Space>
            <Button onClick={cancel_handle} danger>
              Cancel
            </Button>
          </Space>
        </Col>
        <Col>
          <Space>
            <Button onClick={make_copy_handle}>
              Make a copy
            </Button>
            <Button type="primary" onClick={rename_handle}>
              Rename
            </Button>
          </Space>
        </Col>
      </Row>,
    });
    return yield this.wait();
  }), [cur_files, modal_api, t]);
  let upload_handle = useCallback(accepted_files=>eserf(function*
  _upload_files(){
    let _uploading_progress_part = {};
    for (let file of accepted_files)
      _uploading_progress_part[file.name] = 0;
    let _uploading_files_part = {};
    for (let file of accepted_files)
    {
      let file_id = native_file2file_id(path, file);
      if (plain_files.find(_file=>_file.id == file_id))
      {
        let new_filename = yield already_existing_file_handle(file.name);
        if (!new_filename)
          continue;
        file_id = file_id.split('/').slice(0, -1).join('/') + '/'
          + str.path2mongo(new_filename);
        file = new File([file], new_filename, {type: file.type});
      }
      _uploading_files_part[file_id] = {ts: new Date(), file};
    }
    uploading_files_set(_uploading_files=>{
      return {..._uploading_files, ..._uploading_files_part};
    });
    uploading_progress_set(_uploading_progress=>{
      return {..._uploading_progress, ..._uploading_progress_part};
    });
    for (let file_id in _uploading_files_part)
    {
      let {file} = _uploading_files_part[file_id];
      es_root.spawn(eserf(function* _uploading_for_each(){
        let res = yield back_app.file.upload(token, [file_id], [file],
          ({event: _e})=>{
            uploading_progress_set(_uploading_progress=>{
              return {..._uploading_progress, [file.name]: _e.progress};
            });
          });
        uploading_files_set(_uploading_files=>{
          _uploading_files = {..._uploading_files};
          delete _uploading_files[file_id];
          return _uploading_files;
        });
        uploading_progress_set(_uploading_progress=>{
          _uploading_progress = {..._uploading_progress};
          delete _uploading_progress[file.name];
          return _uploading_progress;
        });
        if (res.err == 'already_exist')
          return message_api.error(t('File already exists'));
        if (res.err == 'invalid ext')
        {
          let ext = file.name.split('.').pop();
          return message_api.error(
            t(`File extension "${ext}" is not supported`));
        }
        if (res.err)
        {
          message_api.error(t('Something went wrong'));
          return metric.error('file_rm_err', res.err);
        }
        proj_get();
      }));
    }
  }), [already_existing_file_handle, path, es_root, token, message_api, t,
    proj_get, plain_files]);
  let files_upload = useCallback((_is_dir=false)=>{
    let file_input = document.createElement('input');
    file_input.type = 'file';
    file_input.multiple = true;
    if (_is_dir)
    {
      file_input.directory = true;
      file_input.webkitdirectory = true;
      file_input.mozdirectory = true;
    }
    file_input.onchange = e=>{
      upload_handle(Array.from(e.target.files));
    };
    file_input.click();
  }, [upload_handle]);
  let new_btn_click_handle = useCallback(e=>{
    if (e.key == 'file_upload')
      files_upload();
    if (e.key == 'folder_upload')
      files_upload(true);
    if (e.key == 'new_folder')
      is_new_folder_modal_open_set(true);
  }, [files_upload]);
  let {getRootProps: root_props_get,
    isDragActive: is_files_drag_active} = useDropzone({
    onDrop: _files=>upload_handle(_files), noClick: true});
  let add_dir_modal_close_handle = useCallback(()=>{
    is_new_folder_modal_open_set(false);
  }, []);
  let rename_file_modal_close_handle = useCallback(()=>{
    is_rename_file_modal_open_set(false);
  }, []);
  let move_file_modal_close_handle = useCallback(()=>{
    is_mv_cp_modal_open_set(false);
    mv_cp_modal_cmd_set(null);
  }, []);
  let editing_proj_link_show = useCallback(updated_proj_link=>{
    let _selected_files = plain_files.filter(_file=>{
      return updated_proj_link.file_inodes[_file.inode];
    });
    selected_to_share_files_set(_selected_files);
    editing_proj_link_set(updated_proj_link);
    is_edit_proj_link_modal_open_set(true);
  }, [plain_files]);
  let share_files_modal_close_handle = useCallback(updated_proj_link=>{
    is_share_modal_open_set(false);
  }, []);
  let edit_proj_link_modal_close_handle = useCallback(()=>{
    is_edit_proj_link_modal_open_set(false);
    editing_proj_link_set(null);
  }, []);
  let editor_qs_o_get = useCallback(file=>{
    return {...qs_o, src: JSON.stringify(file.src), src_path: path,
      src_is_dir: is_dir};
  }, [is_dir, path, qs_o]);
  let open_aaf_in_editor = useCallback(file=>{
    navigate(xurl.url('/editor', editor_qs_o_get(file)));
  }, [editor_qs_o_get, navigate]);
  let open_aaf_in_highlighter = useCallback(file=>{
    navigate(xurl.url('/highlighter', editor_qs_o_get(file)));
  }, [editor_qs_o_get, navigate]);
  let media_row_class_name_handle = useCallback(record=>{
    let _row_class_name = 'workspace-table-row';
    if (selected_file_ids.includes(record.file.id))
      _row_class_name += ' workspace-table-row-selected';
    if (selected_file_ids.includes(record.file.id) && is_dragging)
      _row_class_name += ' workspace-table-row-dragging';
    return _row_class_name;
  }, [is_dragging, selected_file_ids]);
  let links_row_class_name_handle = useCallback(record=>{
    let _row_class_name = 'workspace-table-row';
    if (selected_proj_link_ids.includes(record.proj_link.id))
      _row_class_name += ' workspace-table-row-selected';
    return _row_class_name;
  }, [selected_proj_link_ids]);
  let outer_dropdown_items = useMemo(()=>{
    if (active_tab == 'media')
    {
      return [
        {label: t('New folder'), key: 'new_folder', icon: <FolderOutlined />},
        {label: t('New private folder'), key: 'new_private_folder',
          icon: <FolderViewOutlined />, disabled: true},
        {type: 'divider'},
        {label: t('Download all'), key: 'download_all', disabled: true,
          icon: <DownloadOutlined />},
        {label: t('Recently deleted'), key: 'recently_deleted',
          icon: <DeleteOutlined />, disabled: true},
        {type: 'divider'},
        {label: t('Project settings'), key: 'proj_settings',
          icon: <SettingOutlined />, disabled: true},
      ];
    }
    if (active_tab == 'links')
    {
      return [
        {label: t('Project settings'), key: 'proj_settings',
          icon: <SettingOutlined />, disabled: true},
      ];
    }
    assert(0, 'unexpected tab key: ', active_tab);
  }, [active_tab, t]);
  let outer_dropdown_click_handle = useCallback(e=>{
    if (e.key == 'new_folder')
      is_new_folder_modal_open_set(true);
  }, []);
  let media_inner_dropdown_items_get = useCallback(file=>{
    if (!file)
      return [];
    let ext = str.mongo2path(file.id).split('.').pop();
    let _media_inner_dropdown_items = [
      ext == 'aaf' && {label: t('Open In Highlighter'),
        key: 'open_in_highlighter', icon: <HighlightOutlined />,
        disabled: file.is_upload},
      ext == 'aaf' && {label: t('Open In Editor'),
        key: 'open_in_editor', icon: <EditOutlined />,
        disabled: file.is_upload},
      {label: t('Share'), key: 'share', icon: <ShareAltOutlined />},
      {label: t('Rename'), key: 'rename', icon: <EditOutlined />,
        disabled: file.is_upload || file.is_tcode_run},
      {type: 'divider'},
      {label: t('Move to...'), key: 'mv', icon: <EnterOutlined />,
        disabled: file.is_upload || file.is_tcode_run},
      {label: t('Copy to...'), key: 'cp', icon: <CopyOutlined />},
      {label: t('Duplicate'), key: 'duplicate', icon: <SnippetsOutlined />},
      {label: t('Make private'), key: 'make_private', icon: <LockOutlined />,
        disabled: true},
      !file.is_dir && {label: t('Download'), key: 'download',
        icon: <DownloadOutlined />,
        disabled: file.is_upload || file.is_tcode_run},
      {type: 'divider'},
      {label: t('Delete'), key: 'delete', icon: <DeleteOutlined />,
        disabled: file.is_upload || file.is_tcode_run},
    ];
    _media_inner_dropdown_items = _media_inner_dropdown_items.filter(Boolean);
    return _media_inner_dropdown_items;
  }, [t]);
  let selected_files_duplicate = useCallback(()=>eserf(function*
  _selected_files_duplicate(){
    let resps = yield this.wait_ret(selected_file_ids.map(file_id=>{
      let filename = file_id2filename(file_id);
      let dir_id = file_id.split('/').slice(0, -1).join('/');
      let target_file_id;
      let idx = 1;
      do
      {
        target_file_id = dir_id + '/'
          + str.path2mongo(filename_idx_add(filename, idx));
        idx += 1;
      // eslint-disable-next-line no-loop-func
      } while (plain_files.some(file=>file.id == target_file_id));
      return back_app.file.cp(token, file_id, target_file_id);
    }));
    let err_res = resps.find(res=>res.err);
    if (err_res?.err == 'forbidden')
      return message_api.error(t('Access denied'));
    if (err_res)
    {
      message_api.error(t('Something went wrong'));
      return metric.error('file_rm_err', err_res.err);
    }
    proj_get();
  }), [message_api, plain_files, proj_get, selected_file_ids, t, token]);
  let selected_files_delete = useCallback(()=>eserf(function*
  _selected_files_delete(){
    let resps = yield this.wait_ret(selected_file_ids.map(file_id=>{
      return back_app.file.rm(token, file_id);
    }));
    let err_res = resps.find(res=>res.err);
    if (err_res?.err == 'forbidden')
      return message_api.error(t('Access denied'));
    if (err_res)
    {
      message_api.error(t('Something went wrong'));
      return metric.error('file_rm_err', err_res.err);
    }
    proj_get();
  }), [message_api, proj_get, selected_file_ids, t, token]);
  let selected_files = useMemo(()=>{
    return plain_files.filter(file=>{
      return selected_file_ids.includes(file.id);
    });
  }, [plain_files, selected_file_ids]);
  let media_inner_dropdown_click_handle = useCallback(e=>eserf(function*
  _media_inner_dropdown_click_handle(){
    if (e.key == 'open_in_editor')
      open_aaf_in_editor(ctx_file);
    if (e.key == 'open_in_highlighter')
      open_aaf_in_highlighter(ctx_file);
    if (e.key == 'share')
    {
      if (!selected_files.length)
        assert(0, 'no files selected');
      selected_to_share_files_set(selected_files);
      is_share_modal_open_set(true);
      is_show_share_checkboxes_set(false);
    }
    if (e.key == 'rename')
    {
      if (!ctx_file)
        assert(0, 'no ctx file');
      is_rename_file_modal_open_set(true);
      rename_file_set(ctx_file);
    }
    if (e.key == 'mv')
    {
      is_mv_cp_modal_open_set(true);
      mv_cp_modal_cmd_set('mv');
    }
    if (e.key == 'cp')
    {
      is_mv_cp_modal_open_set(true);
      mv_cp_modal_cmd_set('cp');
    }
    if (e.key == 'duplicate')
    {
      if (!selected_files.length)
        assert(0, 'no files selected');
      selected_files_duplicate();
    }
    if (e.key == 'download')
    {
      if (!selected_files.length)
        assert(0, 'no files selected');
      for (let file of selected_files)
      {
        if (file.is_dir)
          continue;
        let filename = file_id2filename(file.id);
        let url = xurl.url(
          `${config_ext.back.app.url}/private/file/download.json`,
          {file_id: file.id, filename, token});
        download(url, filename);
        // browser doesn't allow to download multiple files at the same time
        yield eserf.sleep(100);
      }
    }
    if (e.key == 'delete')
    {
      if (!selected_files.length)
        assert(0, 'no files selected');
      let is_contain_file = selected_files.some(file=>!file.is_dir);
      let is_contain_folder = selected_files.some(file=>file.is_dir);
      let confirm_title;
      if (selected_files.length == 1 && is_contain_file)
        confirm_title = t('Delete this file?');
      else if (selected_files.length == 1 && is_contain_folder)
        confirm_title = t('Delete this folder?');
      else if (selected_files.length > 1 && is_contain_file
        && is_contain_folder)
      {
        confirm_title = t('Delete') + ' ' + selected_files.length + ' '
          + t('items?');
      }
      else if (selected_files.length > 1 && is_contain_file)
      {
        confirm_title = t('Delete') + ' ' + selected_files.length + ' '
          + t('files?');
      }
      else if (selected_files.length > 1 && is_contain_folder)
      {
        confirm_title = t('Delete') + ' ' + selected_files.length + ' '
          + t('folders?');
      }
      else
        assert(0, 'unexpected items selection case');
      modal_api.confirm({title: confirm_title, okText: t('Delete'),
        okButtonProps: {danger: true}, onOk: selected_files_delete,
        maskClosable: true});
    }
  }), [ctx_file, open_aaf_in_editor, open_aaf_in_highlighter,
    t, token, modal_api, selected_files_delete, selected_files,
    selected_files_duplicate]);
  let proj_link_url_copy = useCallback(url=>eserf(function*
  _proj_link_url_copy(){
    let res = yield url_copy(url);
    if (res.err)
      message_api.error(t('Something went wrong'));
  }), [message_api, t]);
  let selected_proj_links_delete = useCallback(()=>eserf(function*
  _selected_proj_links_delete(){
    let resps = yield this.wait_ret(selected_proj_link_ids.map(proj_link_id=>{
      return back_app.proj_link.delete(token, proj.id, proj_link_id);
    }));
    let err_res = resps.find(res=>res.err);
    if (err_res?.err == 'forbidden')
      return message_api.error(t('Access denied'));
    if (err_res)
    {
      message_api.error(t('Something went wrong'));
      return metric.error('proj_link_delete_err', err_res.err);
    }
    proj_get();
  }), [message_api, proj, proj_get, selected_proj_link_ids, t, token]);
  let links_inner_dropdown_click_handle = useCallback(e=>{
    if (e.key == 'settings')
    {
      let file_inodes = Object.keys(ctx_proj_link.file_inodes);
      let _selected_to_share_files = plain_files.filter(file=>{
        return file_inodes.includes(file.inode);
      });
      is_edit_proj_link_modal_open_set(true);
      selected_to_share_files_set(_selected_to_share_files);
      editing_proj_link_set(ctx_proj_link);
    }
    if (e.key == 'edit_files')
    {
      let _selected_file_ids = [];
      let ctx_proj_link_inodes = Object.keys(ctx_proj_link.file_inodes);
      for (let file of plain_files)
      {
        if (ctx_proj_link_inodes.includes(file.inode))
          _selected_file_ids.push(file.id);
      }
      active_tab_set('media');
      is_show_share_checkboxes_set(true);
      editing_proj_link_set(ctx_proj_link);
      selected_file_ids_set(_selected_file_ids);
    }
    if (e.key == 'cp_link')
      proj_link_url_copy(proj_link2url(proj.id, ctx_proj_link.id));
    if (e.key == 'delete')
    {
      if (!selected_proj_link_ids.length)
        assert(0, 'no proj links selected');
      let confirm_title;
      if (selected_proj_link_ids.length == 1)
        confirm_title = t('Delete this link?');
      else
      {
        confirm_title = t('Delete') + ' ' + selected_proj_link_ids.length
          + ' ' + t('links?');
      }
      modal_api.confirm({title: confirm_title, okText: t('Delete'),
        okButtonProps: {danger: true}, onOk: selected_proj_links_delete,
        maskClosable: true});
    }
  }, [ctx_proj_link, proj_link_url_copy, t, proj, modal_api, plain_files,
    selected_proj_link_ids, selected_proj_links_delete]);
  let media_data_src = useMemo(()=>{
    let _data_src = Object.values(query ? query_files : cur_files).map(file=>{
      let record = {file_id: file.id, status: '—', file};
      record.uploader = str.mongo2email(file.user_id).split('@')[0];
      record.date_uploaded = xdate.date_format(file.ts.insert, 'MMM DD, YYYY');
      record.cmts = file.is_dir ? '—' : Object.keys(file.cmts).length;
      let filename = str.mongo2path(file.id).split('/').pop();
      let ext = str.mongo2path(file.id).split('.').pop();
      if (file.is_dir)
      {
        record.name = <File_name filename={filename} is_dir
          status={Object.keys(file.children).length + ' ' + t('items')} />;
        record.type = t('Folder');
        record.size = bytes_format(folder_size_get(file));
      }
      else
      {
        let status;
        if (file.is_tcode_run)
        {
          let remaining_time_ms = file.ts.insert.getTime() + file.tcode_time_est
            - Date.now();
          let remaining_time_sec = remaining_time_ms / xdate.MS_SEC;
          if (remaining_time_sec < 0)
            remaining_time_sec = 0;
          status = t('Preparing') + ' ・ ' + Math.round(remaining_time_sec) + ' '
            + t('seconds left');
        }
        record.name = <File_name filename={filename} src={file.src.pub_url}
          status={status} is_loading={file.is_tcode_run} />;
        record.type = ext == 'aaf' ? t('Sequence') : t('File');
        record.size = bytes_format(file.size);
      }
      let open_change_handle = is_open=>{
        if (is_open)
          ctx_file_set(file);
        else
        {
          is_media_inner_ctx_open_set(false);
          ctx_file_set(null);
        }
      };
      record.action = <div onClick={e=>e.stopPropagation()}
        style={{display: 'flex', justifyContent: 'flex-end'}}>
        {ext == 'aaf' && screens.md && <Tooltip title={t('Open In Editor')}
          placement="bottom">
          <Button type="text" shape="circle" icon={<EditOutlined />}
            onClick={()=>open_aaf_in_editor(file)} />
        </Tooltip>}
        {ext == 'aaf' && screens.md && <Tooltip title={t('Open In Highlighter')}
          placement="bottom">
          <Button type="text" shape="circle" icon={<HighlightOutlined />}
            onClick={()=>open_aaf_in_highlighter(file)} />
        </Tooltip>}
        <Dropdown menu={{items: media_inner_dropdown_items_get(file),
          onClick: media_inner_dropdown_click_handle}}
        onOpenChange={open_change_handle} placement="bottomRight"
        trigger={['click']}>
          <Button type="text" shape="circle" icon={<MoreOutlined />} />
        </Dropdown>
      </div>;
      return record;
    });
    for (let file_id in uploading_files)
    {
      let {ts, file: native_file} = uploading_files[file_id];
      if (str.mongo2path(file_id).split('/').slice(0, -1).join('/') != path)
        continue;
      let record = {file_id: file_id+rand.uuid(), status: '—', cmts: 0,
        date_uploaded: '—', size: bytes_format(native_file.size)};
      record.uploader = str.mongo2email(user_full.id).split('@')[0];
      record.file = {id: file_id, user_id: user_full.id, is_dir: false,
        cmts: {}, is_upload: true};
      let ext = str.mongo2path(record.file.id).split('.').pop();
      let progress = uploading_progress[native_file.name];
      let elapsed_time_sec = (Date.now() - ts.getTime()) / xdate.MS_SEC;
      let status;
      if (progress == 1 || elapsed_time_sec == 0)
        status = t('Uploading');
      else
      {
        let uploaded_size = native_file.size * progress;
        let conn_speed_bytes_sec = uploaded_size / elapsed_time_sec;
        let conn_speed_mb_sec = conn_speed_bytes_sec / 1024 / 1024;
        let ramaining_size = native_file.size - uploaded_size;
        let remaining_time_sec = ramaining_size / conn_speed_bytes_sec;
        status = t('Uploading') + ' ・ ' + Math.round(conn_speed_mb_sec)
          + ' Mb/s ・ ' + Math.round(remaining_time_sec) + ' '
          + t('seconds left');
      }
      record.name = <File_name filename={native_file.name}
        progress={progress} status={status} />;
      record.action = <div onClick={e=>e.stopPropagation()}
        style={{display: 'flex', justifyContent: 'flex-end'}}>
        {ext == 'aaf' && <Tooltip title={t('Open In Editor')}
          placement="bottom">
          <Button type="text" shape="circle" icon={<EditOutlined />} disabled
            onClick={()=>open_aaf_in_editor(record.file)} />
        </Tooltip>}
        {ext == 'aaf' && <Tooltip title={t('Open In Highlighter')}
          placement="bottom">
          <Button type="text" shape="circle" icon={<HighlightOutlined />}
            onClick={()=>open_aaf_in_highlighter(record.file)} disabled />
        </Tooltip>}
        <Dropdown menu={{items: media_inner_dropdown_items_get(record.file),
          onClick: media_inner_dropdown_click_handle}}
        onOpenChange={()=>ctx_file_set(record.file)} placement="bottomRight"
        trigger={['click']}>
          <Button type="text" shape="circle" icon={<MoreOutlined />} />
        </Dropdown>
      </div>;
      _data_src.unshift(record);
    }
    return _data_src;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, media_inner_dropdown_click_handle, media_inner_dropdown_items_get,
    path, t, uploading_files, uploading_progress, time]);
  let links_inner_dropdown_items = useMemo(()=>{
    return [
      {key: 'settings', label: t('Settings'), icon: <SettingOutlined />},
      {key: 'edit_files', label: t('Add or remove files'),
        icon: <EditOutlined />},
      {key: 'cp_link', label: t('Copy link'), icon: <CopyOutlined />},
      {key: 'duplicate', label: t('Duplicate'), icon: <DiffOutlined />,
        disabled: true},
      {key: 'delete', label: t('Delete'), icon: <DeleteOutlined />},
    ];
  }, [t]);
  let switch_change_handle = useCallback(is_checked=>eserf(function*
  _switch_change_handle(){
    let resps = yield this.wait_ret(selected_proj_link_ids.map(proj_link_id=>{
      return back_app.proj_link.set(token, proj.id, proj_link_id,
        'is_active', is_checked);
    }));
    let err_res = resps.find(res=>res.err);
    if (err_res?.err == 'forbidden')
      return message_api.error(t('Access denied'));
    if (err_res)
    {
      message_api.error(t('Something went wrong'));
      return metric.error('proj_link_set_err', err_res.err);
    }
    proj_get();
  }), [message_api, proj, proj_get, selected_proj_link_ids, t, token]);
  let links_data_src = useMemo(()=>{
    if (!proj?.proj_links)
      return [];
    return Object.values(proj.proj_links).map(proj_link=>{
      let record = {id: proj_link.id, lbl: proj_link.lbl,
        visit: proj_link.visit, proj_link};
      record.creator = str.mongo2email(proj_link.user_id).split('@')[0];
      record.date_created = xdate.date_format(proj_link.ts.insert,
        'MMM DD, YYYY');
      record.active = <Switch checked={proj_link.is_active}
        onChange={switch_change_handle} />;
      let open_change_handle = is_open=>{
        if (is_open)
          ctx_proj_link_set(proj_link);
        else
        {
          is_links_inner_ctx_open_set(false);
          ctx_proj_link_set(null);
        }
      };
      let proj_link_url = proj_link2url(proj.id, proj_link.id);
      record.action = <div onClick={e=>e.stopPropagation()}
        style={{display: 'flex', justifyContent: 'flex-end'}}>
        <Button type="text" shape="circle" icon={<CopyOutlined />}
          onClick={()=>proj_link_url_copy(proj_link_url)} />
        <Dropdown menu={{items: links_inner_dropdown_items,
          onClick: links_inner_dropdown_click_handle}}
        onOpenChange={open_change_handle}
        placement="bottomRight" trigger={['click']}>
          <Button type="text" shape="circle" icon={<MoreOutlined />} />
        </Dropdown>
      </div>;
      return record;
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [links_inner_dropdown_click_handle, links_inner_dropdown_items, time,
    proj_link_url_copy, t]);
  let file_open = useCallback(file=>{
    if (file.is_upload || file.is_tcode_run)
      return;
    if (str.mongo2path(file.id).endsWith('.aaf'))
      return open_aaf_in_highlighter(file);
    on_query_change('');
    on_path_change(str.mongo2path(file.id), file.is_dir);
  }, [on_path_change, on_query_change, open_aaf_in_highlighter]);
  let media_row_handle = useCallback(record=>{
    return {
      onMouseDown: e=>{
        e.stopPropagation();
        if (is_mobile)
          return;
        let _selected_file_ids;
        if (is_show_share_checkboxes && record.file.is_dir)
          _selected_file_ids = selected_file_ids;
        else if (is_show_share_checkboxes
          && selected_file_ids.includes(record.file.id))
        {
          _selected_file_ids = selected_file_ids.filter(id=>{
            return id != record.file.id;
          });
        }
        else if (is_show_share_checkboxes
          && !selected_file_ids.includes(record.file.id))
        {
          _selected_file_ids = [...selected_file_ids, record.file.id];
        }
        else if (e.shiftKey && !last_selected_file)
          _selected_file_ids = [record.file.id];
        else if (e.shiftKey && last_selected_file)
        {
          let idx = media_sorted_data_src.findIndex(r=>{
            return r.file.id == record.file.id;
          });
          let start_idx = Math.min(idx, media_sorted_data_src.findIndex(r=>{
            return r.file.id == last_selected_file;
          }));
          let end_idx = Math.max(idx, media_sorted_data_src.findIndex(r=>{
            return r.file.id == last_selected_file;
          }));
          let part_selected_files = media_sorted_data_src
            .slice(start_idx, end_idx + 1)
            .map(r=>r.file.id);
          _selected_file_ids = [...selected_file_ids, ...part_selected_files];
          _selected_file_ids = [...new Set(_selected_file_ids)];
        }
        else if ((e.ctrlKey || e.metaKey)
          && selected_file_ids.includes(record.file.id))
        {
          _selected_file_ids = selected_file_ids.filter(id=>{
            return id != record.file.id;
          });
        }
        else if ((e.ctrlKey || e.metaKey)
          && !selected_file_ids.includes(record.file.id))
        {
          _selected_file_ids = [...selected_file_ids, record.file.id];
        }
        else if (selected_file_ids.includes(record.file.id))
          _selected_file_ids = [...selected_file_ids];
        else
          _selected_file_ids = [record.file.id];
        selected_file_ids_set(_selected_file_ids);
        last_selected_file_set(record.file.id);
      },
      onClick: ()=>{
        if (!is_mobile)
          return;
        file_open(record.file);
      },
      onDoubleClick: ()=>{
        file_open(record.file);
      },
      onContextMenu: ()=>{
        ctx_file_set(record.file);
        is_media_inner_ctx_open_set(true);
      },
    };
  }, [selected_file_ids, file_open, media_sorted_data_src, last_selected_file,
    is_mobile, is_show_share_checkboxes]);
  let links_row_handle = useCallback(record=>{
    return {
      onMouseDown: e=>{
        e.stopPropagation();
        let _selected_proj_link_ids;
        if (e.shiftKey && !last_selected_proj_link)
          _selected_proj_link_ids = [record.proj_link.id];
        else if (e.shiftKey && last_selected_proj_link)
        {
          let idx = links_sorted_data_src.findIndex(r=>{
            return r.proj_link.id == record.proj_link.id;
          });
          let start_idx = Math.min(idx, links_sorted_data_src.findIndex(r=>{
            return r.proj_link.id == last_selected_proj_link;
          }));
          let end_idx = Math.max(idx, links_sorted_data_src.findIndex(r=>{
            return r.proj_link.id == last_selected_proj_link;
          }));
          let part_selected_proj_links = links_sorted_data_src
            .slice(start_idx, end_idx + 1)
            .map(r=>r.proj_link.id);
          _selected_proj_link_ids = [...selected_proj_link_ids,
            ...part_selected_proj_links];
          _selected_proj_link_ids = [...new Set(_selected_proj_link_ids)];
        }
        else if ((e.ctrlKey || e.metaKey)
          && selected_proj_link_ids.includes(record.proj_link.id))
        {
          _selected_proj_link_ids = selected_proj_link_ids.filter(id=>{
            return id != record.proj_link.id;
          });
        }
        else if ((e.ctrlKey || e.metaKey)
          && !selected_proj_link_ids.includes(record.proj_link.id))
        {
          _selected_proj_link_ids = [...selected_proj_link_ids,
            record.proj_link.id];
        }
        else if (selected_proj_link_ids.includes(record.proj_link.id))
          _selected_proj_link_ids = [...selected_proj_link_ids];
        else
          _selected_proj_link_ids = [record.proj_link.id];
        selected_proj_link_ids_set(_selected_proj_link_ids);
        last_selected_proj_link_set(record.proj_link.id);
      },
      onDoubleClick: ()=>{
        if (!proj)
          assert(0, 'proj not found');
        window.open(proj_link2url(proj.id, record.proj_link.id), '_blank');
      },
      onContextMenu: ()=>{
        is_links_inner_ctx_open_set(true);
        ctx_proj_link_set(record.proj_link);
      },
    };
  }, [last_selected_proj_link, proj, selected_proj_link_ids,
    links_sorted_data_src]);
  use_effect_eserf(()=>eserf(function* use_effect_update_time(){
    while (1)
    {
      time_set(Date.now());
      yield eserf.sleep(1000);
    }
  }), []);
  let media_open_change_handle = useCallback(is_open=>{
    if (is_open)
      return;
    is_media_inner_ctx_open_set(false);
    ctx_file_set(null);
  }, []);
  let links_open_change_handle = useCallback(is_open=>{
    if (is_open)
      return;
    is_links_inner_ctx_open_set(false);
    ctx_proj_link_set(null);
  }, []);
  let tcode_wait = useCallback(file_id=>eserf(function* _tcode_wait(){
    let resp = yield back_app.file.tcode_wait(token, file_id);
    if (resp.err)
    {
      message_api.error(t('Something went wrong'));
      return metric.error('file_get_tcode_err', resp.err);
    }
    return yield proj_get();
  }), [proj_get, message_api, t, token]);
  let tcode_files = useRef({});
  useEffect(()=>{
    for (let filename in cur_files)
    {
      let file = cur_files[filename];
      // XXX: check if this file is already being handled
      if (!file.is_tcode || !file.is_tcode_run || tcode_files.current[file.id])
        continue;
      tcode_files.current[file.id] = file;
      es_root.spawn(tcode_wait(file.id));
    }
  }, [cur_files, es_root, proj_get, tcode_wait]);
  useEffect(()=>{
    last_selected_file_set(null);
    if (!is_show_share_checkboxes)
      selected_file_ids_set([]);
  }, [is_show_share_checkboxes, path]);
  let drag_start_handle = useCallback(()=>{
    is_dragging_set(true);
  }, []);
  let drag_end_handle = useCallback(e=>eserf(function* _drag_end_handle(){
    is_dragging_set(false);
    let {over} = e;
    // no drop target or files were dropped on one of the selected files
    if (!over || selected_file_ids.some(file_id=>file_id == over.id))
      return;
    let resps = yield this.wait_ret(selected_file_ids.map(file_id=>{
      let filename = file_id2filename(file_id);
      let target_file_id = over.id + '/' + str.path2mongo(filename);
      if (file_id == target_file_id)
        return {};
      return back_app.file.mv(token, file_id, target_file_id);
    }));
    let err_res = resps.find(res=>res.err);
    if (err_res?.err == 'already_exist')
      return message_api.error(t('File already exists'));
    if (err_res?.err)
    {
      message_api.error(t('Something went wrong'));
      return metric.error('file_drag_mv_err', err_res.err);
    }
    proj_get();
  }), [proj_get, message_api, selected_file_ids, t, token]);
  let share_btn_click_handle = useCallback(e=>{
    if (selected_files.length)
    {
      selected_file_ids_set([]);
      selected_to_share_files_set(selected_files);
      is_share_modal_open_set(true);
      return;
    }
    is_show_share_checkboxes_set(true);
    active_tab_set('media');
  }, [selected_files]);
  let create_link_btn_click_handle = useCallback(()=>{
    if (!selected_files.length)
      return;
    selected_file_ids_set([]);
    selected_to_share_files_set(selected_files);
    is_share_modal_open_set(true);
    is_show_share_checkboxes_set(false);
  }, [selected_files]);
  let save_link_btn_click_handle = useCallback(()=>{
    if (!selected_files.length)
      return;
    selected_file_ids_set([]);
    selected_to_share_files_set(selected_files);
    is_edit_proj_link_modal_open_set(true);
    is_show_share_checkboxes_set(false);
  }, [selected_files]);
  let cancel_sharing_btn_click_handle = useCallback(()=>{
    selected_file_ids_set([]);
    selected_to_share_files_set([]);
    is_show_share_checkboxes_set(false);
  }, []);
  let row_selection_select_handle = useCallback((record, is_selected)=>{
    let _selected_file_ids;
    if (is_selected)
      _selected_file_ids = [...selected_file_ids, record.file.id];
    else
    {
      _selected_file_ids = selected_file_ids.filter(id=>{
        return id != record.file.id;
      });
    }
    selected_file_ids_set(_selected_file_ids);
  }, [selected_file_ids]);
  let row_selection_checkbox_props_get = useCallback(record=>{
    let is_disabled = selected_file_ids.some(file_id=>{
      return record.file.id.startsWith(file_id) && record.file.id != file_id;
    });
    return {onMouseDown: mouse_down_propagation_stop, disabled: is_disabled};
  }, [mouse_down_propagation_stop, selected_file_ids]);
  let media_row_selection = useMemo(()=>{
    if (!is_show_share_checkboxes)
      return null;
    let selected_row_keys = [...selected_file_ids];
    media_data_src.forEach(record=>{
      let is_disabled = selected_file_ids.some(file_id=>{
        return record.file.id.startsWith(file_id) && record.file.id != file_id;
      });
      if (is_disabled)
        selected_row_keys.push(record.file.id);
    });
    return {selectedRowKeys: selected_row_keys, hideSelectAll: true,
      onSelect: row_selection_select_handle,
      getCheckboxProps: row_selection_checkbox_props_get};
  }, [is_show_share_checkboxes, row_selection_select_handle, media_data_src,
    selected_file_ids, row_selection_checkbox_props_get]);
  let layout_mouse_down_handle = useCallback(()=>{
    if (active_tab == 'media')
    {
      let _selected_file_ids = selected_file_ids.filter(file_id=>{
        return !media_data_src.some(record=>record.file.id == file_id);
      });
      selected_file_ids_set(_selected_file_ids);
      last_selected_file_set(null);
    }
    else if (active_tab == 'links')
    {
      selected_proj_link_ids_set([]);
      last_selected_proj_link_set(null);
    }
  }, [active_tab, media_data_src, selected_file_ids]);
  let media_table_change_handle = useCallback((pagination, filters, sorter,
    extra)=>{
    media_sorter_set(sorter);
    media_sorted_data_src_set(extra.currentDataSource);
  }, []);
  useEffect(()=>{
    if (media_sorter.field)
    {
      let sorted = [...media_data_src].sort((a, b)=>{
        if (media_sorter.order === 'ascend')
          return media_sorter.column.sorter(a, b);
        else if (media_sorter.order === 'descend')
          return media_sorter.column.sorter(b, a);
        return 0;
      });
      media_sorted_data_src_set(sorted);
      return;
    }
    media_sorted_data_src_set(media_data_src);
  }, [media_sorter, media_data_src]);
  let links_table_change_handle = useCallback((pagination, filters, sorter,
    extra)=>{
    links_sorter_set(sorter);
    links_sorted_data_src_set(extra.currentDataSource);
  }, []);
  useEffect(()=>{
    if (links_sorter.field)
    {
      let sorted = [...links_data_src].sort((a, b)=>{
        if (links_sorter.order === 'ascend')
          return links_sorter.column.sorter(a, b);
        else if (links_sorter.order === 'descend')
          return links_sorter.column.sorter(b, a);
        return 0;
      });
      links_sorted_data_src_set(sorted);
      return;
    }
    links_sorted_data_src_set(links_data_src);
  }, [links_sorter, links_data_src]);
  let tab_items = useMemo(()=>{
    return [
      {
        key: 'media',
        label: 'Media',
        disabled: !proj,
        children: <div onContextMenu={e=>e.stopPropagation()}
          onMouseDown={e=>e.stopPropagation()}>
          <Dropdown menu={{items: media_inner_dropdown_items_get(ctx_file),
            onClick: media_inner_dropdown_click_handle}}
          trigger={['contextMenu']} open={is_media_inner_ctx_open}
          onOpenChange={media_open_change_handle}>
            <div {...root_props_get({style: {position: 'relative'}})}>
              <Files_list_row_ctx.Provider value={{data_src: media_data_src}}>
                <Table columns={media_cols} dataSource={media_data_src}
                  size="middle" onRow={media_row_handle} pagination={false}
                  rowKey="file_id" rowClassName={media_row_class_name_handle}
                  showHeader={screens.md} rowSelection={media_row_selection}
                  components={{body: {row: Files_list_row}}}
                  onChange={media_table_change_handle} />
              </Files_list_row_ctx.Provider>
              <DragOverlay style={{width: '100px',
                height: '100px'}} modifiers={[snap_center_to_cursor]}>
                {is_dragging && <div style={{width: '100px', height: '100px',
                  position: 'relative'}}>
                  <div style={{width: '100px', height: '50px', left: '50%',
                    top: '50%', position: 'absolute', pointerEvents: 'none',
                    background: color_bg_container, display: 'flex',
                    alignItems: 'center', justifyContent: 'center',
                    borderRadius: border_radius_lg, boxShadow: box_shadow}}>
                    <FileFilled />
                    <div style={{position: 'absolute', top: 0, right: 0,
                      background: purple.primary, borderRadius: '50%',
                      transform: 'translate(50%, -50%)', width: '24px',
                      height: '24px', display: 'flex', justifyContent: 'center',
                      alignItems: 'center', boxShadow: box_shadow}}>
                      {selected_file_ids.length}
                    </div>
                  </div>
                </div>}
              </DragOverlay>
              {is_files_drag_active && <div style={{position: 'absolute',
                width: '100%', height: '100%', top: 0, left: 0,
                border: `2px solid ${purple.primary}`,
                borderRadius: border_radius_lg,
                background: `${purple.primary}50`}} />}
            </div>
          </Dropdown>
        </div>,
      },
      {
        key: 'links',
        label: 'Links',
        disabled: is_show_share_checkboxes || !proj,
        children: <div onContextMenu={e=>e.stopPropagation()}
          onMouseDown={e=>e.stopPropagation()}>
          <Dropdown menu={{items: links_inner_dropdown_items,
            onClick: links_inner_dropdown_click_handle}}
          trigger={['contextMenu']} open={is_links_inner_ctx_open}
          onOpenChange={links_open_change_handle}>
            <div>
              <Table columns={links_cols} dataSource={links_data_src}
                size="middle" pagination={false} rowKey="id"
                rowClassName={links_row_class_name_handle}
                showHeader={screens.md} onRow={links_row_handle}
                onChange={links_table_change_handle} />
            </div>
          </Dropdown>
        </div>,
      },
    ];
  }, [media_inner_dropdown_items_get, ctx_file, is_dragging, color_bg_container,
    media_inner_dropdown_click_handle, is_media_inner_ctx_open, box_shadow,
    media_open_change_handle, root_props_get, media_data_src, media_cols,
    media_row_handle, media_row_class_name_handle, screens.md, border_radius_lg,
    media_row_selection, is_files_drag_active, links_inner_dropdown_items,
    links_inner_dropdown_click_handle, is_links_inner_ctx_open, proj,
    links_open_change_handle, links_cols, links_data_src, links_row_handle,
    selected_file_ids, is_show_share_checkboxes, links_row_class_name_handle,
    links_table_change_handle, media_table_change_handle]);
  let tab_change_handle = useCallback(key=>{
    if (!proj)
      assert(0, 'proj not found');
    active_tab_set(key);
    if (key != 'media')
      on_path_change(proj.id, true);
  }, [on_path_change, proj]);
  let proj_links_arr = useMemo(()=>{
    if (!proj?.proj_links)
      return [];
    return Object.values(proj.proj_links);
  }, [proj]);
  return (
    <>
      <Add_dir_modal is_open={is_new_folder_modal_open} token={token}
        path={path} on_close={add_dir_modal_close_handle}
        proj_get={proj_get} />
      <Rename_file_modal is_open={is_rename_file_modal_open} token={token}
        file_id={rename_file?.id} is_dir={!!ctx_file?.is_dir}
        on_close={rename_file_modal_close_handle} proj_get={proj_get} />
      <Mv_cp_modal is_open={is_mv_cp_modal_open} proj={proj}
        selected_files={selected_files} on_close={move_file_modal_close_handle}
        token={token} proj_get={proj_get} files={files}
        cmd={mv_cp_modal_cmd} />
      <Share_modal is_open={is_share_modal_open} token={token}
        on_close={share_files_modal_close_handle} proj_id={proj?.id}
        share_files={selected_to_share_files} proj_get={proj_get}
        on_editing_proj_link_change={editing_proj_link_show}
        proj_links={proj_links_arr} />
      <Edit_proj_link_modal is_open={is_edit_proj_link_modal_open}
        on_close={edit_proj_link_modal_close_handle} token={token}
        proj_link={editing_proj_link} proj_get={proj_get}
        proj_id={proj?.id} share_files={selected_to_share_files} />
      <Layout style={{padding: is_mobile ? '10px' : '24px'}}
        onMouseDown={layout_mouse_down_handle}>
        {message_ctx_holder}
        {modal_ctx_holder}
        <DndContext sensors={sensors} onDragStart={drag_start_handle}
          onDragEnd={drag_end_handle} collisionDetection={pointer_within}>
          <Dropdown menu={{items: outer_dropdown_items,
            onClick: outer_dropdown_click_handle}} trigger={['contextMenu']}
          disabled={!proj}>
            <Layout.Content style={{padding: 24, margin: 0, minHeight: 280,
              background: color_bg_container, borderRadius: border_radius_lg,
              color: 'white'}}>
              <div style={{display: 'flex', justifyContent: 'space-between',
                alignItems: 'center'}}>
                <Breadcrumb items={breadcrumb_items}
                  separator={<RightOutlined />} />
                <Space>
                  {is_show_share_checkboxes && <Typography>
                    {selected_file_ids.length} {t('items selected')}
                  </Typography>}
                  {is_show_share_checkboxes && <Clickable>
                    <Button onClick={cancel_sharing_btn_click_handle}
                      onMouseDown={mouse_down_propagation_stop}>
                      {t('Cancel')}
                    </Button>
                  </Clickable>}
                  {is_show_share_checkboxes && !editing_proj_link && <Clickable>
                    <Button onClick={create_link_btn_click_handle}
                      disabled={!selected_file_ids.length}
                      onMouseDown={mouse_down_propagation_stop}>
                      {t('Create link')}
                    </Button>
                  </Clickable>}
                  {is_show_share_checkboxes && editing_proj_link && <Clickable>
                    <Button onClick={save_link_btn_click_handle}
                      disabled={!selected_file_ids.length}
                      onMouseDown={mouse_down_propagation_stop}>
                      {t('Save')}
                    </Button>
                  </Clickable>}
                  {!is_show_share_checkboxes && <Clickable>
                    <Button onClick={share_btn_click_handle}
                      onMouseDown={mouse_down_propagation_stop}
                      type={is_mobile ? 'text' : 'default'}>
                      {is_mobile ? <ShareAltOutlined /> : t('Share')}
                    </Button>
                  </Clickable>}
                  <Clickable>
                    <Dropdown menu={{items: new_btn_items,
                      onClick: new_btn_click_handle}} placement="bottomRight"
                    trigger={['click']}>
                      <Button loading={!!Object.keys(uploading_files).length}
                        onMouseDown={mouse_down_propagation_stop}
                        type={is_mobile ? 'text' : 'primary'}>
                        {is_mobile ? <PlusOutlined /> : <Space>
                          {t('New')}
                          <DownOutlined />
                        </Space>}
                      </Button>
                    </Dropdown>
                  </Clickable>
                </Space>
              </div>
              <Tabs items={tab_items} activeKey={active_tab}
                onChange={tab_change_handle} />
            </Layout.Content>
          </Dropdown>
        </DndContext>
      </Layout>
    </>
  );
});
let image_mime_types = ['image/jpeg', 'image/png', 'image/gif', 'image/svg+xml',
  'image/webp', 'image/apng', 'image/tiff'];
let Image_preview = React.memo(({file})=>{
  return <Image src={file.src.pub_url} preview={false} />;
});
let audio_mime_types = ['audio/mpeg', 'audio/webm', 'audio/ogg', 'audio/wav',
  'audio/flac', 'audio/aac'];
let Audio_preview = React.memo(({file})=>{
  let player_ref = useRef(null);
  let container_ref = useRef(null);
  let [aspect_ratio, aspect_ratio_set] = useState(1);
  let [player_width, player_width_set] = useState(0);
  let [player_height, player_height_set] = useState(0);
  let ready_handle = useCallback(()=>{
    if (!player_ref.current)
      return;
    let _player = player_ref.current;
    let video = _player.getInternalPlayer();
    aspect_ratio_set(video.videoWidth / video.videoHeight);
  }, []);
  let resize_handle = useCallback(()=>{
    let container = container_ref.current;
    let container_rect = container.getBoundingClientRect();
    let container_width = container_rect.width;
    let container_height = container_rect.height;
    let _player_width = Math.min(container_height * aspect_ratio,
      container_width);
    let _player_height = _player_width / aspect_ratio;
    player_width_set(_player_width);
    player_height_set(_player_height);
  }, [aspect_ratio]);
  useEffect(()=>{
    resize_handle();
    window.addEventListener('resize', resize_handle);
    return ()=>window.removeEventListener('resize', resize_handle);
  }, [resize_handle]);
  return (
    <div ref={container_ref} style={{width: '100%', height: '100%',
      display: 'flex', justifyContent: 'center', alignItems: 'center',
      background: `center no-repeat url(${audio_icon})`}} >
      <audio ref={player_ref} src={file.src.pub_url}
        style={{width: player_width, height: player_height}}
        controls onReady={ready_handle} />
    </div>
  );
});
let coords_get = elem=>{
  let box = elem.getBoundingClientRect();
  return {top: box.top + window.scrollY, right: box.right + window.scrollX,
    bottom: box.bottom + window.scrollY, left: box.left + window.scrollX};
};
// XXX vladimir: move to player.js
let Frame_ptr = React.memo(({left, style={}})=>{
  return (
    <div
      style={{height: '100%', width: '1px', background: purple.primary,
        position: 'absolute', left: `${left}px`, pointerEvents: 'none',
        top: '0px', zIndex: 102, ...style}}
    />
  );
});
// XXX vladimir: move to player.js
let Scrub_bar = React.memo(({len, fps, frame, on_frame_change})=>{
  let [container_width, container_width_set] = useState(0);
  let [ptr_moving, ptr_moving_set] = useState(false);
  let [ticks_gap, ticks_gap_set] = useState();
  let container_ref = useRef(null);
  let mouse_move_handle = useCallback(e=>{
    let container = container_ref.current;
    let container_offset_x = coords_get(container).left;
    let offset_x = e.type.includes('mouse')
      ? e.offsetX
      : e.touches[0].clientX;
    let frame_rel = (offset_x - container_offset_x) / container.offsetWidth;
    let _frame = len * frame_rel;
    on_frame_change(_frame);
  }, [on_frame_change, len]);
  let mouse_down_handle = useCallback(e=>{
    mouse_move_handle(e.nativeEvent);
    ptr_moving_set(true);
  }, [mouse_move_handle]);
  let mouse_up_handle = useCallback(()=>ptr_moving_set(false), []);
  let resize_handle = useCallback(()=>{
    let _container_width = container_ref.current.offsetWidth;
    container_width_set(_container_width);
    let min_gap_px = 30;
    let t = len / fps;
    let min_gap_s = t * min_gap_px / _container_width;
    let periods_s = [0.04, 0.2, 1, 2, 5, 10, 15]; // 15 * 2 ^ n
    let min_period = periods_s.at(0);
    if (min_gap_s < periods_s.at(min_period))
    {
      let _ticks_gap = min_gap_s * _container_width / t;
      ticks_gap_set(_ticks_gap);
      return;
    }
    let sec_gap = periods_s.find((period, index)=>{
      return min_gap_s >= periods_s[index - 1] && min_gap_s <= period;
    });
    if (sec_gap)
    {
      let _ticks_gap = sec_gap * _container_width / t;
      ticks_gap_set(_ticks_gap);
      return;
    }
    let base_period = periods_s.at(-1);
    let power = Math.floor(Math.log2(2 * min_gap_s / base_period));
    sec_gap = base_period * 2 ** power;
    let _ticks_gap = sec_gap * _container_width / t;
    ticks_gap_set(_ticks_gap);
  }, [fps, len]);
  useEffect(()=>{
    let container = container_ref.current;
    let resize_observer = new ResizeObserver(resize_handle);
    resize_observer.observe(container);
    return ()=>resize_observer.unobserve(container);
  }, [resize_handle]);
  useEffect(()=>{
    if (!ptr_moving)
      return;
    document.addEventListener('mousemove', mouse_move_handle);
    document.addEventListener('mouseup', mouse_up_handle);
    document.addEventListener('touchmove', mouse_move_handle);
    document.addEventListener('touchend', mouse_up_handle);
    return ()=>{
      document.removeEventListener('mousemove', mouse_move_handle);
      document.removeEventListener('mouseup', mouse_up_handle);
      document.removeEventListener('touchmove', mouse_move_handle);
      document.removeEventListener('touchend', mouse_up_handle);
    };
  }, [mouse_move_handle, mouse_up_handle, ptr_moving]);
  let f2px_k = useMemo(()=>len / container_width,
    [container_width, len]);
  let ticks = useMemo(()=>{
    if (!ticks_gap)
      return [];
    let _ticks = [];
    for (let left = 0; left <= container_width; left += ticks_gap)
    {
      _ticks.push(<div key={left} style={{position: 'absolute',
        left: `${left}px`, transform: 'translateX(-50%)', bottom: 0,
        height: '8px', width: '1px', background: gray[9]}} />);
    }
    return _ticks;
  }, [container_width, ticks_gap]);
  let left = useMemo(()=>{
    return player.f2px(frame, f2px_k, 0);
  }, [f2px_k, frame]);
  return (
    <div ref={container_ref} style={{height: '16px', position: 'relative',
      background: gray[3], zIndex: 0, cursor: 'pointer',
      borderBottom: `1px solid ${gray[9]}`}} onMouseDown={mouse_down_handle}
    onTouchStart={mouse_down_handle}>
      <div style={{position: 'absolute',
        overflow: 'hidden', width: '100%', height: '100%'}}>
        {ticks}
      </div>
      <Frame_ptr left={left} style={{background: purple.primary}} />
    </div>
  );
});
let Video_preview = React.memo(({file, frame, on_frame_change})=>{
  let {t} = useTranslation();
  let container_ref = useRef(null);
  let player_container_ref = useRef(null);
  let {token: {colorBgContainer}} = theme.useToken();
  let [playback_rate, playback_rate_set] = useState(0);
  let [video_el, video_el_set] = useState(null);
  let [rec_tc, rec_tc_set] = useState('time');
  let [can_play, can_play_set] = useState(false);
  let [player_el, player_el_set] = useState(false);
  let fps = useMemo(()=>{
    if (!file?.editrate)
      assert(0, 'no editrate');
    let _fps = editrate2fps(file.editrate);
    if (_fps === null)
      assert(0, 'fps not found');
    return _fps;
  }, [file.editrate]);
  let dur = useMemo(()=>{
    if (!video_el)
      return null;
    return video_el.duration;
  }, [video_el]);
  let len = useMemo(()=>{
    if (!fps)
      return 0;
    return Math.floor(player.sec2f(dur, fps));
  }, [dur, fps]);
  let resolution = useMemo(()=>{
    if (!video_el)
      return {w: 1920, h: 1080};
    return {w: video_el.videoWidth, h: video_el.videoHeight};
  }, [video_el]);
  let aspect_ratio = useMemo(()=>{
    return 16 / 9;
  }, []);
  let video_render_segs = useMemo(()=>{
    if (!file)
      assert(0, 'Cannot get render segment, file not found');
    return [{start: 0, len, video_start: 0, src: file.src.pub_url,
      is_offline: false, video_len: len, playback_rate: 1,
      split_render_segs: []}];
  }, [file, len]);
  let audio_render_segs = useMemo(()=>{
    return [];
  }, []);
  let cmt_click_handle = useCallback(_frame=>{
    if (_frame === undefined)
      return;
    on_frame_change(_frame);
  }, [on_frame_change]);
  let playing_ref = useRef(null);
  useEffect(()=>{
    if (!len || !playback_rate)
    {
      playing_ref.current = null;
      return;
    }
    if (!playing_ref.current)
    {
      playing_ref.current = {};
      playing_ref.current.start_tc = performance.now();
      playing_ref.current.start_frame = frame;
      playing_ref.current.playback_rate = playback_rate;
    }
    if (playback_rate != playing_ref.current.playback_rate)
    {
      playing_ref.current.start_tc = performance.now();
      playing_ref.current.start_frame = frame;
      playing_ref.current.playback_rate = playback_rate;
    }
    let cancelled = false;
    let step = ()=>{
      if (!playing_ref.current)
        return;
      let ms = (performance.now() - playing_ref.current.start_tc)
        * playback_rate;
      let sec = ms / xdate.MS_SEC;
      let _frame = playing_ref.current.start_frame + Math.floor(sec * fps);
      _frame = xutil.clamp(_frame, 0, len - 1);
      on_frame_change(_frame);
      if (playback_rate < 0 &&_frame <= 0 || _frame == len - 1)
        return playback_rate_set(0);
      if (!cancelled)
        requestAnimationFrame(step);
    };
    requestAnimationFrame(step);
    return ()=>cancelled = true;
  }, [fps, frame, on_frame_change, len, playback_rate]);
  let frame_change_handle = useCallback(_frame=>{
    _frame = Math.floor(_frame);
    _frame = xutil.clamp(_frame, 0, len - 1);
    on_frame_change(_frame);
    if (playing_ref.current)
    {
      playing_ref.current.start_tc = performance.now();
      playing_ref.current.start_frame = _frame;
      player_el.currentTime = player.f2sec(_frame, fps);
    }
    if (playback_rate < 0)
      playback_rate_set(0);
  }, [fps, len, on_frame_change, playback_rate, player_el]);
  let move_frame = useCallback(frame_shift=>{
    if (!can_play)
      return;
    let _frame = frame + frame_shift;
    frame_change_handle(_frame);
    playback_rate_set(0);
  }, [can_play, frame, frame_change_handle]);
  let play_stop = useCallback(()=>{
    // do not allow a user to play a video before it is ready,
    // but they can stop the video at any time
    if (!can_play && !playback_rate)
      return;
    playback_rate_set(playback_rate ? 0 : 1);
    if (frame == len - 1 && playback_rate == 0)
      on_frame_change(0);
  }, [can_play, frame, len, on_frame_change, playback_rate]);
  let play = useCallback(()=>{
    if (!can_play)
      return;
    // XXX vladimir: move playback rate progression to comp.js
    let playback_rates = [0, 1, 2, 3, 5, 8];
    if (playback_rate < 0)
      return playback_rate_set(playback_rates[0]);
    let idx = playback_rates.indexOf(playback_rate);
    let _playback_rate = playback_rates[idx + 1];
    if (!_playback_rate)
      _playback_rate = playback_rates.at(-1);
    playback_rate_set(_playback_rate);
    if (frame == len - 1)
      on_frame_change(0);
  }, [can_play, frame, len, on_frame_change, playback_rate]);
  let stop = useCallback(()=>{
    playback_rate_set(0);
  }, []);
  let play_backwards = useCallback(()=>{
    if (!can_play)
      return;
    // XXX vladimir: move playback rate progression to comp.js
    let playback_rates = [-8, -5, -3, -2, -1, 0];
    if (playback_rate > 0)
      return playback_rate_set(playback_rates.at(-2));
    let idx = playback_rates.indexOf(playback_rate);
    let _playback_rate = playback_rates[idx - 1];
    if (!_playback_rate)
      _playback_rate = playback_rates[0];
    playback_rate_set(_playback_rate);
  }, [can_play, playback_rate]);
  let action2func = useMemo(()=>({
    move_one_frame_left: ()=>move_frame(-1),
    move_one_frame_right: ()=>move_frame(1),
    move_ten_frames_left: ()=>move_frame(-10),
    move_ten_frames_right: ()=>move_frame(10),
    play_stop, play_backwards, stop, play,
  }), [move_frame, play, play_backwards, play_stop, stop]);
  useEffect(()=>{
    let unsubscribe = tinykeys(window, key_binding_map_get(action2func));
    return ()=>unsubscribe();
  }, [action2func]);
  useEffect(()=>{
    if (!file?.src?.pub_url)
      return;
    let _video_el = document.createElement('video');
    _video_el.autoplay = false;
    _video_el.loop = false;
    _video_el.onloadedmetadata = ()=>{
      video_el_set(_video_el);
    };
    _video_el.src = file.src.pub_url;
  }, [file.src.pub_url]);
  let tc_items = useMemo(()=>{
    return [
      {key: 'time', label: t('Standard')},
      {key: 'mas', label: t('Timecode')},
      {key: 'frame', label: t('Frames')},
    ];
  }, [t]);
  let cur_tc = useMemo(()=>{
    if (!file?.editrate)
      assert(0, 'Cannot return cur_tc, editrate not found');
    if (rec_tc == 'time')
    {
      let tc_o = tc.frame2tc_o(frame, file.editrate);
      let tc_str = '';
      if (tc_o.h)
        tc_str += `${String(tc_o.h).padStart(2, '0')}:`;
      tc_str += `${String(tc_o.min).padStart(2, '0')}:`;
      tc_str += `${String(tc_o.sec).padStart(2, '0')}`;
      return tc_str;
    }
    if (rec_tc == 'mas')
      return tc.frame2tc(frame, file.editrate);
    if (rec_tc == 'frame')
      return frame;
  }, [file.editrate, frame, rec_tc]);
  let end_tc = useMemo(()=>{
    if (!file?.editrate)
      assert(0, 'Cannot return end_tc, editrate not found');
    if (rec_tc == 'time')
    {
      let tc_o = tc.frame2tc_o(len, file.editrate);
      let tc_str = '';
      if (tc_o.h)
        tc_str += `${String(tc_o.h).padStart(2, '0')}:`;
      tc_str += `${String(tc_o.min).padStart(2, '0')}:`;
      tc_str += `${String(tc_o.sec).padStart(2, '0')}`;
      return tc_str;
    }
    if (rec_tc == 'mas')
      return tc.frame2tc(len, file.editrate);
    if (rec_tc == 'frame')
      return len - 1;
  }, [file.editrate, len, rec_tc]);
  let rec_tc_change_handle = useCallback(opt=>{
    rec_tc_set(opt.key);
  }, []);
  let loading_state_change_handle = useCallback(is_loading=>{
    can_play_set(!is_loading);
  }, []);
  let fullscreen_handle = useCallback(()=>{
    if (document.fullscreenElement && document.exitFullscreen)
      return document.exitFullscreen();
    if (document.mozFullScreenElement && document.mozCancelFullScreen)
      return document.mozCancelFullScreen();
    if (document.webkitFullscreenElement && document.webkitExitFullscreen)
      return document.webkitExitFullscreen();
    if (document.msFullscreenElement && document.msExitFullscreen)
      return document.msExitFullscreen();
    if (!player_container_ref.current)
      return;
    if (player_container_ref.current.requestFullscreen)
      return player_container_ref.current.requestFullscreen();
    if (player_container_ref.current.mozRequestFullScreen)
      return player_container_ref.current.mozRequestFullScreen();
    if (player_container_ref.current.webkitRequestFullscreen)
      return player_container_ref.current.webkitRequestFullscreen();
    if (player_container_ref.current.msRequestFullscreen)
      return player_container_ref.current.msRequestFullscreen();
  }, []);
  let last_touch_ref = useRef(0);
  let touch_start_handle = useCallback(()=>{
    let cur_time = Date.now();
    let tap_delta = cur_time - last_touch_ref.current;
    last_touch_ref.current = cur_time;
    if (tap_delta < 600)
      fullscreen_handle();
  }, [fullscreen_handle]);
  return (
    <div style={{width: '100%', height: '100%', display: 'flex',
      justifyContent: 'center', alignItems: 'center'}} ref={container_ref}>
      <div ref={player_container_ref} style={{display: 'flex',
        flexDirection: 'column', height: '100%', width: '100%',
        justifyContent: 'center', maxWidth: resolution.w}}>
        <div style={{position: 'relative', width: '100%',
          aspectRatio: aspect_ratio}} onClick={play_stop}
        onDoubleClick={fullscreen_handle} onTouchStart={touch_start_handle}>
          <player.Player resolution={resolution} fps={fps} frame={frame}
            video_render_segs={video_render_segs} playback_rate={playback_rate}
            audio_render_segs={audio_render_segs} video_ref={player_el_set}
            on_loading_state_change={loading_state_change_handle} />
        </div>
        <Scrub_bar len={len} fps={fps} frame={frame}
          on_frame_change={frame_change_handle} />
        <div style={{width: '100%', height: '28px', overflow: 'hidden',
          background: colorBgContainer, position: 'relative',
          borderBottom: `1px solid ${gray[9]}`}}>
          {file && Object.values(file.cmts).map(cmt=>{
            if (cmt.frame === undefined)
              return null;
            let percent = (cmt.frame / len * 100).toFixed(2);
            let _tc = cmt.frame !== undefined
              ? tc.frame2tc(cmt.frame, file.editrate) : null;
            let msg = _tc ? _tc + ' ' + cmt.msg : cmt.msg;
            return (
              <Tooltip key={cmt.id} title={msg}>
                <div style={{position: 'absolute', borderRadius: '50%',
                  border: `1px solid ${purple.primary}`, width: '18px',
                  height: '18px', top: '50%', cursor: 'pointer',
                  left: `${percent}%`, transform: 'translate(-50%, -50%)',
                  background: `url(${cmt.avatar})`, backgroundSize: 'cover'}}
                onClick={()=>cmt_click_handle(cmt.frame)} />
              </Tooltip>
            );
          })}
        </div>
        <div style={{display: 'flex', alignItems: 'center',
          height: '40px', justifyContent: 'space-between',
          background: colorBgContainer}}>
          <div>
            <Button type="text" onClick={play_stop}>
              {playback_rate
                ? <PauseOutlined style={{fontSize: '16x'}} />
                : <Icon style={{fontSize: '16px'}} component={Play_icon} />}
            </Button>
          </div>
          <div>
            <Dropdown menu={{items: tc_items, onClick: rec_tc_change_handle}}
              trigger={['click']}>
              <div style={{display: 'flex', alignItems: 'center', gap: '8px',
                color: gray[1], width: '250px', justifyContent: 'center',
                fontSize: '14px', fontFamily: 'monospace', cursor: 'pointer'}}>
                <span style={{color: 'white'}}>{cur_tc}</span>
                <span> / </span>
                <span>{end_tc}</span>
                <DownOutlined />
              </div>
            </Dropdown>
          </div>
          <div>
            <Button type="text" icon={<FullscreenOutlined />}
              onClick={fullscreen_handle} />
          </div>
        </div>
      </div>
    </div>
  );
});
let Cmt = React.memo(({ts, avatar, user, tc: _tc, msg, on_delete, on_select,
  allow_delete})=>{
  let {t} = useTranslation();
  let {token: {colorBgTextHover: color_bg_text_hover,
    colorBgContainer: color_bg_container}} = theme.useToken();
  let [modal_api, modal_ctx_holder] = Modal.useModal();
  let [is_hover, is_hover_set] = useState(false);
  let mouse_enter_handler = useCallback(()=>is_hover_set(true), []);
  let mouse_leave_handler = useCallback(()=>is_hover_set(false), []);
  let delete_handle = useCallback(e=>{
    e.stopPropagation();
    modal_api.confirm({title: t('Delete this comment?'), maskClosable: true,
      content: t('Are you sure you want to delete this comment?'),
      okText: t('Delete'), okButtonProps: {danger: true}, onOk: on_delete});
  }, [modal_api, on_delete, t]);
  let is_delete_btn_visible = useMemo(()=>{
    return allow_delete && is_hover;
  }, [allow_delete, is_hover]);
  let date_created = useMemo(()=>{
    return xdate.date_format(ts.insert, 'hh:mm MMM DD, YYYY');
  }, [ts]);
  return (
    <>
      {modal_ctx_holder}
      <div style={{display: 'flex', flexDirection: 'column', cursor: 'pointer',
        padding: '16px', width: '100%', transition: '0.1s ease',
        background: is_hover ? color_bg_text_hover : color_bg_container,
        gap: '8px', borderBottom: `1px solid ${color_bg_text_hover}`}}
      onMouseEnter={mouse_enter_handler} onMouseLeave={mouse_leave_handler}
      onClick={on_select}>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <Avatar src={avatar} color={purple.primary} style={{width: '24px',
            height: '24px'}} />
          <span style={{fontSize: '14px', color: 'white', marginLeft: '10px'}}>
            {user}
          </span>
          <span style={{fontSize: '14px', color: theme.gray11,
            marginLeft: '10px'}}>
            {date_created}
          </span>
        </div>
        <div style={{fontSize: '14px'}}>
          {_tc && <span style={{color: theme.green, fontWeight: 'bold'}}>
            {_tc}
          </span>}
          <span style={{color: 'white'}}>
            {' '}{msg}
          </span>
        </div>
        <div style={{display: 'flex', height: '24px',
          justifyContent: 'flex-end'}}>
          {is_delete_btn_visible && <div style={{color: 'white', padding: '5px',
            height: '35px'}} onClick={delete_handle}>
            <DeleteOutlined />
          </div>}
        </div>
      </div>
    </>
  );
});
export let cmts_sort = (cmts, sort_method)=>{
  return cmts.sort((a, b)=>{
    switch (sort_method)
    {
    case 'inserted_asc':
      return a.ts.insert - b.ts.insert;
    case 'inserted_desc':
      return b.ts.insert - a.ts.insert;
    case 'tc_asc':
      return a.frame - b.frame;
    case 'user_asc':
      return str.cmp(a.user_id, b.user_id);
    default:
      metric.error('Unexpected sort method: ', sort_method);
      return 0;
    }
  });
};
let File_cmts_tab = React.memo(({file, user_full, on_select, on_delete})=>{
  let {t} = useTranslation();
  let {token: {colorBgTextHover: color_bg_text_hover}} = theme.useToken();
  let [sort_method, sort_method_set] = useState('tc_asc');
  let sort_dropdown_items = useMemo(()=>cmt_sort_methods.map(key=>{
    return {key, label: sort_method2lbl(key)};
  }), []);
  let export_dropdown_items = useMemo(()=>{
    return [
      {key: 'cp', label: t('Copy Comments'), icon: <CopyOutlined />,
        disabled: true},
      {key: 'paste', label: t('Paste Comments'), icon: <SnippetsOutlined />,
        disabled: true},
      {key: 'print', label: t('Print'), icon: <PrinterOutlined />,
        disabled: true},
      {key: 'download', label: t('Download as File...'), children: [
        {key: 'csv', label: t('CSV'), icon: <TableOutlined />,
          disabled: true},
        {key: 'xml', label: t('XML'), icon: <ApartmentOutlined />,
          disabled: true},
        {key: 'txt', label: t('Plain Text'), icon: <FileTextOutlined />},
      ], icon: <FileOutlined />},
      {key: 'premiere', label: t('Download For Premiere'),
        disabled: true, icon: <PlaySquareOutlined />},
      {key: 'media_composer', label: t('Download for Media Composer'),
        icon: <PlaySquareOutlined />},
      {key: 'resolve', label: t('Download for Resolve'),
        icon: <PlaySquareOutlined />},
    ];
  }, [t]);
  let sort_method_change_handle = useCallback(opt=>{
    sort_method_set(opt.key);
  }, []);
  let filename = useMemo(()=>{
    if (!file?.id)
      return null;
    return str.mongo2path(file.id.split('/').pop());
  }, [file?.id]);
  let plain_text_download = useCallback(()=>{
    if (!file?.cmts)
      assert(0, 'Cannot export as plain text, cmts not found');
    let author = file.user_id.split('@')[0];
    let date = xdate.date_format(file.ts.insert, 'hh:mm MMM DD, YYYY');
    let content = `${filename}\n`;
    content += `${author} — ${date}\n\n`;
    Object.values(file.cmts).forEach((cmt, idx)=>{
      let num = idx.toString().padStart(3, '0');
      let cmt_author = cmt.user_id.split('@')[0];
      let cmt_date = xdate.date_format(cmt.ts.insert, 'hh:mm MMM DD, YYYY');
      let cmt_tc = tc.frame2tc(cmt.frame, file.editrate);
      content += `${num} — ${cmt_author} — ${cmt_date}\n`;
      content += `${cmt_tc} — ${cmt.msg}\n\n\n`;
    });
    content = content.trim();
    let blob = new Blob([content], {type: 'text/plain;'});
    download(URL.createObjectURL(blob), `${filename.split('.')[0]}.txt`);
  }, [file?.cmts, file?.ts?.insert, file?.user_id, file?.editrate, filename]);
  let media_composer_download = useCallback(()=>{
    if (!file?.cmts)
      assert(0, 'Cannot export for media composer, cmts not found');
    let content = '';
    Object.values(file.cmts).forEach(cmt=>{
      let cmt_author = cmt.user_id.split('@')[0];
      let cmt_tc = tc.frame2tc(cmt.frame, file.editrate);
      content += `${cmt_author}\t${cmt_tc}\tTC1\tmagenta`;
      content += ` @${cmt_author} ${cmt.msg}\n`;
    });
    content = content.trim();
    let blob = new Blob([content], {type: 'text/plain;'});
    download(URL.createObjectURL(blob), `${filename.split('.')[0]}.txt`);
  }, [file?.cmts, file?.editrate, filename]);
  let resolve_download = useCallback(()=>{
    if (!file?.cmts)
      assert(0, 'Cannot export for davinci resolve, cmts not found');
    let content = `TITLE: ${filename}\n`;
    content += 'FCM: NON DROP FRAME\n\n';
    Object.values(file.cmts).forEach((cmt, idx)=>{
      let num = (idx + 1).toString().padStart(3, '0');
      let cmt_author = cmt.user_id.split('@')[0];
      let cmt_date = xdate.date_format(cmt.ts.insert, 'MMM DD hh:mm');
      let cmt_tc = tc.frame2tc(cmt.frame, file.editrate);
      content += `${num}\t001\tC\tV\t`;
      content += `${cmt_tc}\t${cmt_tc}\t${cmt_tc}\t${cmt_tc}\n`;
      content += `@${cmt_author}, ${cmt_date}\n`;
      content += `${cmt.msg} |C:ResolveColorPurple |M:user_a |D:0\n\n`;
    });
    content = content.trim();
    let blob = new Blob([content], {type: 'text/plain;'});
    download(URL.createObjectURL(blob), `${filename.split('.')[0]}.edl`);
  }, [file?.cmts, file?.editrate, filename]);
  let export_click_handle = useCallback(({key})=>{
    if (key == 'txt')
      return plain_text_download();
    if (key == 'media_composer')
      return media_composer_download();
    if (key == 'resolve')
      return resolve_download();
    assert(0, 'Unknown export type ' + key);
  }, [media_composer_download, plain_text_download, resolve_download]);
  return (
    <div style={{display: 'flex', flexDirection: 'column',
      alignItems: 'center'}}>
      <div style={{width: '100%', padding: '0 16px 16px', display: 'flex',
        borderBottom: `1px solid ${color_bg_text_hover}`,
        justifyContent: 'space-between'}}>
        <Dropdown trigger={['click']} menu={{items: sort_dropdown_items,
          onClick: sort_method_change_handle}}>
          <div style={{color: 'white', cursor: 'pointer'}}>
            <Space>
              <span>{sort_method2lbl(sort_method)}</span>
              <DownOutlined style={{fontSize: '12px'}} />
            </Space>
          </div>
        </Dropdown>
        <Dropdown trigger={['click']} menu={{items: export_dropdown_items,
          onClick: export_click_handle}}>
          <Button type="text" icon={<DownloadOutlined />} disabled={!file} />
        </Dropdown>
      </div>
      {!file || !Object.keys(file.cmts).length
        ? <div style={{padding: '16px'}}>{t('No comments')}</div>
        : cmts_sort(Object.values(file.cmts), sort_method).map(cmt=>{
          let _tc = cmt.frame !== undefined
            ? tc.frame2tc(cmt.frame, file.editrate) : null;
          return (
            <Cmt key={cmt.id} ts={cmt.ts} avatar={cmt.avatar} msg={cmt.msg}
              user={cmt.user_id.split('@')[0]} tc={_tc}
              on_delete={()=>on_delete(cmt)} on_select={()=>on_select(cmt)}
              allow_delete={user_full.id == cmt.user_id} />
          );
        })}
    </div>
  );
});
let img_res_get = src=>eserf(function* _img_res_get(){
  let img = new window.Image();
  img.onload = ()=>{
    this.continue({w: img.width, h: img.height});
  };
  img.onerror = e=>{
    this.continue({err: `Cannot load image from url: ${src}`});
  };
  img.src = src;
  return yield this.wait();
});
let audio_len_get = src=>eserf(function* _img_res_get(){
  let audio = new Audio(src);
  audio.addEventListener('loadedmetadata', ()=>{
    this.continue(audio.duration);
  }, false);
  audio.addEventListener('error', e=>{
    return {err: `Cannot load image from url: ${src}`};
  }, false);
  return yield this.wait();
});
let File_info_tab = React.memo(({file})=>{
  let {t} = useTranslation();
  let {token: {colorBgTextHover: color_bg_text_hover}} = theme.useToken();
  let [info, info_set] = useState([]);
  useEffect(()=>{
    if (!file)
      return info_set([]);
    let es = eserf(function* _use_effect_get_info(){
      let filename = str.mongo2path(file.id.split('/').pop());
      let upload_date = xdate.date_format(file.ts.insert, 'MMM DD, YYYY');
      let _info = [
        {lbl: t('File Name'), content: filename},
        {lbl: t('Uploader'), content: str.mongo2email(file.user_id)},
        {lbl: t('Upload Date'), content: upload_date},
        {lbl: t('Size'), content: bytes_format(file.size)},
      ];
      let mime_type = mime.getType(filename);
      if (audio_mime_types.includes(mime_type))
      {
        let audio_len = yield audio_len_get(file.src.pub_url);
        if (!audio_len.err)
        {
          _info.push({lbl: t('Duration'),
            content: tc.frame2tc(audio_len * 25, {n: 25, d: 1}, true, true)});
        }
      }
      if (image_mime_types.includes(mime_type))
      {
        let img_res = yield img_res_get(file.src.pub_url);
        if (!img_res.err)
          _info.push({lbl: t('RES'), content: `${img_res.w} x ${img_res.h}`});
      }
      info_set(_info);
    });
    return ()=>es.return();
  }, [file, t]);
  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      {info.map(({lbl, content})=><div style={{display: 'flex',
        justifyContent: 'space-between', padding: '8px 0',
        borderBottom: `1px solid ${color_bg_text_hover}`}} key={lbl}>
        <Typography.Text type="secondary">{lbl}</Typography.Text>
        <Typography.Text>{content}</Typography.Text>
      </div>)}
    </div>
  );
});
let Cmt_form = React.memo(({user, token, file, frame, file_get})=>{
  let {t} = useTranslation();
  let {token: {colorBgContainer, borderRadiusLG}} = theme.useToken();
  let [form] = Form.useForm();
  let [message_api, message_ctx_holder] = message.useMessage();
  let [is_loading, is_loading_set] = useState(false);
  let submit_handle = useCallback(()=>eserf(function* _submit_handle(){
    let values = yield this.wait_ext2(form.validateFields());
    if (values.err || !file || !token)
      return;
    is_loading_set(true);
    let res = yield back_app.file_cmt.insert(token, file.id, values.msg,
      is_video(str.mongo2path(file.id)), frame);
    is_loading_set(false);
    if (res.err)
    {
      message_api.error(t('Something went wrong'));
      return metric.error('file_cmt_insert_err', res.err);
    }
    file_get();
    form.resetFields();
  }), [file, file_get, form, frame, message_api, t, token]);
  return <Form form={form} layout="vertical" initialValues={{msg: ''}}
    onFinish={submit_handle} style={{display: 'flex', justifyContent: 'center',
      alignItems: 'center'}} preserve={false}>
    {message_ctx_holder}
    <div style={{background: colorBgContainer, display: 'flex', height: '100%',
      borderRadius: borderRadiusLG, flexDirection: 'column',
      padding: '16px', width: '100%', maxWidth: '800px'}}>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <div style={{width: '24px', height: '24px'}}>
          <Avatar src={user.picture} size={24} />
        </div>
        <Form.Item name="msg" rules={[{required: true}]} noStyle>
          <Input variant="borderless"
            placeholder={t('Leave your comment here...')} />
        </Form.Item>
      </div>
      <div style={{display: 'flex', justifyContent: 'flex-end'}}>
        <Button size="small" type="primary" htmlType="submit"
          style={{fontSize: '14px'}} loading={is_loading}>
          {t('Send')}
        </Button>
      </div>
    </div>
  </Form>;
});
export let File_preview = React.memo(({file_id, inode, user, token, on_return,
  user_full, is_share, proj_link_id, proj_link_passwd})=>{
  let {t} = useTranslation();
  let [message_api, message_ctx_holder] = message.useMessage();
  let {token: {colorBgContainer,
    controlHeight: control_height}} = theme.useToken();
  let {is_mobile} = use_is_mobile();
  let [file, file_set] = useState(null);
  let [frame, frame_set] = useState(0);
  let file_name = useMemo(()=>{
    if (!file)
      return '';
    return str.mongo2path(file.id.split('/').pop());
  }, [file]);
  let dropdown_items = useMemo(()=>{
    let _dropdown_items = [];
    _dropdown_items.push({label: t('Download'), key: 'download',
      icon: <DownloadOutlined />});
    if (!is_share)
    {
      _dropdown_items.push({label: t('Make private'), key: 'make_private',
        icon: <LockOutlined />, disabled: true});
      _dropdown_items.push({label: t('Reveal in project'), key: 'reveal',
        icon: <ExportOutlined />});
      _dropdown_items.push({label: t('Delete'), key: 'delete',
        icon: <DeleteOutlined />, disabled: true});
    }
    return _dropdown_items;
  }, [is_share, t]);
  let dropdown_click_handle = useCallback(e=>{
    if (e.key == 'download' && file)
    {
      let filename = file_id2filename(file.id);
      let url = xurl.url(
        `${config_ext.back.app.url}/private/file/download.json`,
        {file_id: file.id, filename, token});
      download(url, filename);
    }
    if (e.key == 'reveal')
      on_return();
  }, [file, on_return, token]);
  let file_preview = useMemo(()=>{
    if (!file?.id)
      return null;
    if (is_image(str.mongo2path(file.id)))
      return <Image_preview file={file} />;
    if (is_audio(str.mongo2path(file.id)))
      return <Audio_preview file={file} />;
    if (is_video(str.mongo2path(file.id)))
    {
      return <Video_preview file={file} frame={frame}
        on_frame_change={frame_set} />;
    }
    return null;
  }, [file, frame]);
  let date_uploaded = useMemo(()=>{
    if (!file)
      return;
    return xdate.date_format(file.ts.insert, 'MMM DD, YYYY');
  }, [file]);
  let file_get = useCallback(()=>eserf(function* _file_get(){
    let res = yield file_id ? back_app.file.get(token, file_id, proj_link_id,
      proj_link_passwd) : back_app.file.get_by_inode(token, inode, proj_link_id,
      proj_link_passwd);
    if (res.err)
    {
      message_api.error(t('Something went wrong'));
      return metric.error('file_get_err', res.err);
    }
    file_set(res.file);
  }), [message_api, file_id, inode, proj_link_id, proj_link_passwd, t, token]);
  let cmt_delete_handle = useCallback(cmt=>eserf(function* _cmt_delete_handle(){
    if (!file?.id)
      return;
    let res = yield back_app.file_cmt.delete(token, file.id, cmt.id);
    if (res.err)
    {
      message_api.error(t('Something went wrong'));
      return metric.error('cmt_delete_handle_err', res.err);
    }
    file_get();
  }), [file?.id, file_get, message_api, t, token]);
  let cmt_select_handle = useCallback(cmt=>{
    if (cmt.frame === undefined)
      return;
    frame_set(cmt.frame);
  }, []);
  let tab_items = useMemo(()=>{
    let _tab_items = [];
    if (!is_share)
    {
      _tab_items.push({key: 'cmts', label: t('Comments'),
        children: <File_cmts_tab file={file} user_full={user_full}
          on_select={cmt_select_handle} on_delete={cmt_delete_handle} />});
    }
    _tab_items.push({key: 'file_info', label: t('File Information'),
      children: <File_info_tab file={file} />});
    return _tab_items;
  }, [is_share, cmt_delete_handle, cmt_select_handle, file, t,
    user_full]);
  useEffect(()=>{
    if (!token)
      return;
    file_set(null);
    let es = file_get();
    return ()=>es.return();
  }, [file_get, token]);
  return (
    <Layout style={{height: '100%'}}>
      {message_ctx_holder}
      <Layout.Header style={{background: colorBgContainer, display: 'flex',
        alignItems: 'center', padding: 0}}>
        <div style={{display: 'flex', justifyContent: 'space-between',
          alignItems: 'center', flex: 1, paddingLeft: '8px',
          paddingRight: is_mobile ? '8px' : '408px'}}>
          <Space>
            <Button size="small" shape="circle" icon={<LeftOutlined />}
              onClick={on_return} />
            {!is_mobile && <Typography.Text>{file_name}</Typography.Text>}
          </Space>
          <Space>
            <Dropdown menu={{items: dropdown_items,
              onClick: dropdown_click_handle}} placement="bottomRight"
            trigger={['click']}>
              <Button icon={<EllipsisOutlined />} />
            </Dropdown>
            <Button type="primary" disabled>Share</Button>
          </Space>
        </div>
      </Layout.Header>
      <Layout>
        <Layout.Content style={{display: 'flex', flexDirection: 'column',
          padding: is_mobile ? '0px' : '20px'}}>
          <div style={{display: 'flex', flexDirection: 'column',
            flexGrow: is_mobile ? 0 : 1, justifyContent: 'center',
            alignItems: 'center'}}>
            {file_preview}
          </div>
          {!is_share && !is_mobile && <div style={{marginTop: '20px'}}>
            <Cmt_form user={user} token={token}
              file={file} frame={frame} file_get={file_get} />
          </div>}
          {is_mobile && <div style={{padding: '16px 10px'}}>
            <Space direction="vertical" size="small" style={{display: 'flex'}}>
              <Skeleton loading={!file} paragraph={{rows: 1}} title={true}
                active>
                {file && <>
                  <Typography.Title level={5}>
                    {file_name}
                  </Typography.Title>
                  <Typography.Text type="secondary"
                    style={{display: 'block', height: control_height}}>
                    {str.mongo2email(file.user_id).split('@')[0] + ' '}
                    {t('uploaded on')} {date_uploaded}
                  </Typography.Text>
                </>}
              </Skeleton>
              {!is_share && <Cmt_form user={user} token={token} file={file}
                frame={frame} file_get={file_get} />}
              <Tabs items={tab_items} />
            </Space>
          </div>}
        </Layout.Content>
        {!is_mobile && <Layout.Sider style={{background: colorBgContainer,
          padding: '20px'}} width={400}>
          <Space direction="vertical" size="middle" style={{display: 'flex'}}>
            <Skeleton loading={!file} paragraph={{rows: 1}} title={false}
              active>
              {file && <Typography.Text type="secondary"
                style={{display: 'block', height: control_height}}>
                {str.mongo2email(file.user_id).split('@')[0] + ' '}
                {t('uploaded on')} {date_uploaded}
              </Typography.Text>}
            </Skeleton>
            <Tabs items={tab_items} />
          </Space>
        </Layout.Sider>}
      </Layout>
    </Layout>
  );
});
let E = ()=>{
  let [message_api, message_ctx_holder] = message.useMessage();
  let {t} = useTranslation();
  use_qs_clear({path: 1, is_dir: 1});
  let {qs_o, qs_set} = use_qs();
  let {user, user_full, token, org} = auth.use_auth();
  let es_root = use_es_root();
  let [path, path_set] = useState(qs_o.path || '');
  let [is_dir, is_dir_set] = useState(qs_o.is_dir || false);
  let [proj, proj_set] = useState(null);
  let [files, files_set] = useState({});
  let [is_sidebar_drawer_open, is_sidebar_drawer_open_set] = useState(false);
  let [query, query_set] = useState('');
  let is_file_preview = useMemo(()=>{
    if (!path)
      return false;
    return !is_dir;
  }, [is_dir, path]);
  let path_change_handle = useCallback((_path, _is_dir)=>{
    path_set(_path);
    is_dir_set(_is_dir);
    qs_set({...qs_o, path: _path, is_dir: _is_dir});
  }, [qs_o, qs_set]);
  let proj_get = useCallback(proj_id=>eserf(function* _proj_get(){
    if (!token)
      return;
    if (!proj_id)
      proj_id = proj.id;
    let proj_res = yield back_app.proj.get(token, proj_id);
    if (proj_res.err && proj_res.err != 'not_found')
    {
      message_api.error(t('Something went wrong'));
      return metric.error('proj_get_err', proj_res.err);
    }
    let _proj = proj_res.proj;
    if (proj_res.err == 'not_found')
    {
      let insert_res = yield back_app.proj.insert(token, proj_lbl);
      if (insert_res.err)
      {
        message_api.error(t('Something went wrong'));
        return metric.error('proj_main_insert_err', insert_res.err);
      }
      _proj = insert_res.proj;
    }
    proj_set(_proj);
    let files_res = yield back_app.file.ls(token, proj_id);
    if (files_res.err)
    {
      message_api.error(t('Something went wrong'));
      return metric.error('file_ls_err', files_res.err);
    }
    files_set(files_res.files);
  }), [message_api, proj, t, token]);
  useEffect(()=>{
    if (!org || !token || proj)
      return;
    let proj_id = str.path2mongo(`/${org.id}/root/${proj_lbl}`);
    es_root.spawn(proj_get(proj_id));
    if (!path)
      path_change_handle(str.mongo2path(proj_id), true);
  }, [es_root, proj_get, message_api, org, path, path_change_handle, proj, t,
    token]);
  let sidebar_drawer_open_handle = useCallback(()=>{
    is_sidebar_drawer_open_set(true);
  }, []);
  let sidebar_drawer_close_handle = useCallback(()=>{
    is_sidebar_drawer_open_set(false);
  }, []);
  let query_change_handle = useCallback(_query=>{
    query_set(_query);
    if (!proj)
      return;
    path_change_handle(str.mongo2path(proj.id), true);
  }, [path_change_handle, proj]);
  let return_handle = useCallback(()=>{
    path_change_handle(path.split('/').slice(0, -1).join('/'), true);
  }, [path, path_change_handle]);
  return (
    <>
      {message_ctx_holder}
      <Layout style={{minHeight: '90vh'}}>
        <Sidebar_drawer is_open={is_sidebar_drawer_open} proj={proj}
          on_close={sidebar_drawer_close_handle} org={org}
          on_path_change={path_change_handle} token={token}
          path={path} files={files} proj_get={proj_get} />
        {is_file_preview && <File_preview file_id={str.path2mongo(path)}
          user_full={user_full} token={token} on_return={return_handle}
          on_path_change={path_change_handle} user={user} />}
        {!is_file_preview && <Layout>
          <Header on_drawer_open={sidebar_drawer_open_handle} query={query}
            on_query_change={query_change_handle} proj_get={proj_get}
            on_path_change={path_change_handle} proj={proj} />
          <Content token={token} path={path} proj={proj}
            query={query} user_full={user_full} proj_get={proj_get}
            on_path_change={path_change_handle} is_dir={is_dir} files={files}
            on_query_change={query_change_handle} />
        </Layout>}
      </Layout>
    </>
  );
};

export default auth.with_auth_req(E);
