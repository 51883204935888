// LICENSE_CODE TLM
import assert from 'assert';
import xdate from './date.js';

let E = {};
export default E;

E.init = ()=>{};
E.uninit = ()=>{};

E.frame2tc_o = (frame, editrate)=>{
  let fps = editrate.n / editrate.d;
  let tc_o = {};
  let sec = frame / fps;
  tc_o.h = Math.floor(sec / xdate.SEC_HOUR);
  sec -= tc_o.h * xdate.SEC_HOUR;
  tc_o.min = Math.floor(sec / xdate.SEC_MIN);
  sec -= tc_o.min * xdate.SEC_MIN;
  tc_o.sec = Math.floor(sec);
  tc_o.frame = Math.abs(Math.round(frame % fps));
  return tc_o;
};

E.frame2tc = (frame, editrate, show_frames=true, trim_leading_zeros=false)=>{
  let is_frame_negative = frame < 0;
  let tc_o = E.frame2tc_o(Math.abs(frame), editrate);
  let tc = E.tc_o2str(tc_o, show_frames);
  if (trim_leading_zeros)
    tc = tc.slice(0, -4).replace(/^[0:]+/u, '') + tc.slice(-4);
  if (is_frame_negative)
    tc = '-'+tc;
  return tc;
};

E.tc_o2frame = (tc_o, editrate, _fps)=>{
  let fps = _fps || editrate.n / editrate.d;
  let frame = Math.floor((tc_o.h * xdate.SEC_HOUR + tc_o.min * xdate.SEC_MIN
    + tc_o.sec) * fps) + tc_o.frame;
  return frame;
};

E.tc_o2str = (tc_o, show_frames=true)=>{
  return `${String(tc_o.h).padStart(2, '0')}:`
    +`${String(tc_o.min).padStart(2, '0')}:`
    +`${String(tc_o.sec).padStart(2, '0')}${tc_o.is_dropframe ? ';' : ':'}`
    +(show_frames ? `${String(tc_o.frame).padStart(2, '0')}` : '--');
};

E.tcms_o2str = tcms_o=>{
  return `${String(tcms_o.h).padStart(2, '0')}:`
    +`${String(tcms_o.min).padStart(2, '0')}:`
    +`${String(tcms_o.sec).padStart(2, '0')}`;
};

let is_dropframe = tc=>tc.includes(';');

E.norm = tc=>{
  let arr = tc.split(/[:;]/u);
  if (arr.length<3)
    assert(0, 'invalid tc with not enough :');
  if (arr.length==4)
    return tc;
  return tc+(is_dropframe(tc) ? ';' : ':')+'00';
};

E.short = tc=>{
  return tc.replaceAll(':', '');
};

E.tc_o_norm = (tc_o, editrate)=>{
  let fps = editrate.n / editrate.d;
  let _tc_o = {...tc_o};
  if (_tc_o.frame > fps)
  {
    _tc_o.sec += Math.floor(_tc_o.frame / fps);
    _tc_o.frame = Math.round(_tc_o.frame % fps);
  }
  if (_tc_o.sec > 60)
  {
    _tc_o.min += Math.floor(_tc_o.sec / 60);
    _tc_o.sec = Math.round(_tc_o.sec % 60);
  }
  if (_tc_o.min > 60)
  {
    _tc_o.h += Math.floor(_tc_o.min / 60);
    _tc_o.min = Math.round(_tc_o.min % 60);
  }
  return _tc_o;
};

E.sec2tc = (sec, frame)=>{
  let tc_o = {};
  tc_o.h = Math.floor(sec/xdate.SEC_HOUR);
  sec -= tc_o.h*xdate.SEC_HOUR;
  tc_o.min = Math.floor(sec/xdate.SEC_MIN);
  sec -= tc_o.min*xdate.SEC_MIN;
  tc_o.sec = sec;
  tc_o.frame = frame;
  return E.tc_o2str(tc_o);
};

E.sec2tcms = sec=>{
  let tc_o = {};
  tc_o.h = Math.floor(sec/xdate.SEC_HOUR);
  sec -= tc_o.h*xdate.SEC_HOUR;
  tc_o.min = Math.floor(sec/xdate.SEC_MIN);
  sec -= tc_o.min*xdate.SEC_MIN;
  tc_o.sec = sec;
  return E.tcms_o2str(tc_o);
};

E.add_sec = (tc, sec)=>{
  let tc_o = E.str2tc_o(tc);
  let sum_sec = tc_o.h*xdate.SEC_HOUR+tc_o.min*xdate.SEC_MIN+tc_o.sec+sec;
  return E.sec2tc(sum_sec, tc_o.frame);
};

E.add_frame = (tc, frame, editrate)=>{
  let tc_o = E.str2tc_o(tc);
  let tc_frame_o = E.str2tc_o('00:00:00'+(tc_o.is_dropframe ? ';' : ':')+frame);
  return E.add_tc_o(tc_o, tc_frame_o, editrate);
};

E.sub_frame = (tc, frame, editrate)=>{
  let tc_o = E.str2tc_o(tc);
  let _frame = E.tc_o2frame(tc_o, editrate);
  return E.frame2tc_o(_frame-frame, editrate);
};

E.add_frame_str = (tc, frame, editrate)=>E.tc_o2str(E.add_frame(tc, frame,
  editrate));

E.sub_frame_str = (tc, frame, editrate)=>E.tc_o2str(E.sub_frame(tc, frame,
  editrate));

E.sub_sec = (tc, sec)=>{
  let tc_o = E.str2tc_o(tc);
  let sum_sec = tc_o.h*xdate.SEC_HOUR+tc_o.min*xdate.SEC_MIN+tc_o.sec+sec;
  return E.sec2tc(sum_sec, tc_o.frame);
};

E.add_tc_o = (tc_o1, tc_o2, editrate)=>{
  let _tc_o = {...tc_o1};
  _tc_o.frame += tc_o2.frame;
  _tc_o.sec += tc_o2.sec;
  _tc_o.min += tc_o2.min;
  _tc_o.h += tc_o2.h;
  return E.tc_o_norm(_tc_o, editrate);
};

E.sub_tc_o = (tc_o1, tc_o2, editrate)=>{
  let _tc_o = {...tc_o1};
  _tc_o.frame -= tc_o2.frame;
  _tc_o.sec -= tc_o2.sec;
  _tc_o.min -= tc_o2.min;
  _tc_o.h -= tc_o2.h;
  return E.tc_o_norm(_tc_o, editrate);
};

E.set_tc_o = (tc_o1, tc_o2, editrate)=>{
  let _tc_o = {...tc_o1};
  let replace = false;
  if (tc_o2.h)
    replace = true;
  _tc_o.h = replace ? tc_o2.h : _tc_o.h;
  if (tc_o2.min)
    replace = true;
  _tc_o.min = replace ? tc_o2.min : _tc_o.min;
  if (tc_o2.sec)
    replace = true;
  _tc_o.sec = replace ? tc_o2.sec : _tc_o.sec;
  _tc_o.frame = tc_o2.frame;
  return E.tc_o_norm(_tc_o, editrate);
};

E.str2tc_o = tc=>{
  let arr = tc.split(/[:;]/u);
  let tc_o = {h: +arr[0], min: +arr[1], sec: +arr[2], frame: +arr[3],
    is_dropframe: is_dropframe(tc)};
  return tc_o;
};

E.is_valid = tc=>{
  if (!tc || typeof tc != 'string')
    return false;
  let arr = tc.split(/[:;]/u);
  if (arr.length!=4)
    return false;
  let tc_o = E.str2tc_o(tc);
  return E.tc_o2str(tc_o)==tc;
};

E.set_frame = (tc, frame, editrate)=>{
  let tc_o = E.str2tc_o(tc);
  let _tc_o = E.frame2tc_o(frame, editrate);
  tc_o.frame = _tc_o.frame;
  tc_o.sec += _tc_o.sec;
  tc_o.min += _tc_o.min;
  tc_o.h += _tc_o.h;
  return E.tc_o2str(tc_o);
};

E.get_sec = tc=>{
  let tc_o = E.str2tc_o(tc);
  return tc_o.h*xdate.SEC_HOUR+tc_o.min*xdate.SEC_MIN+tc_o.sec;
};

// XXX colin: change to be _sec-sec
E.cmp = (tc, _tc)=>{
  let sec = E.get_sec(tc, 0);
  let _sec = E.get_sec(_tc, 0);
  return sec-_sec;
};

E.sub_sec = (tc, _tc)=>{
  // XXX colin: add support for frames
  let sec = E.get_sec(tc, 0);
  let _sec = E.get_sec(_tc, 0);
  let ret = sec-_sec;
  return ret;
};

// XXX colin: merge with fps_int2editrate_aaf in python
let editrates = [
  {n: 24, d: 1, fps: 24},
  {n: 25, d: 1, fps: 25},
  {n: 30, d: 1, fps: 30},
  {n: 50, d: 1, fps: 50},
  {n: 48, d: 1, fps: 48},
  {n: 60, d: 1, fps: 60},
  {n: 24000, d: 1001, fps: 23.976},
  {n: 30000, d: 1001, fps: 29.97},
  {n: 60000, d: 1001, fps: 59.94},
  {n: 48000, d: 1, fps: 48000}, // audio
  {n: 44100, d: 1, fps: 44100}, // audio
  {n: 96000, d: 1, fps: 96000}, // audio
  {n: 88200, d: 1, fps: 88200}, // audio
];
let _fps2editrate, _editrate2fps;
export let editrate_is_valid = E.editrate_is_valid = editrate=>{
  if (!_editrate2fps)
  {
    _editrate2fps = {};
    for (let ele of editrates)
      _editrate2fps[ele.n+'/'+ele.d] = ele;
  }
  return !!_editrate2fps[editrate.n+'/'+editrate.d];
};

export let fps_is_valid = E.fps_is_valid = fps=>{
  if (!_fps2editrate)
  {
    _fps2editrate = {};
    for (let ele of editrates)
      _fps2editrate[ele.fps] = ele;
  }
  return !!_fps2editrate[fps];
};

export let editrate2fps = editrate=>{
  for (let ele of editrates)
  {
    if (ele.n == editrate.n && ele.d == editrate.d)
      return ele.fps;
  }
  return null;
};

export let fps2editrate = E.fps2editrate= fps=>{
  let res = fps_is_valid(fps);
  if (!res)
    assert(0, `invalid fps ${fps}`);
  return _fps2editrate[fps];
};

E.editrate2editrate = (frame, src, dst, opt)=>{
  if (src.d == dst.d && src.n == dst.n)
    return frame;
  let dur_sec = frame * src.d / src.n;
  let ret = dur_sec * dst.n / dst.d;
  return opt?.is_not_floor ? ret : Math.floor(ret);
};

export let tc_dropframe2frame = E.tc_dropframe2frame = (tc_dropframe,
  editrate)=>{
  // Extract hours, minutes, seconds, and frames from the timecode
  let [hours, minutes, seconds, frames] =
    tc_dropframe.split(/[:;]/u).map(Number);
  // Calculate the effective frame rate
  let fps = editrate2fps(editrate);
  // Calculate total minutes
  let tot_min = hours * 60 + minutes;
  // Calculate absolute frames from the start
  let abs_frame = 0;
  abs_frame += Math.floor(hours * 3600 * fps);
  abs_frame += Math.floor(minutes * 60 * fps);
  abs_frame += Math.floor(seconds * fps);
  abs_frame += frames;
  // Drop two frames every minute except every tenth minute
  // Normally 2 frames for 29.97 fps
  let drop_frames = Math.floor(fps * 0.066666);
  let tot_drops = tot_min * drop_frames
    - Math.floor(tot_min / 10) * drop_frames;
  return abs_frame - tot_drops;
};

// XXX colin: move in aaf.js
export let aaf_info2file = E.aaf_info2file = (aaf_info, editrate,
  is_from_aaf)=>{
  let d = aaf_info.is_audio && (!aaf_info.is_audio_only)
    ? aaf_info.d25fps||aaf_info.d : aaf_info.d;
  let tc = aaf_info.is_audio_only ? aaf_info.tc25fps||aaf_info.tc : aaf_info.tc;
  if (is_from_aaf && aaf_info.is_audio && aaf_info.shoot_date)
  {
    let _tc = aaf_info.shoot_date.split(' ')[1];
    // shoot_date can be a date 2024-05-16 with no tc
    tc = E.is_valid(_tc) ? E.norm(_tc) : tc;
  }
  let _ext = aaf_info.ext
    || (aaf_info.is_audio ? 'm4a' : aaf_info.is_video ? 'mp4' : 'mp4');
  return `n_${aaf_info.n}__tc_${E.short(tc)}`
    +`__d_${d}__tr_${aaf_info.tr}.${_ext}`;
};
