// LICENSE_CODE TLM
import React, {useState, useRef, useEffect, useCallback, useMemo,
  forwardRef, memo} from 'react';
import {Spin} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';
import mime from 'mime';
import assert from 'assert';
import audio from './audio.js';
import video from './video.js';
import canvas from './canvas.js';
import metric from './metric.js';
import back_app from './back_app.js';
import {use_on_unmount} from './comp.js';
import eserf from '../../../util/eserf.js';
import xdate from '../../../util/date.js';
import tc from '../../../util/tc.js';
import media_offline_poster from './assets/media_offline_poster.jpeg';

let E = {};
export default E;

E.f2px = (frames, k, zoom)=>frames / k * 2 ** zoom;
E.px2f = (pxs, k, zoom)=>pxs * k / 2 ** zoom;
E.f2sec = (frames, fps)=>frames / fps;
E.sec2f = (sec, fps)=>sec * fps;
E.frame_start = seg=>seg.start;
E.frame_end = (start, len)=>{
  if (typeof start == 'object')
  {
    len = start.len;
    start = start.start;
  }
  return start + len - 1;
};
E.seg_len = seg=>seg.len;
E.src2url = (src, editrate)=>{
  if (src.type=='http')
    return src.v;
  if (src.type!='aaf_info')
    assert(0, `invalid src.type ${src.type}`);
  //let _prefix = 'https://tlm-front-share-prod-il.s3.il-central-1.amazonaws.com';
  //let _prefix = 'https://tlm-front-share-prod.s3.eu-west-1.amazonaws.com';
  let _prefix = 'https://d22dwsjbhzy8k.cloudfront.net';
  if (src.bucket_url)
    _prefix = src.bucket_url;
  return _prefix+'/'+encodeURIComponent(tc.aaf_info2file(src.v, editrate,
    true));
};
E.src2is_video = src=>{
  if (src.type=='http')
    return mime.getType(src.v).startsWith('video');
  // XXX colin: add support for ext
  if (src.type=='aaf_info')
    return src.v.is_video;
  assert(0, `invalid src.type ${src.type}`);
  return null;
};
E.src2is_audio = src=>{
  if (src.type=='http')
    return mime.getType(src.v).startsWith('audio');
  if (src.type=='aaf_info')
    return src.v.is_audio;
  assert(0, `invalid src.type ${src.type}`);
  return null;
};
E.src2len = src=>{
  if (src.type=='http')
    return src.len;
  if (src.type=='aaf_info')
    return src.v.d;
  assert(0, `invalid src.type ${src.type}`);
  return null;
};
let is_offline_cache = {};
E.is_offline_check = (token, url)=>eserf(function* _is_offline_check(){
  if (is_offline_cache[url] !== undefined)
    return is_offline_cache[url];
  let res = yield back_app.proxy_cors(token, url);
  let is_offline = ![200, 206].includes(res.status);
  is_offline_cache[url] = is_offline;
  if (is_offline)
    console.warn(url + ' is offline');
  return is_offline;
});
E.src_segs_get = (token, segs, selected_monitor, playback_rate=1)=>eserf(
  function* player_src_segs_get(){
    if (!segs)
      return [];
    if (!Array.isArray(segs))
      segs = [segs];
    if (!segs.length)
      return [];
    let is_no_monitor = selected_monitor === null;
    let selected_track_idx = segs.findIndex(track=>{
      return track.id == selected_monitor?.track_id;
    });
    let is_solo = selected_monitor?.is_solo;
    let src_segs = yield this.wait_ret([
      ...segs
        .filter(seg=>{
          return seg.type == 'source_clip' && !seg.rndr?.is_mute;
        })
        .map(seg=>eserf(function* _check_is_offline(){
          let url = E.src2url(seg.src, seg.editrate);
          let is_offline = yield E.is_offline_check(token, url);
          return {...seg, start: seg.abs_start, is_offline,
            len: Math.round(seg.len / playback_rate), playback_rate};
        })),
      ...segs
        .filter(seg=>{
          if (seg.is_no_arr)
            return false;
          if (seg.type != 'timeline_track')
            return true;
          if (seg.ctrl.has_monitor && is_no_monitor)
            return false;
          if (seg.ctrl.has_monitor && is_solo &&
            seg.id != selected_monitor.track_id)
          {
            return false;
          }
          if (seg.ctrl.has_monitor && !is_solo &&
            segs.indexOf(seg) < selected_track_idx)
          {
            return false;
          }
          if (seg.ctrl.has_mute && seg.mute.v)
            return false;
          if (seg.has_power && !seg.power.v)
            return false;
          return true;
        })
        .map(seg=>{
          if (seg.type == 'selector')
          {
            return E.src_segs_get(token, [seg.arr[seg.select_idx]],
              undefined, playback_rate);
          }
          if (seg.type == 'operation_motion')
          {
            return E.src_segs_get(token, seg.arr, undefined,
              playback_rate * seg.playrate);
          }
          return E.src_segs_get(token, seg.arr, undefined, playback_rate);
        }).flat(),
    ]);
    src_segs = src_segs.flat();
    return src_segs;
  });
E.abs_starts_get = segs=>{
  if (!Array.isArray(segs))
    segs = [segs];
  let abs_starts_map = new Map();
  for (let seg of segs)
  {
    abs_starts_map.set(seg, seg.abs_start);
    if (seg.is_no_arr)
      continue;
    let children_map = E.abs_starts_get(seg.arr);
    abs_starts_map = new Map([...abs_starts_map, ...children_map]);
  }
  return abs_starts_map;
};
E.src_seg2video_render_seg = (src_seg, start, len, split_src_segs=[])=>{
  if (!src_seg)
  {
    return {start, len, video_start: null, src: null, is_offline: false,
      playback_rate: 1, video_len: null, split_render_segs: []};
  }
  let video_start = E.frame_start(src_seg) - src_seg.start_frame_abs;
  let is_offline = src_seg.is_offline;
  let src = E.src2url(src_seg.src, src_seg.editrate);
  let video_len = E.src2len(src_seg.src);
  return {start, len, video_start, src, is_offline, video_len,
    playback_rate: src_seg.playback_rate,
    split_render_segs: split_src_segs.map(split_src_seg=>{
      return E.src_seg2video_render_seg(split_src_seg, start, len);
    })};
};
E.video_render_segs_get = (token, rec_monitor_in, selected_monitor)=>eserf(
  function* player_video_render_segs_get(){
    let video_tracks = rec_monitor_in.tracks
      .filter(track=>track.id[0] == 'V');
    let abs_starts = E.abs_starts_get(video_tracks);
    let segs = [...abs_starts.keys()];
    let src_segs = yield E.src_segs_get(token, video_tracks,
      selected_monitor);
    let video_src_segs = src_segs.filter(seg=>E.src2is_video(seg.src));
    let boundaries = video_src_segs.map(seg=>{
      return [E.frame_start(seg), E.frame_end(seg) + 1];
    }).flat();
    boundaries.push(rec_monitor_in.start);
    boundaries.push(rec_monitor_in.start + rec_monitor_in.len);
    boundaries = [...new Set(boundaries)].sort((a, b)=>a - b);
    let render_segs = yield this.wait_ret(boundaries.slice(0, -1).map(
      (boundary, idx)=>eserf(function* _video_render_segs_get_map(){
        let len = boundaries[idx + 1] - boundary;
        let src_seg = video_src_segs.find(seg=>{
          return E.frame_start(seg) <= boundary
            && boundary < E.frame_end(seg);
        });
        if (!src_seg)
          return E.src_seg2video_render_seg(null, boundary, len);
        let selector_seg = segs.find(seg=>{
          return E.frame_start(seg) <= boundary
            && boundary <= E.frame_end(seg) && seg.type == 'selector';
        });
        let split_src_segs = [];
        if (selector_seg)
        {
          let selector_src_segs = yield E.src_segs_get(token, selector_seg.arr);
          if (selector_src_segs.some(seg=>seg.id == src_seg.id))
            split_src_segs = selector_src_segs;
        }
        return E.src_seg2video_render_seg(src_seg, boundary, len,
          split_src_segs);
      })));
    return render_segs;
  });
E.src_seg2audio_render_seg = (src_segs, start, len)=>{
  if (!src_segs.length)
  {
    return {start, len, audio_starts: [], srcs: [],
      playback_rates: []};
  }
  let online_src_segs = src_segs.filter(seg=>!seg.is_offline);
  let audio_starts = online_src_segs
    .map(seg=>E.frame_start(seg) - seg.start_frame_abs);
  let srcs = online_src_segs.map(seg=>E.src2url(seg.src, seg.editrate));
  let playback_rates = online_src_segs.map(seg=>seg.playback_rate);
  return {start, len, audio_starts, srcs, playback_rates};
};
E.audio_render_segs_get = (token, rec_monitor_in, selected_monitor)=>eserf(
  function* player_audio_render_segs_get(){
    let audio_tracks = rec_monitor_in.tracks
      .filter(track=>track.id[0] == 'A');
    let src_segs = yield E.src_segs_get(token, audio_tracks,
      selected_monitor);
    let audio_src_segs = src_segs.filter(seg=>E.src2is_audio(seg.src));
    let boundaries = audio_src_segs.map(seg=>{
      return [E.frame_start(seg), E.frame_end(seg) + 1];
    }).flat();
    boundaries.push(rec_monitor_in.start);
    boundaries.push(rec_monitor_in.start + rec_monitor_in.len);
    boundaries = [...new Set(boundaries)].sort((a, b)=>a - b);
    let render_segs = boundaries.slice(0, -1).map((boundary, idx)=>{
      let cur_audio_src_segs = audio_src_segs.filter(seg=>{
        return E.frame_start(seg) <= boundary
          && boundary < E.frame_end(seg);
      });
      let len = boundaries[idx + 1] - boundary;
      return E.src_seg2audio_render_seg(cur_audio_src_segs, boundary, len);
    });
    return render_segs;
  });
E.Player = memo(forwardRef(({resolution, video_render_segs, on_resize,
  audio_render_segs, frame, fps, playback_rate, is_audio_video_sync,
  volume=1, video_ref: _video_ref, on_loading_state_change}, ref)=>{
  let [player_width, player_width_set] = useState(0);
  let [player_height, player_height_set] = useState(0);
  let [is_video_loading, is_video_loading_set] = useState(false);
  let [is_audio_loading, is_audio_loading_set] = useState(false);
  let aspect_ratio = useMemo(()=>resolution.w / resolution.h,
    [resolution.h, resolution.w]);
  let [cur_video_render_seg, cur_video_render_seg_set] = useState();
  let [cur_audio_render_seg, cur_audio_render_seg_set] = useState();
  let canvas_ref = useRef(null);
  let video_ref = useRef(null);
  let video_els_ref = useRef(new Map());
  let audio_buffers_ref = useRef({});
  let cur_audio_buffers_ref = useRef([]);
  let chunk_start_ref = useRef(null);
  let audio_ref = useRef(null);
  let src_buffer_ref = useRef(null);
  let container_ref = useRef(null);
  let audio_chunk_frames = useMemo(()=>{
    return audio.chunk_size / audio.audio_ctx.sampleRate * fps;
  }, [fps]);
  let gain_node = useMemo(()=>{
    let _gain_node = audio.audio_ctx.createGain();
    _gain_node.connect(audio.audio_ctx.destination);
    return _gain_node;
  }, []);
  useEffect(()=>{
    if (!video_render_segs)
      return;
    let _cur_video_render_seg = video_render_segs.find(seg=>{
      return E.frame_start(seg) <= frame && frame <= E.frame_end(seg);
    });
    if (cur_video_render_seg === _cur_video_render_seg)
      return;
    cur_video_render_seg_set(_cur_video_render_seg);
  }, [cur_video_render_seg, frame, video_render_segs]);
  useEffect(()=>{
    if (!audio_render_segs)
      return;
    let _cur_audio_render_seg = audio_render_segs.find(seg=>{
      return E.frame_start(seg) <= frame && frame <= E.frame_end(seg);
    });
    if (cur_audio_render_seg === _cur_audio_render_seg)
      return;
    cur_audio_render_seg_set(_cur_audio_render_seg);
  }, [frame, audio_render_segs, cur_audio_render_seg]);
  let audio_playback_rate = useMemo(()=>{
    return playback_rate;
  }, [playback_rate]);
  let video_playback_rate = useMemo(()=>{
    if (!cur_video_render_seg)
      return playback_rate;
    return cur_video_render_seg.playback_rate * playback_rate;
  }, [cur_video_render_seg, playback_rate]);
  let video_play = useCallback(()=>eserf(function* _video_play(){
    if (!video_ref.current)
      return;
    let ctx = canvas_ref.current.getContext('2d');
    canvas.video_draw(ctx, video_ref.current, 0, 0, ctx.canvas.width,
      ctx.canvas.height);
    let start = performance.now();
    let res = yield video.play(video_ref.current);
    if (res.err)
      return metric.error('video_play_err', res.err);
    let latency = (performance.now() - start) / xdate.MS_SEC;
    video_ref.current.currentTime += latency;
  }), []);
  let video_pause = useCallback(()=>{
    if (!video_ref.current)
      return;
    video.pause(video_ref.current);
  }, []);
  let audio_pause = useCallback(()=>{
    if (!src_buffer_ref.current)
      return;
    src_buffer_ref.current.stop();
    src_buffer_ref.current.disconnect();
    src_buffer_ref.current = null;
  }, []);
  let audio_play = useCallback(()=>{
    audio_pause();
    if (!audio_ref.current)
      return;
    let start_time = E.f2sec(
      prev_props_ref.current.frame - chunk_start_ref.current, fps)
      + audio.audio_ctx.baseLatency;
    src_buffer_ref.current = audio.play(audio_ref.current, start_time, 1,
      gain_node);
  }, [audio_pause, fps, gain_node]);
  useEffect(()=>{
    if (!src_buffer_ref.current)
      return;
    gain_node.gain.value = volume;
  }, [gain_node, volume]);
  use_on_unmount(()=>{
    video_pause();
    audio_pause();
    gain_node.disconnect();
    for (let [, video_el] of video_els_ref.current)
      video.destroy(video_el);
  }, [gain_node]);
  let resize_handle = useCallback(()=>{
    let container = container_ref.current;
    // Check if the player was unmounted
    // (e.g. when split was changed during playing)
    if (!container)
      return;
    let container_rect = container.getBoundingClientRect();
    let container_width = container_rect.width;
    let container_height = container_rect.height;
    let _player_width = Math.min(container_height * aspect_ratio,
      container_width);
    let _player_height = _player_width / aspect_ratio;
    player_width_set(_player_width);
    player_height_set(_player_height);
    if (on_resize)
      on_resize(_player_width, _player_height);
  }, [aspect_ratio, on_resize]);
  useEffect(()=>{
    let container = container_ref.current;
    // Check if the player was unmounted
    // (e.g. when split was changed during playing)
    if (!container)
      return;
    let resize_observer = new ResizeObserver(resize_handle);
    resize_observer.observe(container);
    return ()=>resize_observer.unobserve(container);
  }, [resize_handle]);
  let prev_props_ref = useRef({});
  let video_render_seg_load = useCallback(render_seg=>eserf(function*
  _render_seg_load(){
    if (!render_seg || render_seg.is_offline)
      return;
    if (video_els_ref.current.has(render_seg))
      return video_els_ref.current.get(render_seg);
    let video_src = render_seg.src;
    if (!video_src)
      return;
    let video_time = E.f2sec(render_seg.start - render_seg.video_start, fps)
      * render_seg.playback_rate;
    let video_el = yield video.init(video_src, video_time);
    if (video_el.err)
    {
      metric.error('could not load a video', video_src, video_el.err);
      return;
    }
    video_els_ref.current.set(render_seg, video_el);
    return video_el;
  }), [fps]);
  let cur_video_render_seg_load = useCallback(()=>eserf(function*
  _cur_render_seg_load(){
    video_ref.current = null;
    is_video_loading_set(true);
    let video_el = yield video_render_seg_load(cur_video_render_seg);
    is_video_loading_set(false);
    if (!video_el)
      return;
    video_ref.current = video_el;
    if (_video_ref)
      _video_ref(video_el);
    let video_time = E.f2sec(
      prev_props_ref.current.frame - cur_video_render_seg.video_start, fps)
      * cur_video_render_seg.playback_rate;
    if (video_ref.current.currentTime != video_time)
      video_ref.current.currentTime = video_time;
    if (video_ref.current.playbackRate !=
      prev_props_ref.current.video_playback_rate
      && prev_props_ref.current.video_playback_rate > 0)
    {
      video_ref.current.playbackRate =
        prev_props_ref.current.video_playback_rate;
    }
    if (prev_props_ref.current.video_playback_rate > 0)
      video_play();
    else
      video_pause();
  }), [cur_video_render_seg, video_pause, video_play, video_render_seg_load,
    fps, _video_ref]);
  let next_video_render_seg_load = useCallback(()=>eserf(function*
  _next_render_seg_load(){
    if (!cur_video_render_seg)
      return;
    let cur_render_seg_idx = video_render_segs.indexOf(cur_video_render_seg);
    let next_render_seg = video_render_segs[cur_render_seg_idx + 1];
    let video_el = yield video_render_seg_load(next_render_seg);
    if (!video_el)
      return;
    let video_time = E.f2sec(
      next_render_seg.start - next_render_seg.video_start, fps)
      * next_render_seg.playback_rate;
    video_el.currentTime = video_time;
    yield video.play(video_el);
    yield video.pause(video_el);
  }), [cur_video_render_seg, fps, video_render_seg_load, video_render_segs]);
  let rest_video_render_segs_load = useCallback(()=>eserf(function*
  _rest_render_segs_load(){
    if (!video_render_segs)
      return;
    for (let render_seg of video_render_segs)
      yield video_render_seg_load(render_seg);
  }), [video_render_seg_load, video_render_segs]);
  useEffect(()=>{
    if (video_render_segs == prev_props_ref.current.video_render_segs)
      return;
    prev_props_ref.current.video_render_segs = video_render_segs;
    if (!video_render_segs)
    {
      for (let video_el of video_els_ref.current.values())
        video.destroy(video_el);
      video_els_ref.current.clear();
      return;
    }
    let prev_render_segs = {};
    for (let render_seg of video_els_ref.current.keys())
      prev_render_segs[JSON.stringify(render_seg)] = render_seg;
    let next_render_segs = {};
    for (let render_seg of video_render_segs)
      next_render_segs[JSON.stringify(render_seg)] = render_seg;
    let _video_els = new Map();
    for (let serialized_render_seg of Object.keys(prev_render_segs))
    {
      let prev_render_seg = prev_render_segs[serialized_render_seg];
      let video_el = video_els_ref.current.get(prev_render_seg);
      if (next_render_segs[serialized_render_seg])
        _video_els.set(next_render_segs[serialized_render_seg], video_el);
      else
        video.destroy(video_el);
    }
    video_els_ref.current = _video_els;
  }, [cur_video_render_seg, next_video_render_seg_load, video_render_segs]);
  useEffect(()=>{
    if (cur_video_render_seg == prev_props_ref.current.cur_video_render_seg)
      return;
    prev_props_ref.current.cur_video_render_seg = cur_video_render_seg;
    let es = eserf(function* _cur_render_seg_change(){
      yield cur_video_render_seg_load();
      yield next_video_render_seg_load();
      yield rest_video_render_segs_load();
    });
    return ()=>es.return();
  }, [cur_video_render_seg, cur_video_render_seg_load,
    next_video_render_seg_load, rest_video_render_segs_load]);
  useEffect(()=>{
    if (video_playback_rate == prev_props_ref.current.video_playback_rate)
      return;
    prev_props_ref.current.video_playback_rate = video_playback_rate;
    if (!video_ref.current)
      return;
    video_ref.current.playbackRate = Math.max(video_playback_rate, 0);
    if (video_playback_rate > 0)
      video_play();
    else
      video_pause();
  }, [video_playback_rate, video_pause, video_play, frame, cur_video_render_seg,
    fps]);
  let loading_ts_ref = useRef(null);
  useEffect(()=>{
    let ctx = canvas_ref.current.getContext('2d');
    let cancelled = false;
    let prev_cur_time;
    let frame_render = ()=>{
      if (cancelled)
        return;
      if (cur_video_render_seg?.is_offline)
      {
        canvas.img_draw(ctx, media_offline_poster, 0, 0, ctx.canvas.width,
          ctx.canvas.height);
        return;
      }
      if (!video_ref.current)
      {
        canvas.color_fill(ctx, '#000000');
        return;
      }
      if (video_ref.current.readyState < video_ref.current.HAVE_CURRENT_DATA)
      {
        if (!loading_ts_ref.current)
          loading_ts_ref.current = performance.now();
        else if (performance.now() - loading_ts_ref.current > xdate.MS_SEC)
          is_video_loading_set(true);
        return void requestAnimationFrame(frame_render);
      }
      let is_pause = !playback_rate;
      let cur_frame = Math.floor(E.sec2f(video_ref.current.currentTime, fps));
      if (is_pause && cur_frame == prev_cur_time)
        return void requestAnimationFrame(frame_render);
      prev_cur_time = cur_frame;
      loading_ts_ref.current = null;
      is_video_loading_set(false);
      canvas.video_draw(ctx, video_ref.current, 0, 0, ctx.canvas.width,
        ctx.canvas.height);
      requestAnimationFrame(frame_render);
    };
    frame_render();
    return ()=>cancelled = true;
  }, [cur_video_render_seg, playback_rate, fps, is_video_loading, player_width,
    player_height]);
  let audio_chunk_update = useCallback(()=>{
    if (!cur_audio_render_seg || !cur_audio_buffers_ref.current.length)
      return audio_ref.current = null;
    let chunks = cur_audio_buffers_ref.current.map(
      (audio_buffer, idx)=>{
        let audio_start = cur_audio_render_seg.audio_starts[idx];
        let start_time = E.f2sec(
          prev_props_ref.current.frame - audio_start, fps);
        return audio.audio_buffer2chunk(audio_buffer, start_time);
      });
    let chunk = audio.mix(chunks);
    if (audio_playback_rate < 0)
      chunk = audio.reverse(chunk);
    chunk_start_ref.current =
      prev_props_ref.current.frame - audio_chunk_frames / 2;
    audio_ref.current = chunk;
    if (audio_playback_rate)
      audio_play();
  }, [audio_chunk_frames, audio_playback_rate, cur_audio_render_seg, fps,
    audio_play]);
  let audio_render_seg_load = useCallback(render_seg=>eserf(function*
  _render_seg_load(){
    if (!render_seg || !render_seg.srcs.length)
      return;
    is_audio_loading_set(true);
    let audio_buffers = yield this.wait_ret(render_seg.srcs.map(
      src=>eserf(function* _audio_buffers_get(){
        if (audio_buffers_ref.current[src])
          return audio_buffers_ref.current[src];
        let audio_buffer = yield audio.audio_buffer_get(src);
        if (audio_buffer.err)
          return {err: audio_buffer.err};
        audio_buffers_ref.current[src] = audio_buffer;
        return audio_buffer;
      })));
    is_audio_loading_set(false);
    let err_idx = audio_buffers.findIndex(audio_buffer=>audio_buffer.err);
    if (err_idx != -1)
    {
      let err_audio_buffer = audio_buffers[err_idx];
      let err_src = render_seg.srcs[err_idx];
      metric.error('could not load a audio', err_src, err_audio_buffer.err);
      return;
    }
    return audio_buffers;
  }), []);
  let cur_audio_render_seg_load = useCallback(()=>eserf(function*
  _cur_render_seg_load(){
    chunk_start_ref.current = null;
    cur_audio_buffers_ref.current = [];
    audio_ref.current = null;
    if (!cur_audio_render_seg)
      return;
    let audio_buffers = yield audio_render_seg_load(cur_audio_render_seg);
    if (!audio_buffers || !audio_buffers.length)
      return;
    cur_audio_buffers_ref.current = audio_buffers.map((audio_buffer, idx)=>{
      let src_playback_rate = cur_audio_render_seg.playback_rates[idx];
      return audio.stretch(audio_buffer, 1 / src_playback_rate);
    });
    if (prev_props_ref.current.audio_playback_rate)
      audio_chunk_update();
    else
      audio_pause();
  }), [audio_pause, audio_render_seg_load, cur_audio_render_seg,
    audio_chunk_update]);
  let next_audio_render_seg_load = useCallback(()=>eserf(function*
  _next_render_seg_load(){
    if (!cur_audio_render_seg)
      return;
    let cur_render_seg_idx = audio_render_segs.indexOf(cur_audio_render_seg);
    let next_render_seg = audio_render_segs[cur_render_seg_idx + 1];
    yield audio_render_seg_load(next_render_seg);
  }), [audio_render_seg_load, audio_render_segs, cur_audio_render_seg]);
  let rest_audio_render_segs_load = useCallback(()=>eserf(function*
  _rest_render_segs_load(){
    if (!audio_render_segs)
      return;
    for (let render_seg of audio_render_segs)
      yield audio_render_seg_load(render_seg);
  }), [audio_render_seg_load, audio_render_segs]);
  useEffect(()=>{
    if (cur_audio_render_seg == prev_props_ref.current.cur_audio_render_seg)
      return;
    prev_props_ref.current.cur_audio_render_seg = cur_audio_render_seg;
    if (prev_props_ref.current.audio_es)
      prev_props_ref.current.audio_es.return();
    prev_props_ref.current.audio_es = eserf(function* _cur_render_seg_change(){
      yield cur_audio_render_seg_load();
      yield next_audio_render_seg_load();
      yield rest_audio_render_segs_load();
    });
  }, [cur_audio_render_seg, cur_audio_render_seg_load,
    next_audio_render_seg_load, rest_audio_render_segs_load]);
  useEffect(()=>{
    if (audio_playback_rate == prev_props_ref.current.audio_playback_rate)
      return;
    prev_props_ref.current.audio_playback_rate = audio_playback_rate;
    if (audio_playback_rate)
      audio_chunk_update();
    else
      audio_pause();
  }, [audio_pause, audio_play, audio_playback_rate, audio_chunk_update]);
  let tol = useMemo(()=>{
    return E.f2sec(2, fps);
  }, [fps]);
  let err_fix_ts_ref = useRef(null);
  useEffect(()=>{
    if (frame == prev_props_ref.current.frame)
      return;
    prev_props_ref.current.frame = frame;
    if (!video_ref.current)
      return;
    let video_time = E.f2sec(frame - cur_video_render_seg.video_start, fps)
      * cur_video_render_seg.playback_rate;
    let video_err = Math.abs(video_ref.current.currentTime - video_time);
    let is_video_ready = video_ref.current.readyState
      >= video_ref.current.HAVE_CURRENT_DATA;
    let can_be_fixed = !err_fix_ts_ref.current
      || performance.now() - err_fix_ts_ref.current > 1000;
    let should_be_fixed = is_audio_video_sync && is_video_ready && can_be_fixed
      && video_err > tol;
    if (should_be_fixed)
      err_fix_ts_ref.current = performance.now();
    if (video_playback_rate <= 0 && is_video_ready || should_be_fixed)
      video_ref.current.currentTime = video_time;
    let chunk_frame = frame - chunk_start_ref.current;
    let percent = chunk_frame / audio_chunk_frames;
    if (audio_playback_rate && (percent >= 0.75 || percent <= 0.25))
      audio_chunk_update();
  }, [audio_chunk_frames, audio_playback_rate, cur_video_render_seg, fps, frame,
    audio_chunk_update, video_play, video_playback_rate, tol,
    is_audio_video_sync]);
  let is_loading = useMemo(()=>{
    return is_video_loading || is_audio_loading;
  }, [is_audio_loading, is_video_loading]);
  useEffect(()=>{
    if (!on_loading_state_change)
      return;
    on_loading_state_change(is_loading);
  }, [is_loading, on_loading_state_change]);
  let spinner_size = useMemo(()=>{
    return player_height / 2;
  }, [player_height]);
  let container_ref_set = useCallback(el=>{
    container_ref.current = el;
    if (!ref)
      return;
    if (typeof ref == 'function')
      ref(el);
    if (typeof ref == 'object')
      ref.current = el;
  }, [ref]);
  return (
    <div ref={container_ref_set} style={{position: 'relative', height: '100%',
      display: 'flex', justifyContent: 'center', alignItems: 'center',
      overflow: 'hidden'}}>
      <canvas ref={canvas_ref} width={Math.round(player_width)}
        height={Math.round(player_height)} style={{position: 'absolute',
          background: 'black', width: `${player_width}px`,
          height: `${player_height}px`}} />
      {is_loading && <Spin
        indicator={<LoadingOutlined style={{fontSize: `${spinner_size}px`}}
          spin />} />}
    </div>
  );
}));
